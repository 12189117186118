import React from "react";
import CommonStyles from "../sharedStyle.module.scss";
import Styles from "./input.module.scss";
import RupeeSVG from "../svg/rupeeSvg";
import { PRIMARYCOLOR } from "../colors";

function Input({
  hasError = "",
  isValid = null,
  requiredAstrict,
  miniLabel = false,
  withIcon = "",
  existingPriceLabel = "",
  whiteBackgroundcolor = false,
  textarea = false,
  ...props
}: any) {
  return (
    <div className={hasError ? "" : "py-2"}>
      <label
        htmlFor={props.labelName}
        className={`${
          miniLabel ? Styles.formLabelMini : CommonStyles.formLabel
        } `}
      >
        <div
          className={`d-flex align-items-center ${
            requiredAstrict ? Styles.required : null
          }`}
        >
          {props.labelname}
        </div>
      </label>

      {withIcon === "rupee" && !textarea && (
        <>
          <br />
          <label className={`${Styles.iconParent}`}>
            <svg className={`${Styles.icon}`}>
              <RupeeSVG
                width="8"
                height="10"
                color={props.disabled ? "#757575" : PRIMARYCOLOR}
              />
            </svg>
            <input
              className={`${CommonStyles.commonInputClass} ${
                hasError !== ""
                  ? CommonStyles.error
                  : isValid
                  ? CommonStyles.valid
                  : CommonStyles.formControl
              } ${props.no_shadow === "true" ? "shadow-none" : ""}`}
              {...props}
            />
          </label>
        </>
      )}

      {withIcon === "" && !textarea && (
        <input
          className={`${CommonStyles.commonInputClass} ${
            hasError !== ""
              ? CommonStyles.error
              : isValid
              ? CommonStyles.valid
              : CommonStyles.formControl
          } ${props.no_shadow === "true" ? "shadow-none" : ""}
          ${props.no_shadow === "true" ? "border border-2" : ""}
            ${whiteBackgroundcolor ? Styles.whiteBG : null}
            `}
          {...props}
        />
      )}

      {textarea && (
        <textarea
          className={`${CommonStyles.commonInputClass} 
          ${props.no_shadow === "true" ? "shadow-none" : ""}
            ${whiteBackgroundcolor ? Styles.whiteBG : null}
            `}
          {...props}
        />
      )}

      {existingPriceLabel !== "" && (
        <label className={`${Styles.formLabelMini} m-0 p-0`}>
          Existing Price:
          <svg className={`${Styles.icon} ml-1`}>
            <RupeeSVG width="5" height="9" color={PRIMARYCOLOR} />
          </svg>
          <span className={`${Styles.existingPrice}`}>
            {existingPriceLabel}
          </span>
        </label>
      )}
    </div>
  );
}

export default Input;

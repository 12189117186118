export function getDuration(date: Date) {
  const currentDate = Date.now();
  const Difference_In_Time = currentDate - date.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const numberOfDays = Math.round(Difference_In_Days);
  let duration = "";
  if (numberOfDays === 0) {
    duration = `Today`;
  } else if (numberOfDays === 1) {
    duration = "Yesterday";
  } else if (numberOfDays < 30) {
    duration = `${numberOfDays} Days ago`;
  } else if (numberOfDays > 365) {
    const numberOfYears = Math.floor(numberOfDays / 365);
    duration =
      numberOfYears < 2
        ? `${numberOfYears} Year ago`
        : `${numberOfYears} Years ago`;
  } else {
    const numberOfMonths = Math.floor(numberOfDays / 30);
    duration =
      numberOfMonths < 2
        ? `${numberOfMonths} Month ago`
        : `${numberOfMonths} Months ago`;
  }
  return duration;
}

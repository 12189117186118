export type paramsType = {
  name: string;
  example: any;
  required: boolean;
  description: string;
};

function createFlattenObject(data: any): any {
  const param: any = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === "object") {
      const res = createFlattenObject(value);
      for (const [objKey, objvalue] of Object.entries(res)) {
        param[`${key}.${objKey}`] = objvalue;
      }
    } else {
      param[key] = value;
    }
  }

  return param;
}

export function createParams(params: any, isHeaders: boolean): paramsType[] {
  if (!params) return [];

  params = isHeaders ? params : createFlattenObject(params);

  const param: paramsType[] = [];
  for (const [key, value] of Object.entries(params)) {
    const para: paramsType = {
      name: key,
      example: value,
      required: true,
      description: "",
    };

    param.push(para);
  }
  return param;
}

export function updateParams(
  data: any,
  exisingParmas: paramsType[],
  isHeaders: boolean
): paramsType[] {
  if (!data || !exisingParmas) return [];

  data = isHeaders ? data : createFlattenObject(data);

  const params: any = {};
  for (const para of exisingParmas) {
    params[para.name] = para;
  }

  const param: paramsType[] = [];
  for (const [key, value] of Object.entries(data)) {
    if (key in params) {
      const para: paramsType = {
        name: key,
        example: value,
        required: params[key].required,
        description: params[key].description,
      };
      param.push(para);
    } else {
      const para: paramsType = {
        name: key,
        example: value,
        required: true,
        description: "",
      };
      param.push(para);
    }
  }
  return param;
}

export function vallidateData(doc: any, setError: any) {
  if (!doc) {
    setError("Something went wrong");
    return true;
  }

  if (!doc.arn) {
    setError("Arn is missing");
    return true;
  }

  if (doc.arn.includes(" ")) {
    setError("Space is not allowed in ARN");
  }

  if (!doc.name) {
    setError("Name is missing");
    return true;
  }

  if (!doc.description) {
    setError("Description is missing");
    return true;
  }

  if (!doc.type) {
    setError("Type is missing");
    return true;
  }

  if (!doc.category) {
    setError("Category is missing");
    return true;
  }

  if (!doc.curl) {
    setError("CURL is missing");
    return true;
  }

  if (!doc.headers) {
    setError("Headers is missing");
    return true;
  }

  if (!doc.params) {
    setError("Params is missing");
    return true;
  }

  if (!doc.responses) {
    setError("Responses is missing");
    return true;
  }

  return false;
}

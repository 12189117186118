import React from "react";
import Styles from "./keyValuePair.module.scss";

function KeyValuePair({
  className,
  label,
  value,
  helperText = "",
  setToggleAssignPlanPopUp,
  toggleAssignPlanPopUp,
  setDisabled,
  ...props
}: any) {
  return (
    <div className={className}>
      <p className={`mb-0 ${Styles.key}`}>{label}</p>
      <div className="d-flex align-items-center">
        {props.children && <div className="pr-2">{props.children}</div>}
        <p
          className={`mb-0 ${Styles.value}`}
          style={{ cursor: label === "Plan Name" ? "pointer" : "" }}
          onClick={() => {
            if (label === "Plan Name") {
              setDisabled(true);
              setToggleAssignPlanPopUp(!toggleAssignPlanPopUp);
            }
          }}
        >
          {value}
        </p>
      </div>
      <p className={`mb-0 ${Styles.helperText}`}>{helperText}</p>
    </div>
  );
}

export default KeyValuePair;

import React, { createContext, useContext, useEffect, useState } from "react";
import {
  accounts,
  batchService,
  editDocs,
  home,
  monitor,
  plans,
  products,
  users,
} from "../constants/navItems";
import { callGetApi } from "../api/axios";
import { ERROR } from "../utils/toastType";
import { ToastContext } from "./toastContext";

export const PermissionContext = createContext<any>({});

function PermissionProvider({ ...props }: any) {
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "{}");

  const [permissions, setPermissions] = useState<any>(null);
  const dispatch = useContext(ToastContext);
  const currentNavItems: string[] = [
    home,
    accounts,
    plans,
    products,
    users,
    batchService,
    editDocs,
    monitor,
  ];

  useEffect(() => {
    async function getUserPermissions() {
      try {
        const response: any = await callGetApi(`/roles/${currentUser.id}`);
        const navItems = Array.from(
          new Set(
            response.data.permissionsList?.map((permission: any) => {
              const name = permission.split("_").slice(1).join("_");
              return name;
            })
          )
        )?.map((permission: any) => {
          let name: string = "";
          for (const item of currentNavItems) {
            if (
              permission.startsWith(item.toLowerCase().replaceAll(" ", "_"))
            ) {
              name = item;
              break;
            }
          }
          return name;
        });

        setPermissions({
          navItems: [home, ...new Set(navItems)],
          assets: response.data.assets,
          permissions: response.data.permissions,
        });
        localStorage.setItem(
          "current_user",
          JSON.stringify({
            ...currentUser,
            role: response.data.role,
          })
        );
      } catch (err: any) {
        setPermissions({
          navItems: [home],
          assets: {},
          permissions: {},
        });
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    if (currentUser?.id) {
      getUserPermissions();
    }
  }, [dispatch]);

  return (
    <>
      {permissions ? (
        <PermissionContext.Provider value={permissions}>
          {props.children}
        </PermissionContext.Provider>
      ) : null}
    </>
  );
}

export default PermissionProvider;

import React from "react";

function Monitor() {
  return (
    <div style={{ margin: "-24px", height: "calc(100vh - 61px)" }}>
      <iframe
        className="w-100 h-100 border-0"
        src="https://monitoring.zoop.tools/dashboard"
        title="Stack zoop monitoring service"
      ></iframe>
    </div>
  );
}

export default Monitor;

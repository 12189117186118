import React, { useContext, useEffect, useState } from "react";
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import OverviewCard from "../overview-card/overviewCard";
import RupeeSVG from "../../../../shared/svg/rupeeSvg";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../shared/colors";
import LowBalanceAlertIllustration from "../../../../assets/images/low-balance-alert.png";
import { Link } from "react-router-dom";
import { callGetApi } from "../../../../api/axios";
import { ERROR } from "../../../../utils/toastType";
import Loading from "../../../../shared/loading/loading";
import { ToastContext } from "../../../../context/toastContext";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../utils/updateAmount";
import { durationStyles } from "../../../../constants/durationStyles";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";

function LowBalanceAlert() {
  const [loading, setLoading] = useState(false);
  const [accountsHavingLowBalance, setAccountsHavingLowBalance] = useState([]);
  const dispatch = useContext(ToastContext);
  useEffect(() => {
    async function getAccountsHavingLowBalance() {
      setLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=low_balance"
        );
        const lowBalanceAlertAccountList = response.data.map((data: any) => ({
          name: data.name,
          alert_balance: roundOfDecimal(data.alert_balance),
          wallet_balance: roundOfDecimal(data.wallet_balance),
          alert_balance_set_at: data.alert_balance_set_at,
          id: data._id,
        }));
        setAccountsHavingLowBalance(lowBalanceAlertAccountList);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: error.response.data.err,
          },
        });
      }
    }

    getAccountsHavingLowBalance();
  }, [dispatch]);
  return (
    <div className="row">
      <OverviewCard
        illustrationUrl={LowBalanceAlertIllustration}
        headingText="Low Balance Alert"
        bodyText="Reach out to customers to get payments made immediately to avoid service termination."
      />
      <div className="col-lg-9 col-md-12 p-3">
        <div className={`${sharedStyle.card}`}>
          <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center pb-3">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                Low Balance Alert
              </p>
            </div>
          </div>
          {loading ? (
            <div
              style={{ height: "300px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : accountsHavingLowBalance.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <table style={{ width: "100%" }}>
                <thead className={sharedStyle.tableHeaderBack}>
                  <tr>
                    <th className={Styles.account_name_column}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Account Name
                      </p>
                    </th>
                    <th className={Styles.balance_alert}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Balance Alert
                      </p>
                    </th>
                    <th className={Styles.current_balance}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Current Balance
                      </p>
                    </th>
                    <th className={Styles.date}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Date
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className={Styles.tableWrapper}>
                  {accountsHavingLowBalance.map(
                    (account: any, index: number) => {
                      const date = new Date(
                        Number(account.alert_balance_set_at)
                      );
                      const duration = getDuration(date);
                      const style = getDurationStyle(duration, 7, 3);
                      return (
                        <tr
                          key={index}
                          style={
                            index !== accountsHavingLowBalance.length - 1
                              ? { borderBottom: "1px solid #eceeef" }
                              : { borderBottom: 0 }
                          }
                        >
                          <td className={Styles.account_name_column}>
                            <Link
                              to={`/application/accounts/${account.id}`}
                              className={`mb-0 ${Styles.accountName}`}
                            >
                              {account.name}
                            </Link>
                          </td>
                          <td className={Styles.balance_alert}>
                            <div className="d-flex align-items-center">
                              <div className="pr-2">
                                <RupeeSVG
                                  width="8"
                                  height="12"
                                  color={PRIMARYCOLOR}
                                />
                              </div>
                              <p
                                className={`mb-0 ${sharedStyle.tableBodyText}`}
                              >
                                {numberWithCommas(account.alert_balance)}
                              </p>
                            </div>
                          </td>
                          <td className={Styles.current_balance}>
                            <div className="d-flex align-items-center">
                              <div className="pr-2">
                                <RupeeSVG
                                  width="8"
                                  height="12"
                                  color={PRIMARYCOLOR}
                                />
                              </div>
                              <p
                                className={`mb-0 ${sharedStyle.tableBodyText}`}
                              >
                                {numberWithCommas(account.wallet_balance)}
                              </p>
                            </div>
                          </td>
                          <td className={Styles.date}>
                            {account.alert_balance_set_at ? (
                              <div
                                className={`mb-0 ${Styles.duration}`}
                                style={
                                  style === durationStyles.DANGER
                                    ? { color: "#d64747" }
                                    : style === durationStyles.WARNING
                                    ? { color: "#f4a63b" }
                                    : { color: "#31b975" }
                                }
                              >
                                {duration}
                              </div>
                            ) : null}
                            <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                              {account.alert_balance_set_at
                                ? `${monthName(
                                    date.getMonth()
                                  )} ${date.getDate()}, ${date.getFullYear()}`
                                : "NA"}
                            </p>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{ height: "300px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <p className="mb-0">No Account Found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LowBalanceAlert;

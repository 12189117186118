import React from "react";

function Sort({ classes, click, width = "12", height = "10", ...props }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 10.5"
      className={classes}
      onClick={click}
      {...props}
    >
      <g id="sort" transform="rotate(180 12.666 15.232)">
        <rect
          id="Rectangle_1612"
          width="4"
          height="2.5"
          rx="1.25"
          transform="translate(21.333 27.963)"
        />
        <rect
          id="Rectangle_1613"
          width="8"
          height="2.5"
          rx="1.25"
          transform="translate(17.333 23.963)"
        />
        <rect
          id="Rectangle_1614"
          width="12"
          height="2.5"
          rx="1.25"
          transform="translate(13.333 19.963)"
        />
      </g>
    </svg>
  );
}

export default Sort;

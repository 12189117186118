import React from "react";
import Button from "../../shared//button/button";
import { COMPRESSED } from "../../shared//buttonSize";
import {
  noBorderButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../shared/buttonStyles";
import popUpStyles from "../../shared//popup.module.scss";
import sharedStyle from "../../shared//sharedStyle.module.scss";

function ConfirmationModal({
  onCancel,
  onSuccess,
  isLoading,
  title,
  subTitle,
  btnText,
  forBatch,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div
        className={`${popUpStyles.popUpContent}`}
        style={{ width: forBatch ? "400px" : "500px" }}
      >
        <div
          className={`p-4 ${sharedStyle.cardHeaderConfirmationBack}`}
          style={{ backgroundColor: title ? "#ededed" : "" }}
        >
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>{title}</p>
        </div>
        <div className="p-4">
          <div
            className="py-2"
            style={{ width: forBatch ? "" : "400px", margin: "0 auto" }}
          >
            <p
              className={`mb-0 ${sharedStyle.tableBodyText}`}
              style={{
                whiteSpace: "pre-wrap",
                textAlign: "center",
                lineHeight: 1.8,
                fontSize: forBatch ? "16px" : "12px",
                letterSpacing: "normal",
                color: forBatch ? "#202020" : "#414141",
              }}
            >
              {subTitle}
            </p>
          </div>
        </div>
        <div
          className="py-3 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: forBatch ? "none" : "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={COMPRESSED}
              style={noBorderButtonStyle}
              hoveredStyle={noBorderButtonStyle}
              disabled={isLoading}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={COMPRESSED}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => onSuccess()}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {btnText || "Confirm"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;

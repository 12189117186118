import React, { useContext } from "react";
import Styles from "../addPaymentModal.module.scss";
import { SECONDARYCOLOR } from "../../../../../../../shared/colors";
import KeyValuePair from "../../../../../../../shared/key-value-pair/keyValuePair";
import ArrowDown from "../../../../../../../shared/svg/arrowDown";
import Button from "../../../../../../../shared/button/button";
import { MEDIUM } from "../../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../../shared/buttonStyles";
import { callPutApi } from "../../../../../../../api/axios";
import { ToastContext } from "../../../../../../../context/toastContext";
import { ERROR } from "../../../../../../../utils/toastType";

function NoPayment({ back, onCancel, onProceed, planPaymentPending, id }: any) {
  const dispatch = useContext(ToastContext);
  const [loading, setLoading] = React.useState(false);
  async function handleNoPayment() {
    setLoading(true);
    try {
      await callPutApi(`organisations/${id}?action=no_payment`, {});
      setLoading(false);
      onProceed();
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  return (
    <div>
      <div className="d-inline-flex py-2">
        <div
          className="d-flex align-items-center"
          onClick={() => back()}
          style={{ cursor: "pointer" }}
        >
          <div className={`pr-1 ${Styles.rotate}`}>
            <ArrowDown color={SECONDARYCOLOR} />
          </div>
          <p className={`mb-0 ${Styles.back}`}>back</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 py-3">
          <KeyValuePair label="Plan Id" value={planPaymentPending.plan_id} />
        </div>
        <div className="col-md-6 py-3">
          <KeyValuePair
            label="Plan Name"
            value={planPaymentPending.plan_name}
          />
        </div>
      </div>

      <div
        className="py-4 my-2 d-flex align-items-center justify-content-center"
        style={{ borderTop: "1px solid #ECEEEF" }}
      >
        <div className="px-2">
          <Button
            size={MEDIUM}
            style={cancelButtonStyle}
            hoveredStyle={cancelButtonHoverStyle}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
        <div className="px-2">
          <Button
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoverStyle}
            onClick={() => handleNoPayment()}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoPayment;

import React from "react";

const StartSvg = ({ fillBackgroundColor, fillInnerColor, border }: any) => (
  <svg
    width="70"
    height="42"
    viewBox="0 0 64 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="64"
      height="40"
      rx="6"
      fill={fillBackgroundColor}
      stroke={border}
      strokeLinejoin="round"
    />
    <path
      d="M27.6238 27.3976C27.3535 27.3976 27.0831 27.3276 26.8354 27.1863C26.5894 27.0485 26.3848 26.8473 26.243 26.6035C26.1012 26.3597 26.0274 26.0824 26.0293 25.8004V15.199C26.0293 14.618 26.3306 14.1 26.8354 13.8131C27.3397 13.5256 27.9388 13.5317 28.4382 13.8291L37.3441 19.13C37.8313 19.4202 38.1226 19.9322 38.1226 20.5C38.1226 21.0677 37.8313 21.5797 37.3441 21.8699L28.4387 27.1709C28.1925 27.3186 27.9109 27.3969 27.6238 27.3976ZM27.626 14.7069C27.5399 14.7076 27.4555 14.7302 27.3805 14.7725C27.3045 14.8146 27.2413 14.8765 27.1976 14.9516C27.1539 15.0266 27.1313 15.1121 27.1322 15.199V25.8009C27.1322 26.0586 27.3055 26.1849 27.3805 26.2274C27.4556 26.2712 27.5412 26.2939 27.6281 26.293C27.715 26.2922 27.8001 26.2678 27.8743 26.2225L36.7797 20.9215C36.996 20.7929 37.0197 20.5838 37.0197 20.5C37.0197 20.4161 36.9965 20.207 36.7797 20.0785L27.8737 14.7775C27.7993 14.7315 27.7135 14.7071 27.626 14.7069Z"
      fill={fillInnerColor}
    />
  </svg>
);

export default StartSvg;
//"#DDDCDD"
//#747474

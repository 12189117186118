/**
 * Desc - filterTagModal is a reusable componet that renders user selected filters stored in a react state, as small capsules design tags with individual cross button for each tag and clear all button for all tags
 * It expects 4 props -- 1 variable, 3 callbacks - allFilters, clearAllFilters(), removeFilterTag() and extractTagTitle()
 * {State}: allFilters should be an array of objects s.t., each object in it is an individual filter, having details of that filter, name, price etc. depends on context
 *
 *
 * {Function}: clearAllFilters() -- is handler defined in parent component, for clearAll button, to clear all applied filters
 *      example of clearAllFilters() for user selected filters stored in an array of object allFilters[], maintained as react state in parent component st. each object definition is as : {productName: string, productPricePoint: string} -->
 *
 *          function clearAllFilters(filter: any) {
 *              setAllFilters([])
 *          }
 *
 *
 * {Function}: removeFilterTag(filterToBeRemoved: any) -- is handler which is to be defined in parent component, for 'X' svg button, to remove a single filter tag, takes the filter to be removed as argument
 *     example of removeAllFilters() for user selected filters stored in an array of object allFilters[], maintained as react state in parent component st. each object definition is as : {productName: string, productPricePoint: string} -->
 *
 *          function removeAllFilters(filterToBeRemoved:any){
 *              if (allFilters.includes(filterToBeRemoved)) {
 *                   setAllFilters(allFilters.filter( // .filter() method, not iterable variable
 *                      (filter: any) => { // filter iterable variable not .filter method()
 *                          return Object.entries(filter).toString() !== Object.entries(filterToBeRemoved).toString()
 *                       }
 *                   ));
 *              }
 *          }
 *
 *
 * {Function}: extractTagTitle(filter: any) -- is handler which is to be defined in parent component, for extracting title text of individual filter, just provide a callback that fetches title of the tag from each filter object's definition
 *      example of extractTagTitle() for a filter with object definition as  filter: {productName: string, productPricePoint: string} -->
 *
 *      function extractTagTitle(filter: any) {
 *            return `${filter.productName} ₹ ${filter.productPricePoint}`;
 *      }
 *      //Above callback takes in a filter object, extracts title from its key-value pairs and returns it
 *
 * Example use case of FilterTagModal -->
 * <FilterTagModal allFilters={filtersArray} extractTagTitle={callback} clearAllFilters={callback} removeFilterTag={callback} />
 */

import Styles from "./filterTagModal.module.scss";
import React from "react";
import Close from "../svg/close";

function FilterTagModal(props: any) {
  return (
    <div className="d-flex flex-column">
      {props.allFilters.length > 0 && (
        <div className="d-flex align-items-center flex-wrap">
          {/*filter tag rendering below */}
          {props.allFilters.map((filter: any, index: number) => {
            const filterTitle: any = props.extractTagTitleHandler(filter);
            return (
              <div className={"p-2"} key={index}>
                {" "}
                {/*parent container for all filter tags*/}
                <div
                  className={`${Styles.tagContainer} px-3 py-2 d-flex align-items-center`}
                >
                  {" "}
                  {/*parent container for individual filter tag*/}
                  {/* <p className={`mb-0 px-2 ${Styles.tagTitle}`}>{{...filterTitle}}</p> */}
                  {filterTitle}
                  <div
                    className="px-1"
                    style={{ marginBottom: "2px" }}
                    onClick={() => props.removeFilterTagHandler(filter)}
                  >
                    {" "}
                    {/*parent container for close button*/}
                    <Close classes={Styles.close} width="8" height="8" />
                  </div>
                </div>
              </div>
            );
          })}
          <p
            className={` ${Styles.clearAll}`}
            onClick={props.clearAllFiltersHandler}
          >
            Clear All
          </p>
        </div>
      )}
    </div>
  );
}

export default FilterTagModal;

import React, { useEffect, useState } from "react";
import Styles from "./popupModal.module.scss";
import Modal from "react-bootstrap/Modal";
import Close from "../svg/close";

function PopupModal(props: any) {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    props.handleClose && props.handleClose(); //handling null case of handleClose
  };

  useEffect(() => {
    setShowModal(props.show); //outer state from parent setting inner state in child
  }, [props.show]); //props.show changes upon ONLY clicking filter button in plans tab

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{props.title}</Modal.Title>
        <button
          onClick={handleModalClose}
          className={`${Styles.closeModalButton}`}
        >
          {" "}
          {/*separate close button cuz of lib mismatch in package.json*/}
          <Close classes={Styles.close} width="14" height="14" />
        </button>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default PopupModal;

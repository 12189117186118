const days = [
  { label: "1", value: "01" },
  { label: "2", value: "02" },
  { label: "3", value: "03" },
  { label: "4", value: "04" },
  { label: "5", value: "05" },
  { label: "6", value: "06" },
  { label: "7", value: "07" },
  { label: "8", value: "08" },
  { label: "9", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];
const months = [
  { label: "JAN", value: "01" },
  { label: "FEB", value: "02" },
  { label: "MAR", value: "03" },
  { label: "APR", value: "04" },
  { label: "MAY", value: "05" },
  { label: "JUN", value: "06" },
  { label: "JUL", value: "07" },
  { label: "AUG", value: "08" },
  { label: "SEP", value: "09" },
  { label: "OCT", value: "10" },
  { label: "NOV", value: "11" },
  { label: "DEC", value: "12" },
];

const reportYears = [
  { label: "2015", value: "2015" },
  { label: "2016", value: "2016" },
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
  { label: "2031", value: "2031" },
  { label: "2032", value: "2032" },
  { label: "2033", value: "2033" },
  { label: "2034", value: "2034" },
  { label: "2035", value: "2035" },
  { label: "2036", value: "2036" },
  { label: "2037", value: "2037" },
  { label: "2038", value: "2038" },
  { label: "2039", value: "2039" },
  { label: "2040", value: "2040" },
  { label: "2041", value: "2041" },
  { label: "2042", value: "2042" },
  { label: "2043", value: "2043" },
  { label: "2044", value: "2044" },
  { label: "2045", value: "2045" },
  { label: "2046", value: "2046" },
  { label: "2047", value: "2047" },
  { label: "2048", value: "2048" },
  { label: "2049", value: "2049" },
  { label: "2050", value: "2050" },
];

export { months, days, reportYears };

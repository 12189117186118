/**
 * Implementation of Login component
 *
 * @author - Harsh Tomar
 */

// Dependencies
import React, { useContext } from "react";
import Styles from "./login.module.scss";
import logo from "./../../../assets/images/logo.png";
import Input from "../../../shared/input/input";
import Button from "../../../shared/button/button";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../shared/buttonStyles";
import { LARGE } from "../../../shared/buttonSize";
import firebase from "firebase";
import { ERROR } from "../../../utils/toastType";
import { ToastContext } from "../../../context/toastContext";
import { Link } from "react-router-dom";
import { checkIsEmpty, isValidEmail } from "../../../utils/validation";
import ErrorMessage from "../../../shared/error-message/errorMessage";

export default function Login() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [phoneNumberAuth, setPhoneNumberAuth] = React.useState<any>({
    isPhoneAuth: false,
  });
  const [verificationCode, setVerificationCode] = React.useState<string>("");
  const [phoneVerificationResolver, setPhoneVerificationResolver] =
    React.useState<any>();

  const [error, setError] = React.useState({
    email_error: "",
    password_error: "",
    otp_error: "",
  });
  const [loading, setLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);

  function checkEmail() {
    if (checkIsEmpty(email)) {
      setError((error) => ({
        ...error,
        email_error: "Email cannot be empty",
      }));
      return false;
    }
    if (!isValidEmail(email)) {
      setError((error) => ({
        ...error,
        email_error: "Enter a valid email",
      }));
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = email.split("@");
    if (!domain.toLocaleLowerCase().includes("zoop.one")) {
      setError((error) => ({
        ...error,
        email_error: "Only zoop emails allowed",
      }));
      return false;
    }
    return true;
  }

  function checkPassword() {
    if (checkIsEmpty(password)) {
      setError((error) => ({
        ...error,
        password_error: "Password cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkOtp() {
    if (checkIsEmpty(verificationCode)) {
      setError((error) => ({
        ...error,
        otp_error: "OTP cannot be empty",
      }));
      return false;
    }

    if (verificationCode?.length !== 6) {
      setError((error) => ({
        ...error,
        otp_error: "OTP should be 6 number",
      }));
      return false;
    }

    return true;
  }

  // Call the function to send the OTP on .
  const phoneNumberVerification = (resolver: any, selectedIndex: number) => {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
      }
    );

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then(function (verificationId) {
        // Set the { verificationId } in state got in the response.
        // To enable the Phone number verification
        setPhoneNumberAuth({ verificationId, isPhoneAuth: true });
        setLoading(false);
      });
  };

  // Call the function on OTP submit.
  const _onPhoneVerificationNumber = (e: any) => {
    e.preventDefault();
    const allCheckPasses = [checkOtp()].every(Boolean);

    if (loading || !allCheckPasses) return;

    // Start loading - Login button
    setLoading(true);

    // Ask user for the SMS verification code.
    const cred = firebase.auth.PhoneAuthProvider.credential(
      phoneNumberAuth?.verificationId,
      verificationCode
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in.
    phoneVerificationResolver
      ?.resolveSignIn(multiFactorAssertion)
      .then(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        if (error.code === "auth/invalid-verification-code") {
          setLoading(false);
          return dispatch({
            type: "ADD_TOAST",
            payload: {
              id: Math.floor(Math.random() * 100),
              type: ERROR,
              message: "Invalid OTP",
            },
          });
        }

        setPhoneNumberAuth(null);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "This email id is not registered with any account",
          },
        });
      });
  };

  function _handleSubmit(e: any) {
    e.preventDefault();
    const allCheckPasses = [checkEmail(), checkPassword()].every(Boolean);

    if (loading || !allCheckPasses) return;

    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        if (error.code == "auth/multi-factor-auth-required") {
          const resolver = error.resolver;
          const selectedIndex = 0;
          setPhoneVerificationResolver(resolver);

          // Ask user which second factor to use.
          if (
            resolver.hints[selectedIndex].factorId ===
            firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
          ) {
            // Send SMS verification code
            return phoneNumberVerification(error.resolver, selectedIndex);
          }
        }

        setLoading(false);

        if (error.code == "auth/wrong-password") {
          setLoading(false);
          // Handle other errors such as wrong password.
          return dispatch({
            type: "ADD_TOAST",
            payload: {
              id: Math.floor(Math.random() * 100),
              type: ERROR,
              message: "Invalid email Id or Password",
            },
          });
        }

        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "This email id is not registered with any account",
          },
        });
      });
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.innerContainer}>
        <div className={Styles.loginContainer}>
          <div className={Styles.left}>
            <img className={Styles.logo} src={logo} alt="Zoop.one Logo" />
          </div>
          <div className={Styles.right}>
            <h3 className={Styles.heading}>Admin Login</h3>
            {phoneNumberAuth?.isPhoneAuth ? (
              <>
                <form onSubmit={_onPhoneVerificationNumber}>
                  <div className="form-group mb-0">
                    <Input
                      type="number"
                      placeholder="123456"
                      labelname="Enter OTP"
                      onChange={(e: any) => {
                        setVerificationCode(e.target.value);
                        setError((error) => ({
                          ...error,
                          otp_error: "",
                        }));
                      }}
                      value={verificationCode}
                      onBlur={checkOtp}
                    />
                    {error.otp_error && (
                      <ErrorMessage>{error.otp_error}</ErrorMessage>
                    )}
                  </div>
                  <Button
                    style={primaryButtonStyle}
                    size={LARGE}
                    hoveredStyle={primaryButtonHoverStyle}
                    isLoading={loading}
                  >
                    Login
                  </Button>
                </form>
              </>
            ) : (
              <form onSubmit={_handleSubmit}>
                <div className="form-group mb-0">
                  <Input
                    type="email"
                    placeholder="name@zoop.one"
                    labelname="Enter your E-mail"
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setError((error) => ({
                        ...error,
                        email_error: "",
                      }));
                    }}
                    value={email}
                    onBlur={checkEmail}
                  />
                  {error.email_error && (
                    <ErrorMessage>{error.email_error}</ErrorMessage>
                  )}
                </div>

                <div className="form-group mb-0">
                  <Input
                    type="password"
                    placeholder="Secret Password"
                    labelname="Enter your Password"
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setError((error) => ({
                        ...error,
                        password_error: "",
                      }));
                    }}
                    value={password}
                    onBlur={checkPassword}
                  />
                  {error.password_error && (
                    <ErrorMessage>{error.password_error}</ErrorMessage>
                  )}
                </div>
                <Link
                  to="/change-password"
                  className={Styles.forgotPasswordLink}
                >
                  Forgot Password?
                </Link>
                <br />
                <div
                  id="sign-in-button"
                  className={Styles.recaptchaContainer}
                ></div>
                <Button
                  style={primaryButtonStyle}
                  size={LARGE}
                  hoveredStyle={primaryButtonHoverStyle}
                  isLoading={loading}
                >
                  Login
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Styles from "../plan-details/planDetails.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../shared/colors";
import ArrowDown from "../../../../shared/svg/arrowDown";
import { useHistory } from "react-router";
import Button from "../../../../shared/button/button";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import { COMPRESSED } from "../../../../shared/buttonSize";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import Input from "../../../../shared/input/input";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import { planNames } from "../../../../utils/planNames";
import PlanRateCard from "./plan-rate-card/planRateCard";
import { isObject, numberRegexp } from "../../../../utils/validation";
import { callGetApi, callPostApi } from "../../../../api/axios";
import { ToastContext } from "../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import Loading from "../../../../shared/loading/loading";
import { deprecatedProducts } from "../../../../constants/deprecatedProducts";
import { plans } from "../../../../constants/navItems";
import { PermissionContext } from "../../../../context/permissionContext";

export default function CreatePlan() {
  const history = useHistory();
  const planTypes = {
    PREPAID: "Prepaid",
    POSTPAID: "Postpaid",
  };
  const planValidities = [
    "0 Months",
    "3 Months",
    "6 Months",
    "9 Months",
    "12 Months",
  ];
  const dispatch = useContext(ToastContext);
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const grant = assets[plans][plans].grant;
  const permissions = assets[plans][plans].permissions;
  const [planDetails, setPlanDetails] = useState<any>();
  const [productPricing, setProductPricing] = useState({});
  const [planDetailsLoading, setPlanDetailsLoading] = useState(false);
  const [createPlanLoading, setCreatePlanLoading] = useState(false);
  const [error, setError] = useState({
    plan_name_error: "",
    plan_amount: "",
    plan_type: "",
    plan_validity: "",
  });
  const [pricingError, setPricingError] = useState<any>([]);
  const [addonPriceError, setAddonPriceError] = useState<any>([]);
  const [subAddonPriceError, setSubAddonPriceError] = useState<any>([]);

  async function getPlanDetails(plan_name: string) {
    setPlanDetailsLoading(true);
    try {
      const { data }: any = await callGetApi(
        `/plans/getPlanByName/${plan_name}`
      );
      const filteredProducts = data.pricing.filter(
        (product: any) => !deprecatedProducts.includes(product.productArn)
      );
      setPlanDetails({ ...data, pricing: filteredProducts });
      setPlanDetailsLoading(false);
    } catch (err: any) {
      setPlanDetailsLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  useEffect(() => {
    setError((prevError: any) => {
      if (
        planDetails?.plan_type === "POSTPAID" &&
        planDetails.prepaid_recharge_amount === "0"
      ) {
        return { ...prevError, plan_amount: "" };
      }

      return { ...prevError, plan_amount: "" };
    });
  }, [planDetails, setPlanDetails]);

  useEffect(() => {
    if (!permissions[`${permissionsType.write}_${grant}`]) {
      history.push("/application/home");
    }
    const addonErrors: any = [];
    const subAddonErrors: any = [];

    const price = planDetails?.pricing?.map((product: any) => {
      if (product.addons.length > 0) {
        product.addons.map((addon: any) => {
          // sub-addons validations
          if (addon?.sub_addons?.length) {
            addon.sub_addons.map((subAddon: any) => {
              if (
                product.productPricing[addon.addon_key][
                  subAddon?.sub_addon_key
                ] < 0 ||
                !String(
                  product.productPricing[addon.addon_key][
                    subAddon?.sub_addon_key
                  ]
                )
              ) {
                subAddonErrors.push({
                  product_arn: product.productArn,
                  addon_arn: addon.addon_key,
                  sub_addon_arn: subAddon?.sub_addon_key,
                  error_message:
                    "Addon price should be greater than or equal to 0",
                });
              }
            });
          }

          if (
            addon.addon_key === "name_match" &&
            product?.productArn !== "in_financial_bav_lite" &&
            (product.productPricing[addon.addon_key] < 0.5 ||
              (!String(product.productPricing[addon.addon_key]) &&
                product.productPricing[addon.addon_key] !== 0))
          ) {
            addonErrors.push({
              product_arn: product.productArn,
              addon_arn: addon.addon_key,
              error_message: "addon amount should be greater than 0.5",
            });
            return addonErrors;
          } else if (
            (isObject(product.productPricing[addon.addon_key])
              ? !String(product.productPricing[addon.addon_key]?.unit_price)
              : !String(product.productPricing[addon.addon_key])) ||
            product.productPricing[addon.addon_key] < 0
          ) {
            addonErrors.push({
              product_arn: product.productArn,
              addon_arn: addon.addon_key,
              error_message: "Addon price should be greater than or equal to 0",
            });
            return addonErrors;
          }
          return null;
        });
      }

      setAddonPriceError(addonErrors);
      setSubAddonPriceError(subAddonErrors);

      setProductPricing((productPricing) => ({
        ...productPricing,
        [product.productArn]: product?.productPricing,
      }));
      const product_price =
        Object.keys(product?.productPricing).length > 0
          ? product.productPricing.unit_price
          : product.productPricing;

      if (!String(product_price)) {
        return {
          error_for_product: product.productArn,
          error_message: `price should be greater than ${product.productLowerBound}`,
        };
      }

      if (product_price < product?.productLowerBound) {
        return {
          error_for_product: product.productArn,
          error_message: `price should be greater than ${product.productLowerBound}`,
        };
      }
      return {
        error_for_product: product.productArn,
        error_message: "",
      };
    });
    setPricingError(price);
  }, [planDetails, history]);

  function checkPlanName() {
    if (!planDetails?.plan_name) {
      setError((error) => ({
        ...error,
        plan_name_error: "Please select a plan name",
      }));
      return false;
    }
    return true;
  }

  // use this function to check plan validity
  function checkPlanValidity() {
    if (
      planDetails?.plan_validity === null ||
      planDetails?.plan_validity === undefined
    ) {
      setError((error) => ({
        ...error,
        plan_validity: "Please select a plan Validity",
      }));
      return false;
    }
    return true;
  }
  // use this function to check the plan type
  function checkPlanType() {
    if (
      planDetails?.plan_validity === null ||
      planDetails?.plan_validity === undefined
    ) {
      setError((error) => ({
        ...error,
        plan_type: "Please select a type of a plan",
      }));
      return false;
    }
    return true;
  }
  // use this function to check the plan amount
  function checkPlanAmount() {
    if (!numberRegexp.test(planDetails?.prepaid_recharge_amount)) {
      setError((error) => ({
        ...error,
        plan_amount: "Enter a valid amount",
      }));
      return false;
    }
    if (Number(planDetails?.prepaid_recharge_amount) < 0) {
      setError((error) => ({
        ...error,
        plan_amount: "Amount should not be negative",
      }));
      return false;
    }
    if (!Number.isInteger(Number(planDetails?.prepaid_recharge_amount))) {
      setError((error) => ({
        ...error,
        plan_amount: "Plan amount does not allow decimals",
      }));
      return false;
    }
    if (
      planDetails?.plan_type === "PREPAID" &&
      Number(planDetails?.prepaid_recharge_amount) === 0
    ) {
      setError((prevError) => ({
        ...prevError,
        plan_amount: "Amount should not be zero in Prepaid plan",
      }));
      return false;
    }
    return true;
  }
  // use this function to create a plan
  async function handleCreatePlan() {
    const allCheckPassed = [
      checkPlanAmount(),
      checkPlanName(),
      checkPlanType(),
      checkPlanValidity(),
    ].every(Boolean);
    const isErrorPresentForPricing = pricingError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForAddon = addonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    if (isErrorPresentForPricing.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Price per transaction",
        },
      });
      return;
    }

    if (isErrorPresentForAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Addon Amount",
        },
      });
      return;
    }

    if (!allCheckPassed) {
      return;
    }
    // calling api for create a plan
    setCreatePlanLoading(true);
    try {
      await callPostApi("/plans", {
        plan_name: planDetails?.plan_name,
        plan_type: planDetails?.plan_type,
        plan_validity: Number(planDetails?.plan_validity),
        prepaid_recharge_amount: Number(planDetails?.prepaid_recharge_amount),
        pricing: productPricing,
      });
      setCreatePlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: "Plan created successfully",
        },
      });

      history.replace("/application/plans");
    } catch (err: any) {
      setCreatePlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  const loading = (
    <div
      style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  return (
    <>
      {planDetailsLoading ? (
        loading
      ) : (
        <div className="p-3">
          <div className="d-inline-flex py-2">
            <div
              className="d-flex align-items-center"
              onClick={() => history.push("/application/plans")}
              style={{ cursor: "pointer" }}
            >
              <div className={Styles.rotate}>
                <ArrowDown color={SECONDARYCOLOR} />
              </div>
              <p className={`mb-0 ${Styles.back}`}>back</p>
            </div>
          </div>
          <div className="py-3">
            <div className={sharedStyle.card}>
              <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
                <div className="d-flex align-items-center">
                  <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                    Plan Details
                  </p>
                  <div className="pl-2 ml-auto">
                    <div className="d-flex align-items-center">
                      <div className="pl-2">
                        <Button
                          style={cancelButtonStyle}
                          hoveredStyle={cancelButtonHoverStyle}
                          size={COMPRESSED}
                          disabled={createPlanLoading}
                          onClick={() => {
                            history.replace("/application/plans");
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="pl-2">
                        <Button
                          style={primaryButtonStyle}
                          hoveredStyle={primaryButtonHoverStyle}
                          size={COMPRESSED}
                          onClick={handleCreatePlan}
                          isLoading={createPlanLoading}
                          disabled={
                            createPlanLoading ||
                            !permissions[`${permissionsType.write}_${grant}`]
                          }
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-md-3 col-sm-4">
                      <div className="py-2">
                        <InputDropdown
                          labelname="Plan Name"
                          hasError={error.plan_name_error}
                          optionsArray={planNames}
                          click={(plan_name: string) => {
                            getPlanDetails(plan_name);
                          }}
                          defaultValue={planDetails?.plan_name}
                          no_shadow="true"
                        />
                        {error.plan_name_error && (
                          <ErrorMessage>{error.plan_name_error}</ErrorMessage>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4">
                      <div>
                        <Input
                          no_shadow="true"
                          type="text"
                          name="plan_amount"
                          maxLength={10}
                          disabled={!planDetails}
                          id="plan_amount"
                          value={planDetails?.prepaid_recharge_amount}
                          autoComplete="off"
                          hasError={error.plan_amount}
                          placeholder="Enter Plan Amount"
                          labelname="Plan Amount"
                          onChange={(event: any) => {
                            setPlanDetails((planDetails: any) => ({
                              ...planDetails,
                              prepaid_recharge_amount: event.target.value,
                            }));
                            setError((error: any) => ({
                              ...error,
                              plan_amount: "",
                            }));
                          }}
                          onBlur={checkPlanAmount}
                        />
                        {error.plan_amount && (
                          <ErrorMessage>{error.plan_amount}</ErrorMessage>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4">
                      <div className="py-2">
                        <InputDropdown
                          labelname="Plan Type"
                          hasError={error.plan_type}
                          disabled={!planDetails}
                          optionsArray={Object.keys(planTypes)}
                          click={(plan_type: string) => {
                            setPlanDetails((planDetails: any) => ({
                              ...planDetails,
                              plan_type: plan_type.toUpperCase(),
                            }));
                            setError((error) => ({
                              ...error,
                              plan_type: "",
                            }));
                          }}
                          defaultValue={planDetails?.plan_type}
                          no_shadow="true"
                        />
                        {error.plan_type && (
                          <ErrorMessage>{error.plan_type}</ErrorMessage>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4">
                      <div className="py-2">
                        <InputDropdown
                          labelname="Plan Validity"
                          hasError={error.plan_validity}
                          disabled={!planDetails}
                          optionsArray={planValidities}
                          click={(plan_validity: string) => {
                            const validity = plan_validity.split(" ")[0];
                            setPlanDetails((planDetails: any) => ({
                              ...planDetails,
                              plan_validity: validity,
                            }));
                            setError((error) => ({
                              ...error,
                              plan_validity: "",
                            }));
                          }}
                          defaultValue={
                            /*   `${planDetails?.plan_validity} Months` */
                            planDetails?.plan_validity !== null &&
                            planDetails?.plan_validity !== undefined
                              ? `${planDetails?.plan_validity} Months`
                              : ""
                          }
                          no_shadow="true"
                        />
                        {error.plan_validity && (
                          <ErrorMessage>{error.plan_validity}</ErrorMessage>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {planDetails?.pricing && (
            <PlanRateCard
              addonPriceError={addonPriceError}
              pricingError={pricingError}
              products={planDetails?.pricing}
              subAddonPriceError={subAddonPriceError}
              onUpdatePricing={(pricing: any) => {
                setPlanDetails((planDetails: any) => ({
                  ...planDetails,
                  pricing: pricing,
                }));
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

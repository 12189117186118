// Connecting all auth,onboarding and application components together.
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Application from "./components/application/application";
import AuthState from "./components/auth/AuthState/AuthState";
import ChangePassword from "./components/auth/ChangePassword/changePassword";
import Login from "./components/auth/login/login";
import PermissionProvider from "./context/permissionContext";

function Routes() {
  return (
    <Router>
      <Switch>
        <AuthState>
          <Route path={"/login"} component={Login} />
          <Route path="/change-password" component={ChangePassword} />
          <PermissionProvider>
            <Route
              exact
              path={"/application"}
              component={() => <Redirect to="/application/home" />}
            />
            <Route path={"/application/:value"} component={Application} />
          </PermissionProvider>
        </AuthState>
      </Switch>
    </Router>
  );
}

export default Routes;

export function numberWithCommas(value: any) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function roundOfDecimal(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function getAmountWithGST(value: number) {
  return value + 0.18 * value;
}

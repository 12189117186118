import React, { useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { callGetApi } from "../../../../../api/axios";
import { ToastContext } from "../../../../../context/toastContext";
import { ERROR } from "../../../../../utils/toastType";
import ActiveProducts from "./active-products/activeProducts";
import PendingProducts from "./pending-products/pendingProducts";
import UnsubscribedProducts from "./unsubscribed-products/unsubscribedProducts";

function Products({
  org_status,
  is_allowed_to_write,
  is_allowed_to_delete,
}: any) {
  const { id }: any = useParams();
  const dispatch = useContext(ToastContext);

  const [activeProduct, setActiveProducts] = React.useState([]);
  const [unsubscribedProduct, setUnsubscribedProducts] = React.useState([]);
  const [pendingProducts, setPendingProducts] = React.useState([]);

  const [isProductApprovalLoading, setIsProductApprovalLoading] =
    React.useState(false);
  const [isActiveProductsLoading, setIsActiveProductsLoading] =
    React.useState(false);
  const [isUnsubscribeProductLoading, setisUnsubscribeProductLoading] =
    React.useState(false);

  const getAllActiveProducts = useCallback(async () => {
    setIsActiveProductsLoading(true);
    try {
      const response: any = await callGetApi(
        `/organisations/${id}?data=subscribed_products`
      );
      setIsActiveProductsLoading(false);
      const products = response.data.map((data: any) => ({
        id: data._id,
        name: data?.product?.name,
        arn: data?.product?.arn,
        available_test_credits: data.available_test_credits,
        activation_date: data.auto_approved
          ? data.updatedAt
          : data.authorized_on,
        status: data.status == "SUBSCRIBED" ? "Subscribed" : "NA",
        apps_linked: data.app_count,
        approved_by: data.auto_approved
          ? "Auto Approved"
          : data?.authorized_by
          ? data?.authorized_by.name
          : "User not found",
      }));
      setActiveProducts(products);
    } catch (err: any) {
      setIsActiveProductsLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    getAllActiveProducts();
  }, [getAllActiveProducts]);

  const getAllUnsubscribedProducts = useCallback(async () => {
    setisUnsubscribeProductLoading(true);
    try {
      const response: any = await callGetApi(
        `/organisations/${id}?data=unsubscribed_products`
      );
      setisUnsubscribeProductLoading(false);
      const products = response.data.map((data: any) => ({
        id: data.id,
        name: data?.name,
        arn: data?.arn,
      }));
      setUnsubscribedProducts(products);
    } catch (err: any) {
      setisUnsubscribeProductLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    getAllUnsubscribedProducts();
  }, [getAllUnsubscribedProducts]);

  const getAllPendingProducts = useCallback(async () => {
    setIsProductApprovalLoading(true);
    try {
      const response: any = await callGetApi(
        `/organisations/${id}?data=pending_product_approvals`
      );
      const pendingProducts = response.data.map((data: any) => ({
        id: data._id,
        name: data?.product?.name,
        status: data.status === "PENDING_APPROVAL" ? "Approval Pending" : "NA",
        requested_on: data.updatedAt,
      }));
      setIsProductApprovalLoading(false);
      setPendingProducts(pendingProducts);
    } catch (err: any) {
      setIsProductApprovalLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    getAllPendingProducts();
  }, [getAllPendingProducts]);

  return (
    <div className="py-3 container-fluid">
      <div className="py-3">
        <PendingProducts
          id={id}
          org_status={org_status}
          isLoading={isProductApprovalLoading}
          pendingProducts={pendingProducts}
          getUpdateProducts={() => getAllActiveProducts()}
          getUnsubscribedProducts={() => getAllUnsubscribedProducts()}
          is_allowed_to_write={is_allowed_to_write}
          is_allowed_to_delete={is_allowed_to_delete}
        />
      </div>
      <div className="py-3">
        <ActiveProducts
          org_status={org_status}
          activeProduct={activeProduct}
          isLoading={isActiveProductsLoading}
          getUpdatedProducts={() => getAllActiveProducts()}
          getUnsubscribedProducts={() => getAllUnsubscribedProducts()}
          is_allowed_to_write={is_allowed_to_write}
          is_allowed_to_delete={is_allowed_to_delete}
        />
      </div>
      <div className="py-3">
        <UnsubscribedProducts
          unsubscribedProduct={unsubscribedProduct}
          isLoading={isUnsubscribeProductLoading}
          getUnsubscribedProducts={() => getAllUnsubscribedProducts()}
          getActiveProducts={() => getAllActiveProducts()}
          getPendingProducts={() => getAllPendingProducts()}
          is_allowed_to_write={is_allowed_to_write}
          is_allowed_to_delete={is_allowed_to_delete}
        />
      </div>
    </div>
  );
}

export default Products;

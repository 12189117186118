import React from "react";
import Button from "../../../../../../shared/button/button";
import { MEDIUM } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import FilterDropdown from "../../../../../../shared/inTable-filter-dropdown/filterDropdown";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import Styles from "../products.module.scss";
import ArrowDown from "../../../../../../shared/svg/arrowDown";
import { PRIMARYCOLOR } from "../../../../../../shared/colors";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import { roleType } from "../../../../../../constants/userRoles";

function AddTestCredits({ isLoading, products, onCancel, onUpdate }: any) {
  const [testCredits, setTestCredits] = React.useState<any>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<any>([]);
  const [error, setError] = React.useState({
    error_for_product: "",
    error_message: "",
  });
  const [testCreditFieldEmpty, setTestCreditFieldsEmpty] =
    React.useState(false);
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
  function removeProductFromList(name: string, arn: string) {
    setSelectedProducts(
      selectedProducts.filter((product: any) => product.name !== name)
    );
    setTestCredits(
      Object.keys(testCredits).reduce((object: any, key: any) => {
        if (key !== arn) {
          object[key] = testCredits[key];
        }
        return object;
      }, {})
    );
  }
  function checkTestCredit(inputValue: any, product: any) {
    const maxCredits = currentUser.role === roleType.admin ? 100000 : 500;

    if (inputValue >= 0 && inputValue <= maxCredits) {
      setTestCredits({
        ...testCredits,
        [product.arn]: Number(inputValue),
      });
      setError({
        error_for_product: "",
        error_message: "",
      });
    } else {
      setError({
        error_for_product: product,
        error_message:
          currentUser.role === roleType.admin
            ? "Note: Only up to 100000 credits can be assigned for admin user only"
            : "Note: Only up to 500 credits can be assigned",
      });
    }
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "600px" }}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Add Test Credits</p>
        </div>
        <div className="p-3">
          <div style={{ width: "80%" }} className="p-3">
            <p
              className="mb-0 py-1"
              style={{ fontSize: "14px", color: PRIMARYCOLOR }}
            >
              Please Select Product(s)
            </p>
            <FilterDropdown
              width="100%"
              height="200px"
              optionArray={products.map((product: any) => product.name)}
              filters={selectedProducts.map((product: any) => product.name)}
              onSelect={(option: any) => {
                const product = products.find(
                  (product: any) => product.name === option
                );
                if (
                  selectedProducts.findIndex(
                    (selectedProduct: any) =>
                      selectedProduct.name === product.name
                  ) > -1
                ) {
                  removeProductFromList(product.name, product.arn);
                  return;
                }
                setSelectedProducts([...selectedProducts, product]);
                setTestCredits({ ...testCredits, [product.arn]: 0 });
              }}
              toggleReset={testCredits.length !== 0}
              onReset={() => {
                setSelectedProducts([]);
                setTestCredits([]);
              }}
            >
              <div
                className={`d-flex align-items-center p-2 ${Styles.overallFilterBack}`}
              >
                <div className="px-2 flex-grow-1">
                  <p className={`mb-0 ${Styles.filterLabel}`}>Add Products</p>
                </div>
                <div className="px-2">
                  <ArrowDown classes={Styles.arrow} />
                </div>
              </div>
            </FilterDropdown>
          </div>
          <div
            className="px-3 py-2"
            style={{ minHeight: "200px", maxHeight: "350px", overflow: "auto" }}
          >
            {selectedProducts.map((product: any, index: number) => {
              return (
                <div className="py-2" key={index}>
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor={`id${index}`}
                      className={`mb-0 ${Styles.serviceName}`}
                    >
                      {product.name}
                    </label>
                    <div className="ml-auto">
                      <p
                        className={`mb-0 ${Styles.remove}`}
                        onClick={() => {
                          removeProductFromList(product.name, product.arn);
                        }}
                      >
                        Remove
                      </p>
                    </div>
                  </div>
                  <div className={`${Styles.inputWrapper} py-1`}>
                    <input
                      type="text"
                      id={`id${index}`}
                      className={Styles.formControl}
                      value={testCredits[product.arn]}
                      maxLength={currentUser.role === roleType.admin ? 6 : 3}
                      placeholder="Enter Test Credits"
                      onChange={(event: any) => {
                        checkTestCredit(event.target.value, product);
                      }}
                    />
                  </div>
                  {error.error_for_product === product && (
                    <ErrorMessage>{error.error_message}</ErrorMessage>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="pl-4">
          {testCreditFieldEmpty && (
            <ErrorMessage>
              One or more test credit field is empty or zero.
            </ErrorMessage>
          )}
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={MEDIUM}
              disabled={isLoading}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={MEDIUM}
              disabled={selectedProducts.length === 0 || isLoading}
              isLoading={isLoading}
              style={successButtonStyle}
              hoveredStyle={successButtonHoverStyle}
              onClick={() => {
                if (Object.values(testCredits).includes(0)) {
                  setTestCreditFieldsEmpty(true);
                  return;
                }
                setTestCreditFieldsEmpty(false);
                onUpdate(testCredits);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTestCredits;

import React, { useContext, useEffect } from "react";
import Button from "../../../shared/button/button";
import { COMPRESSED } from "../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../shared/buttonStyles";
import AddNewUserModal from "./add-new-user-modal/addNewUserModal";
import EditUserModal from "./edit-user-modal/editUserModal";
import ReassignKamModal from "./reassign-kam-pop-up/reassignKamModal";
import Styles from "./users.module.scss";
import ViewRoles from "./view-roles/viewRoles";
import AddNewRoleModal from "./add-new-role-modal/addNewRoleModal";
import UsersList from "./user-list";
import RolesList from "./role-list";
import { PermissionContext } from "../../../context/permissionContext";
import { users as userTab } from "../../../constants/navItems";
import {
  Users as userSubTab,
  Roles as roleSubTab,
  usersNavTabs,
} from "../../../constants/usersNavTabs";

function Users() {
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const usersPermissions = assets[userTab];

  const [toggleAddNewUserModal, setToggleAddNewUserModal] =
    React.useState(false);
  const [toggleAddNewRoleModal, setToggleAddNewRoleModal] =
    React.useState(false);
  const [toggleEditModal, setToggleEditModal] = React.useState({
    toggle: false,
    item: "",
    object: {},
  });
  const [activeTab, setActiveTab] = React.useState(userSubTab);
  const [toggleReassignKamModal, setToggleReassignKamModal] =
    React.useState(false);
  const [listOfAccountsAssigned, setListOfAccountsAssigned] =
    React.useState<any>([]);
  const [toggleViewRolesModal, setToggleViewRolesModal] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  const [userList, setUserList] = React.useState<any>([]);
  const [navItems, setNavItems] = React.useState<string[]>([]);

  useEffect(() => {
    const set: Set<string> = new Set();
    for (const grants of Object.values(usersPermissions) as any) {
      for (const is_allowed of Object.values(grants.permissions)) {
        if (!is_allowed) continue;
        for (const tabName of usersNavTabs) {
          if (
            grants.grant.endsWith(tabName.replaceAll(" ", "_").toLowerCase())
          ) {
            set.add(tabName);
          }
        }
      }
    }
    setNavItems(Array.from(set));
    setActiveTab(Array.from(set)[0]);
  }, []);
  return (
    <div className="p-3">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        <div className="d-flex">
          {navItems.map((navItem: string, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`${Styles.navItemWrapper}`}
                  onClick={() => setActiveTab(navItem)}
                >
                  <p
                    className={`mb-0 px-4 ${
                      activeTab === navItem
                        ? Styles.activeNavItem
                        : Styles.navItem
                    }`}
                    style={
                      index !== navItems.length - 1
                        ? { borderRight: `1px solid #ddd` }
                        : { borderRight: 0 }
                    }
                  >
                    {navItem}
                  </p>
                  <div
                    className={
                      activeTab === navItem
                        ? Styles.activeBorder
                        : Styles.nonActiveBorder
                    }
                  ></div>
                </div>
              </>
            );
          })}
        </div>
        <div className="d-flex mb-2 justify-content-end">
          {activeTab === roleSubTab &&
          usersPermissions[roleSubTab].permissions[
            `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
          ] ? (
            <Button
              size={COMPRESSED}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => setToggleAddNewRoleModal(true)}
            >
              Add New Role
            </Button>
          ) : null}
          {activeTab === userSubTab &&
          usersPermissions[userSubTab].permissions[
            `${permissionsType.write}_${usersPermissions[userSubTab].grant}`
          ] ? (
            <Button
              size={COMPRESSED}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => setToggleAddNewUserModal(true)}
            >
              Add New User
            </Button>
          ) : null}
        </div>
      </div>
      {activeTab === userSubTab ? (
        <UsersList
          refresh={refresh}
          setToggleEditModal={setToggleEditModal}
          setToggleReassignKamModal={setToggleReassignKamModal}
          setListOfAccountsAssigned={setListOfAccountsAssigned}
          setToggleViewRolesModal={setToggleViewRolesModal}
          is_allowed_to_delete={
            usersPermissions[userSubTab].permissions[
              `${permissionsType.delete}_${usersPermissions[userSubTab].grant}`
            ]
          }
          is_allowed_to_write={
            usersPermissions[userSubTab].permissions[
              `${permissionsType.write}_${usersPermissions[userSubTab].grant}`
            ]
          }
        />
      ) : null}
      {activeTab === roleSubTab ? (
        <RolesList
          refresh={refresh}
          setToggleEditModal={setToggleEditModal}
          is_allowed_to_write={
            usersPermissions[roleSubTab].permissions[
              `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
            ]
          }
        />
      ) : null}

      {toggleAddNewRoleModal &&
        usersPermissions[roleSubTab].permissions[
          `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
        ] && (
          <AddNewRoleModal
            action={"Add"}
            onAddNewRole={() => {
              setRefresh((prev) => !prev);
              setToggleAddNewRoleModal(false);
            }}
            is_allowed_to_write={
              usersPermissions[roleSubTab].permissions[
                `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
              ]
            }
            onCancel={() => {
              setToggleAddNewRoleModal(false);
            }}
          />
        )}
      {toggleAddNewUserModal &&
        usersPermissions[userSubTab].permissions[
          `${permissionsType.write}_${usersPermissions[userSubTab].grant}`
        ] && (
          <AddNewUserModal
            onAddNewUser={(newUser: any) => {
              setToggleAddNewUserModal(false);
              setUserList([...userList, newUser]);
              setRefresh((prev) => !prev);
            }}
            onCancel={() => setToggleAddNewUserModal(false)}
            is_allowed_to_write={
              usersPermissions[roleSubTab].permissions[
                `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
              ]
            }
          />
        )}
      {toggleEditModal.toggle && toggleEditModal.item === roleSubTab ? (
        <AddNewRoleModal
          action={"Update"}
          existingRole={toggleEditModal.object}
          is_allowed_to_write={
            usersPermissions[roleSubTab].permissions[
              `${permissionsType.write}_${usersPermissions[roleSubTab].grant}`
            ]
          }
          onAddNewRole={() => {
            setRefresh((prev) => !prev);
            setToggleEditModal({ toggle: false, item: "", object: {} });
          }}
          onCancel={() => {
            setToggleEditModal({ toggle: false, item: "", object: {} });
          }}
        />
      ) : null}
      {toggleEditModal.toggle && toggleEditModal.item === userSubTab ? (
        <EditUserModal
          user={toggleEditModal.object}
          onCancel={() =>
            setToggleEditModal((toggleEditModal) => ({
              ...toggleEditModal,
              toggle: false,
            }))
          }
          is_allowed_to_write={
            usersPermissions[userSubTab].permissions[
              `${permissionsType.write}_${usersPermissions[userSubTab].grant}`
            ]
          }
          onUpdate={(
            id: string,
            status: string,
            role: string,
            name: string,
            email: string,
            phone: string
          ) => {
            setToggleEditModal((toggleEditModal) => ({
              ...toggleEditModal,
              toggle: false,
            }));
            setUserList(
              userList.map((user: any) => {
                if (user.id === id) {
                  return {
                    ...user,
                    status,
                    role,
                    name,
                    email,
                    phone,
                  };
                }
                return user;
              })
            );
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
      {toggleViewRolesModal && (
        <ViewRoles onClose={() => setToggleViewRolesModal(false)} />
      )}
      {toggleReassignKamModal && (
        <ReassignKamModal
          onClose={() => setToggleReassignKamModal(false)}
          listOfAccounts={listOfAccountsAssigned}
        />
      )}
    </div>
  );
}

export default Users;

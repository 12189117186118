import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callGetApi } from "../../../../api/axios";

export const getOrgFilterData = createAsyncThunk(
  "data/getOrgIdAndName",
  async () => {
    const { data }: any = await callGetApi(
      "organisations?status=get_org_account_page"
    );

    return data.organisations;
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    orgData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrgFilterData.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrgFilterData.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.orgData = action.payload;
      })
      .addCase(getOrgFilterData.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dataSlice.reducer;

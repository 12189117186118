import React, { useContext, useEffect } from "react";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import {
  cancelButtonModernHoverStyle,
  cancelButtonModernStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import Styles from "./addNewRoleModal.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import popUpStyles from "../../../../shared/popup.module.scss";
import Input from "../../../../shared/input/input";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import { checkIsEmpty } from "../../../../utils/validation";
import { callGetApi, callPostApi } from "../../../../api/axios";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../shared/colors";
import {
  accounts,
  batchService,
  editDocs,
  monitor,
  plans,
  products,
  users,
} from "../../../../constants/navItems";
import Checkbox from "../../../../shared/checkbox/checkbox";
import { PermissionContext } from "../../../../context/permissionContext";

function AddNewRoleModal({
  action,
  onAddNewRole,
  onCancel,
  existingRole,
  is_allowed_to_write,
}: any) {
  const { permissions } = useContext(PermissionContext);
  const [error, setError] = React.useState("");
  const navItems: string[] = [
    accounts,
    plans,
    products,
    users,
    batchService,
    editDocs,
    monitor,
  ];
  const [roleName, setRoleName] = React.useState<string>(
    existingRole?.name || ""
  );
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("");
  const [assets, setAssets] = React.useState<any>(null);
  const dispatch = useContext(ToastContext);

  function checkName() {
    if (checkIsEmpty(roleName)) {
      setError("Role name cannot be empty!");
      return false;
    }

    setRoleName((prev) => {
      return prev.toLowerCase();
    });
    setError("");
    return true;
  }

  async function addNewRole() {
    try {
      setLoading(true);
      const allCheckPasses = [checkName()].every(Boolean);
      if (!allCheckPasses) {
        return;
      }

      const permissions: string[] = [];

      for (const assetDetails of Object.values(assets) as any) {
        for (const permissionDetails of Object.values(assetDetails) as any) {
          for (const permissionsValues of Object.values(
            permissionDetails
          ) as any) {
            if (typeof permissionsValues === "string") continue;
            for (const [permission, value] of Object.entries(
              permissionsValues
            ) as any) {
              if (value) {
                permissions.push(permission);
              }
            }
          }
        }
      }

      let response: any;
      if (existingRole?.id) {
        response = await callPostApi(`/roles/${existingRole?.id}`, {
          role: roleName.trim(),
          permissions: permissions,
        });
      } else {
        response = await callPostApi("/roles", {
          role: roleName.trim(),
          permissions: permissions,
        });
      }

      if (response.err) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: response.err,
          },
        });
      }

      if (response.data) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message: `Role ${
              action === "Add" ? "Added" : "Updated"
            } Successfully`,
          },
        });
        onAddNewRole();
      }
    } catch (err: any) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function getAllPermissions() {
    setLoading(true);
    try {
      const { err, data }: any = await callGetApi("/roles/permissions/all");

      if (existingRole?.id) {
        const response: any = await callGetApi(`/roles/id/${existingRole.id}`);
        existingRole.permissions = response?.data?.permissionsList;
      }

      if (err) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Failed to fetch all permissions!",
          },
        });
        return;
      }

      const assets: any = {};
      for (const [assetsname, assetDetails] of Object.entries(
        data.assets
      ) as any) {
        assets[assetsname] = assetDetails;
        for (const [permissionName, permissionDetails] of Object.entries(
          assetDetails
        ) as any) {
          assets[assetsname][permissionName] = permissionDetails;
          for (const [permissionsName, permissionsValues] of Object.entries(
            permissionDetails
          ) as any) {
            assets[assetsname][permissionName][permissionsName] =
              permissionsValues;
            if (typeof permissionsValues === "string") continue;
            for (const [permission, value] of Object.entries(
              permissionsValues
            ) as any) {
              assets[assetsname][permissionName][permissionsName][permission] =
                existingRole && existingRole.permissions.includes(permission)
                  ? true
                  : value;
            }
          }
        }
      }
      setAssets(assets);
      setActiveTab(navItems[0]);
    } catch (err: any) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to fetch all permissions!",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllPermissions();
  }, []);

  return (
    <div
      className={`d-flex overflow-auto align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div
        className={`${popUpStyles.popUpContent}`}
        style={{
          width: "1000px",
          minHeight: "200px",
        }}
      >
        {loading ? (
          <div
            style={{ height: "200px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <>
            <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>{action} Role</p>
            </div>
            <div className="p-4 mx-2">
              <div>
                <Input
                  no_shadow="true"
                  type="text"
                  name="role_name"
                  id="role_name"
                  autoComplete="off"
                  value={roleName}
                  hasError={error}
                  placeholder="Enter role name"
                  labelname="Role name"
                  maxLength={25}
                  onChange={(event: any) => {
                    setRoleName(() => event.target.value);
                    setError("");
                  }}
                  onBlur={() => checkName()}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </div>
              <div
                className={`mb-1 d-flex align-items-center overflow-auto `}
                style={{ borderBottom: "1px solid #ddd" }}
              >
                {navItems.map((navItem: string, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className={`${Styles.navItemWrapper}`}
                        onClick={() => setActiveTab(navItem)}
                      >
                        <p
                          className={`mb-0 px-4 ${
                            activeTab === navItem
                              ? Styles.activeNavItem
                              : Styles.navItem
                          }`}
                          style={
                            index !== navItems.length - 1
                              ? { borderRight: `1px solid #ddd` }
                              : { borderRight: 0 }
                          }
                        >
                          {navItem}
                        </p>
                        <div
                          className={
                            activeTab === navItem
                              ? Styles.activeBorder
                              : Styles.nonActiveBorder
                          }
                        ></div>
                      </div>
                    </>
                  );
                })}
              </div>
              {activeTab ? (
                <table className="p-4">
                  <tbody className={Styles.tableWrapper}>
                    {assets &&
                      Object.entries(assets[activeTab])?.map(
                        ([name, permission]: any, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${Styles.showOnLargeDevicesf} ${sharedStyle.tableHeadertBack}`}
                              style={{ borderBottom: "1px solid #ddd" }}
                            >
                              <td className="py-2">{name}</td>
                              {Object.entries(permission.permissions).map(
                                ([grant, value]) => {
                                  const grantType = grant.split("_")[0];
                                  return (
                                    <td
                                      key={grant}
                                      className={` align-items-center`}
                                    >
                                      <div className="align-item-center mt-1 d-flex">
                                        <Checkbox
                                          id={`id${index}`}
                                          checked={value}
                                          onChange={(e: any) => {
                                            setAssets((prev: any) => {
                                              const is_allowed =
                                                e.target.checked;

                                              const grantName =
                                                prev[activeTab][name].grant;
                                              if (
                                                !is_allowed &&
                                                [permissions.read].includes(
                                                  grantType
                                                ) &&
                                                !(
                                                  prev[activeTab][name]
                                                    .permissions[
                                                    `${permissions.write}_${grantName}`
                                                  ] ||
                                                  prev[activeTab][name]
                                                    .permissions[
                                                    `${permissions.delete}_${grantName}`
                                                  ]
                                                )
                                              ) {
                                                prev[activeTab][
                                                  name
                                                ].permissions[grant] =
                                                  is_allowed;
                                              } else if (
                                                !is_allowed &&
                                                [
                                                  permissions.write,
                                                  permissions.delete,
                                                ].includes(grantType)
                                              ) {
                                                prev[activeTab][
                                                  name
                                                ].permissions[grant] =
                                                  is_allowed;
                                              } else if (
                                                is_allowed &&
                                                [
                                                  permissions.write,
                                                  permissions.delete,
                                                ].includes(grantType)
                                              ) {
                                                prev[activeTab][
                                                  name
                                                ].permissions[grant] =
                                                  is_allowed;
                                                prev[activeTab][
                                                  name
                                                ].permissions[
                                                  `${permissions.read}_${grantName}`
                                                ] = is_allowed;
                                              } else if (
                                                is_allowed &&
                                                [permissions.read].includes(
                                                  grantType
                                                )
                                              ) {
                                                prev[activeTab][
                                                  name
                                                ].permissions[grant] =
                                                  is_allowed;
                                              }
                                              return {
                                                ...prev,
                                              };
                                            });
                                          }}
                                        />
                                        <span className=" ml-4">
                                          {grantType}
                                        </span>
                                      </div>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              ) : null}
            </div>
            <div
              className="py-4 my-2 d-flex align-items-center justify-content-center"
              style={{ borderTop: "1px solid #ECEEEF" }}
            >
              <div className="px-2">
                <Button
                  size={MEDIUM}
                  style={cancelButtonModernStyle}
                  hoveredStyle={cancelButtonModernHoverStyle}
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
              </div>
              <div className="px-2">
                <Button
                  size={MEDIUM}
                  disabled={!is_allowed_to_write || loading || error}
                  isLoading={loading}
                  style={primaryButtonStyle}
                  hoveredStyle={primaryButtonHoverStyle}
                  onClick={() => addNewRole()}
                >
                  {action} Role
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AddNewRoleModal;

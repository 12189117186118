import { durationStyles } from "../constants/durationStyles";

export function getDurationStyle(
  duration: string,
  warningOffset: number,
  successOffset: number
) {
  if (
    duration.toLowerCase().includes("month" || "months") ||
    duration.toLowerCase().includes("year" || "years")
  )
    return durationStyles.DANGER;
  const index = duration.toLowerCase().indexOf("days");
  const str = duration.substr(0, index);
  if (Number(str) > warningOffset) return durationStyles.DANGER;
  else if (Number(str) > successOffset) return durationStyles.WARNING;
  else return durationStyles.SUCCESS;
}

import React from "react";
import FilterForm from "./filter-form/filterForm";

function ProductPriceFilter(props: any) {
  function getAllSelectedFilters(allSelectedFilters: any[]) {
    props.fetchAllSelectedFilters(allSelectedFilters);
  }

  return (
    <div>
      {" "}
      {/*parent container for filter form*/}
      <FilterForm
        handleFilterPopupClose={props.handleFilterPopupClose}
        fetchAllSelectedFilters={getAllSelectedFilters}
        currentFilters={props.currentFilters}
        fetchFilteredPlans={props.fetchFilteredPlans}
        removeTagHandler={props.removeTagHandler}
        clearAllHandler={props.clearAllHandler}
        allProductsPricings={props.allProductsPricings}
        allZoopPlans={props.allZoopPlans}
      ></FilterForm>
    </div>
  );
}

export default ProductPriceFilter;

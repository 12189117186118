import React, { useContext, useEffect } from "react";
import Styles from "./navbar.module.scss";
import logo from "../../assets/images/logo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  home,
  accounts,
  plans,
  products,
  users,
  batchService,
  batchServiceRoute,
  editDocsRoute,
  editDocs,
} from "../../constants/navItems";
import firebase from "firebase";
import { BACKGROUNDCOLOR, WHITE } from "../colors";
import ArrowDown from "../svg/arrowDown";
import { PermissionContext } from "../../context/permissionContext";

export function _handleLogout() {
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("signed out");
      localStorage.removeItem("current_user");
      window.location.assign("/login");
    })
    .catch(() => console.log("Unable to sign out"));
}

function Navbar() {
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "{}");
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState("");
  const { navItems } = useContext(PermissionContext);

  useEffect(() => {
    const url = location.pathname.split("/");
    if (url.includes(home.toLowerCase())) {
      setActiveTab(home);
    } else if (url.includes(accounts.toLowerCase())) {
      setActiveTab(accounts);
    } else if (url.includes(plans.toLowerCase())) {
      setActiveTab(plans);
    } else if (url.includes(products.toLowerCase())) {
      setActiveTab(products);
    } else if (url.includes(users.toLowerCase())) {
      setActiveTab(users);
    } else if (url.includes(batchServiceRoute.toLowerCase())) {
      setActiveTab(batchService);
    } else if (url.includes(editDocsRoute.toLowerCase())) {
      setActiveTab(editDocs);
    }
  }, [location.pathname]);

  function toggleNavTav(navItem: string) {
    if (!navItems.includes(navItem)) return;
    setActiveTab(navItem);
    if (navItem.toLowerCase() === accounts.toLowerCase()) {
      history.push(`/application/${navItem.toLowerCase()}`, {
        filter: "",
      });
    } else if (navItem.toLowerCase() === batchService.toLowerCase()) {
      history.push(`/application/${batchServiceRoute}`);
    } else if (navItem.toLowerCase() === editDocs.toLowerCase()) {
      history.push(`/application/${editDocsRoute}`);
    } else {
      history.push(`/application/${navItem.toLowerCase()}`);
    }
  }

  return (
    <div
      className={`${Styles.navbarBack} px-4 d-flex align-items-center sticky-top`}
      style={{ zIndex: 10 }}
    >
      <Link to="/application">
        <img src={logo} alt="logo" height="35px" className="img-responsive" />
      </Link>
      <div className={Styles.showOnMobileDevice}>
        <div className="pl-4 dropdown">
          <div
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              className={`d-flex align-items-center ${Styles.navItemWrapper}`}
            >
              <div className={`pr-2 ${Styles.activeNavItem}`}>{activeTab}</div>
              <ArrowDown classes={Styles.arrowDown} width="7" height="7" />
            </div>
          </div>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              borderRadius: "8px",
              width: "250px",
              backgroundColor: BACKGROUNDCOLOR,
              border: 0,
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
              zIndex: 1,
            }}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="p-3">
              {navItems.map((navItem: string, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => toggleNavTav(navItem)}
                    style={{ cursor: "pointer" }}
                  >
                    <p
                      className={`mb-0 py-2 ${
                        activeTab === navItem
                          ? Styles.activeNavItem
                          : Styles.navItem
                      }`}
                      style={activeTab === navItem ? { color: "#000" } : {}}
                    >
                      {navItem}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.showOnLargeDevices}>
        <div className="pl-5 d-flex align-items-center">
          {navItems.map((navItem: string, index: number) => {
            return (
              <div
                key={index}
                className={Styles.navItemWrapper}
                onClick={() => toggleNavTav(navItem)}
              >
                <p
                  className={`mb-0 px-4 ${
                    activeTab === navItem
                      ? Styles.activeNavItem
                      : Styles.navItem
                  }`}
                  style={
                    index !== navItems.length - 1
                      ? { borderRight: `1px solid rgba(188, 188, 188, 0.5)` }
                      : { borderRight: 0 }
                  }
                >
                  {navItem}
                </p>
                <div
                  className={
                    activeTab === navItem
                      ? Styles.activeBorder
                      : Styles.nonActiveBorder
                  }
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="ml-auto px-2">
        <div className="dropdown">
          <div
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className={Styles.initial_wrapper}>
              <p className={`mb-0 ${Styles.initial}`}>
                {currentUser.name.substr(0, 1).toUpperCase()}
              </p>
            </div>
          </div>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              borderRadius: "8px",
              width: "270px",
              backgroundColor: WHITE,
              border: 0,
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
              zIndex: 1,
            }}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="p-3">
              <div className="d-flex align-items-center">
                <div className="px-2">
                  <div
                    className={Styles.initial_wrapper}
                    style={{ cursor: "default" }}
                  >
                    <p className={`mb-0 ${Styles.initial}`}>
                      {currentUser.name.substr(0, 1).toUpperCase()}
                    </p>
                  </div>
                </div>
                <div className="px-2">
                  <p className={`mb-0 ${Styles.userName}`}>
                    {currentUser.name}
                  </p>
                  <p className={`mb-0 ${Styles.userEmail}`}>
                    {currentUser.email}
                  </p>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div
              onClick={() =>
                history.push("/change-password", { email: currentUser?.email })
              }
              className={`p-3 d-flex align-items-center justify-content-center ${Styles.changePasswordBack}`}
            >
              <p className={`mb-0 ${Styles.changePassword}`}>Change Password</p>
            </div>
            <hr className="m-0" />
            <div
              onClick={_handleLogout}
              className={`p-3 d-flex align-items-center justify-content-center ${Styles.logOutBack}`}
            >
              <p className={`mb-0 ${Styles.logOut}`}>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { PermissionContext } from "../../context/permissionContext";

function ProtectedRoutes({ ...props }: any) {
  const { navItems } = useContext(PermissionContext);
  const path = props.path;
  const component = props.component;
  const is_allowed = navItems.includes(props.name);

  return is_allowed ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to={{ pathname: "/application" }} />
  );
}

export default ProtectedRoutes;

import React from "react";
import Styles from "./overviewCard.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";

function OverviewCard({ illustrationUrl, headingText, bodyText }: any) {
  return (
    <div className="col-3 d-none d-lg-block p-3">
      <div
        className={`${sharedStyle.card} d-flex align-items-center justify-content-center`}
      >
        <div className="text-center">
          <img src={illustrationUrl} alt="illustrationUrl" height="70px" />
          <div className="pt-3">
            <p className={`mb-0 ${Styles.headingText}`}>{headingText}</p>
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Styles.bodyText}`}>{bodyText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverviewCard;

export const orderTypes = [
  {
    key: "PREPAID_PLAN_ORDER",
    value: "Prepaid plan order",
  },
  {
    key: "BALANCE_TOPUP",
    value: "Balance Top-up",
  },
  {
    key: "PREPAID",
    value: "Prepaid",
  },
  {
    key: "POSTPAID",
    value: "Postpaid",
  },
];

export const paymentTypes = [
  { key: "WALLET_ADJUSTMENT", value: "Wallet Adjustment" },
  { key: "EXTERNAL", value: "External[] Payment" },
  { key: "INTERNAL", value: "Internal Payment" },
];

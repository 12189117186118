import React, { useEffect, useState } from "react";
import Styles from "./filterForm.module.scss";
import FilterTagModal from "../../../../../shared/filter-tag/filterTagModal"; /*for conditionally rendering each filter tag with reusable component*/

import InputDropdown from "../../../../../shared/input-dropdown/inputDropdown";
import Button from "../../../../../shared/button/button";
import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  primaryButtonStyle,
} from "../../../../../shared/buttonStyles";
import { MEDIUM } from "../../../../../shared/buttonSize";
import ErrorMessage from "../../../../../shared/error-message/errorMessage";
import Close from "../../../../../shared/svg/close";
import tagStyles from "../../../../../shared/filter-tag/filterTagModal.module.scss";
import { callGetApi } from "../../../../../api/axios";

function FilterForm(props: any) {
  const [allProducts, setAllProducts] = useState<any>([]); //stores list of all zoop products received from API call to /products
  const [allProductsPricings, setAllProductsPricings] = useState<any>([]); //to store product pricing of all plans in array form
  const [allZoopPlans, setAllZoopPlans] = useState<any>([]); //stored all published and draft plans in Zoop DB

  const [chosenProduct, setChosenProduct] = useState<any>({
    name: "Select",
    arn: "Select",
    id: "Select",
  }); //stores the product user selected from 1st dropdown, its type is an object
  const [chosenProductPricings, setChosenProductPricings] = useState<any[]>([]); //stores all unique price points of a selected product in zoop plans
  const [chosenProductPricePoint, setChosenProductPricePoint] =
    useState<string>(""); //stores the price point selected from 2nd dropdown for a product selected in 1st dropdown

  const [selectedFilters, setSelectedFilters] = useState<any[]>(
    props.currentFilters
  ); // state to store  all selected filters by user
  //initial state value in above is provided by parent component in plans.tsx for sync-ing filter tags across plans and filter popup

  //follow state variables control enabling disabling sub-components in form, such as dropdown, button etc
  const [productPricingDisabled, setProductPricingDisabled] =
    useState<boolean>(true); //to conditionally enable/disable product pricing dropdown(2nd), true - disabled, false - enabled
  const [addSelectedFilterDisabled, setAddSelectedFilterDisabled] =
    useState<boolean>(true); //to conditionally enable/disable add selected filter button visibility
  const [filterPlansDisabled, setFilterPlansDisabled] = useState<boolean>(true); //to conditionally enable/disable add apply filter button visibility

  const [duplicateFilterError, setDuplicateFilterError] =
    useState<boolean>(false); //to conditionally enable/disable duplicate filter error message visibility, false - error message visible, true - hidden

  useEffect(() => {
    async function getAllProducts() {
      try {
        const products: any = await callGetApi("/products", {
          method: "GET",
        });
        let dest_products = products.data.map((product: any) => ({
          name: product.name,
          arn: product.arn,
          id: product._id,
        }));

        dest_products = dest_products.filter((product: any) =>
          product && product.arn && product.arn.includes("ckyc-") ? false : true
        );

        dest_products.sort((prod1: any, prod2: any) =>
          prod1.name > prod2.name ? 1 : -1
        );

        setAllProducts([
          {
            name: "Select",
            arn: "Select",
            id: "Select",
          },
          ...dest_products,
        ]);
      } catch (err) {
        console.log("/plans not working", err);
      }
    }
    setProductPricingDisabled(false);
    setAllZoopPlans(props.allZoopPlans);
    setAllProductsPricings(props.allProductsPricings);
    getAllProducts();
  }, []);

  function selectProductHandler(selectedProductName: string) {
    //name of product clicked in dropdown is passed here implcityly by inputDropwodn.tsx

    const selectedProduct = allProducts.find(
      (option: any) => option.name === selectedProductName
    );

    /*fetch all pricings avaialble for the selected product */
    const selected_product_pricings: string[] = [];
    const ProductNotFound = {}; //error in case product exists but is not listed in any zoop plan, e.g. - Student verification SDK w.e.f - 6 sep 22'
    try {
      allProductsPricings.forEach((plan: any) => {
        let unitPrice: string = "0.0";
        if (
          Object.keys(plan.planPricing).includes(selectedProduct.arn) === false
        ) {
          throw ProductNotFound;
        }

        unitPrice =
          Object.keys(plan.planPricing[selectedProduct.arn]).length > 0 //length is > 0 for products having add-ons over a unit price
            ? plan.planPricing[selectedProduct.arn]?.unit_price
            : typeof plan.planPricing[selectedProduct.arn] === "number" //no add-ons exist, unit price is stored directly at prod arn
            ? plan.planPricing[selectedProduct.arn]
            : 0.0; //handle those product whose arn key has empty object as value i.e. {}

        const productPricingInPlan = unitPrice;
        selected_product_pricings.push(`₹ ${productPricingInPlan.toString()}`);
      });
    } catch (err) {
      console.log("pricing for above product not found in records:");
    }
    selected_product_pricings.sort((price1: string, price2: string) => {
      price1 = price1.slice(2); //slice() to remove "₹ " prefix from pricing
      price2 = price2.slice(2);
      return Number(price1) - Number(price2);
    });

    setChosenProductPricings([
      //taking only unique values from selected_product_pricings
      ...new Set(selected_product_pricings),
    ]);

    setDuplicateFilterError(false);
    setChosenProductPricePoint("Select");
    setChosenProduct(selectedProduct);

    if (selectedProduct.name !== "Select") {
      setProductPricingDisabled(false);
      setFilterPlansDisabled(true);
    } else {
      //in-case Select-option is in 1st dropdown
      setProductPricingDisabled(true);
      setAddSelectedFilterDisabled(true);
      if (selectedFilters.length > 0) {
        setFilterPlansDisabled(false);
      } else {
        setFilterPlansDisabled(true);
      }
      return;
    }
  }

  function selectProductPricingHandler(selectedProductPricingValue: string) {
    //price of product clicked in 2nd dropdown is passed here by inputDropDown.tsx
    const selectedProductPricePoint: string = chosenProductPricings
      .find((option: string) => option === selectedProductPricingValue)
      .slice(2);

    setChosenProductPricePoint(selectedProductPricePoint);

    setAddSelectedFilterDisabled(false);
    setFilterPlansDisabled(true);
  }

  function addSelectedFilterHandler(evtObj: any) {
    //handler for 1st button click
    evtObj.preventDefault();

    const currentFilter = {
      productName: chosenProduct.name,
      productArn: chosenProduct.arn,
      productPricePoint: chosenProductPricePoint,
    };

    const checkForDuplicateFilters =
      selectedFilters.length &&
      selectedFilters.some((filter: any) => {
        return (
          filter.productName === currentFilter.productName &&
          filter.productArn === currentFilter.productArn
        );
      });

    if (checkForDuplicateFilters === true) {
      setDuplicateFilterError(true);
      setAddSelectedFilterDisabled(true);
      setFilterPlansDisabled(false);
      return;
    }

    setSelectedFilters([currentFilter, ...selectedFilters]);

    setChosenProduct({ name: "Select", arn: "Select", id: "Select" });
    setChosenProductPricePoint("0.0");

    setProductPricingDisabled(true);
    setAddSelectedFilterDisabled(true);
    setFilterPlansDisabled(false);
  }

  function filterPlansHandler(evtObj: any) {
    evtObj.preventDefault();

    const allFilteredPlans: any[] = planFilteringLogic();

    props.fetchAllSelectedFilters(selectedFilters); //to send current list of filters back to parent component -- plans.tsx
    props.fetchFilteredPlans(allFilteredPlans); // to send list of filtered plans as per user selected product price filters

    props.handleFilterPopupClose();
  }

  function planFilteringLogic() {
    const allFilteredPlans: any[] = allZoopPlans.filter((plan: any) => {
      let check = 0;
      for (const filter of selectedFilters) {
        const unitPrice =
          Object.keys(plan.pricing[filter.productArn]).length > 0
            ? plan.pricing[filter.productArn]?.unit_price
            : typeof plan.pricing[filter.productArn] === "number"
            ? plan.pricing[filter.productArn]
            : 0.0;

        check =
          unitPrice === Number(filter.productPricePoint) ? check + 1 : check;
      }
      return check === selectedFilters.length; //if number of checks passed are same as number of selected filters, then the plan is valid
    });

    return allFilteredPlans;
  }

  /* Following 3 are callbacks sent as props to FilterTag Modal, in order to generate tags for selected filter */
  function clearAllFilters() {
    const updatedListOfFilters: any[] = [];
    setSelectedFilters(updatedListOfFilters);

    props.clearAllHandler(); //to update rows in plans tab and sync clearing of tags across plans.tsx and filterForm.tsx
  }

  function removeFilterTag(filterToBeRemoved: any) {
    const updatedListOfFilters = selectedFilters.filter((filter: any) => {
      return (
        Object.entries(filter).toString() !==
        Object.entries(filterToBeRemoved).toString()
      );
    });

    if (selectedFilters.includes(filterToBeRemoved)) {
      setSelectedFilters(updatedListOfFilters);
    }

    props.removeTagHandler(filterToBeRemoved); //to update rows in plans tab and //to sync 'X' i.e. remove tag functionality across both filterForm.tsx and plans.tsx
  }

  function extractTagTitle(filter: any) {
    //return `${filter.productName} ₹ ${filter.productPricePoint}`;
    return (
      <p className={`mb-0 px-2 ${tagStyles.tagTitle}`}>
        {`${filter.productName} ₹ ${filter.productPricePoint}`}
      </p>
    );
  }

  return (
    <form action="">
      <FilterTagModal
        allFilters={selectedFilters}
        extractTagTitleHandler={extractTagTitle}
        clearAllFiltersHandler={clearAllFilters}
        removeFilterTagHandler={removeFilterTag}
      />
      {duplicateFilterError && (
        <div
          className={`${Styles.duplicateErrorContainer} px-3 py-2 d-flex align-items-center`}
        >
          <div>
            <ErrorMessage>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Duplicate Filter! This product has already been chosen once.
                Choosen a different product.
              </span>
            </ErrorMessage>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setDuplicateFilterError(false)}
          >
            <Close classes={Styles.close} width="9" height="9" />
          </div>
        </div>
      )}
      <div className={Styles.selectProduct}>
        <InputDropdown
          labelname="Product"
          defaultValue={chosenProduct?.name}
          optionsArray={allProducts.map((product: any) => product.name)}
          click={selectProductHandler}
        />
      </div>
      <div>
        <InputDropdown
          labelname="Product Pricing"
          defaultValue={
            productPricingDisabled === true
              ? `₹ ${0.0}`
              : chosenProductPricePoint
          }
          optionsArray={chosenProductPricings}
          click={selectProductPricingHandler}
          disabled={productPricingDisabled}
        />
      </div>
      <div className={Styles.addSelectedFilter}>
        <Button
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonStyle}
          size={MEDIUM}
          onClick={addSelectedFilterHandler}
          disabled={addSelectedFilterDisabled}
        >
          Add Selected Filter
        </Button>
      </div>
      <div className={Styles.applyFilter}>
        <Button
          style={dangerButtonStyle}
          hoveredStyle={dangerButtonHoverStyle}
          size={MEDIUM}
          onClick={filterPlansHandler}
          disabled={selectedFilters.length === 0 ? true : filterPlansDisabled}
        >
          Filter Plans
        </Button>
      </div>
    </form>
  );
}

export default FilterForm;

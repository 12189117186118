import React from "react";
import Routes from "./router";
import ErrorBoundary from "./shared/error-boundary/errorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  );
}

export default App;

import axios from "axios";
import firebase from "firebase";
import { _handleLogout } from "../shared/navbar/navbar";

export async function resolveToken() {
  const currentUser = firebase.auth().currentUser;

  if (!currentUser) return null;

  const token = await firebase.auth().currentUser?.getIdToken();
  return token;
}

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// All the GET apis will use this function.
export function callGetApi(url: string, ...params: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const token = await resolveToken();

      const response = await axios.get(url, {
        ...params,
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return resolve(response.data);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        _handleLogout();
      }
      return reject(error);
    }
  });
}

// All the PUT apis will use this function.
export function callPutApi(url: string, ...params: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const token = await resolveToken();
      const response = await axios.put(url, ...params, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return resolve(response.data);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        _handleLogout();
      }
      return reject(error);
    }
  });
}

// All the POST apis will use this function.
export function callPostApi(url: string, ...params: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const token = await resolveToken();
      const response = await axios.post(url, ...params, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return resolve(response.data);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        _handleLogout();
      }
      return reject(error);
    }
  });
}

// All the DELETE apis will use this function.
export function callDeleteApi(url: string, ...params: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const token = await resolveToken();
      const response = await axios.delete(url, ...params, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return resolve(response.data);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        _handleLogout();
      }
      return reject(error);
    }
  });
}

/**
 * Implementation of Change Password component
 *
 * @author - Harsh Tomar
 */

// Dependencies
import React, { useContext } from "react";
import Styles from "./../login/login.module.scss";
import logo from "./../../../assets/images/logo.png";
import Input from "../../../shared/input/input";
import Button from "../../../shared/button/button";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../shared/buttonStyles";
import { LARGE } from "../../../shared/buttonSize";
import firebase from "firebase";
import { ERROR, SUCCESS } from "../../../utils/toastType";
import { ToastContext } from "../../../context/toastContext";

export default function ChangePassword({ history }: any) {
  const [email, setEmail] = React.useState(
    history.location?.state?.email || ""
  );
  const [loading, setLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);

  function _handleSubmit(e: any) {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: `${window.location.origin}/login`,
      })
      .then(() => {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message:
              "We have sent you an email. Please check your inbox and follow instructions to change your password.",
          },
        });
      })
      .catch(function (error) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: error.message,
          },
        });
      });
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.innerContainer}>
        <div className={Styles.loginContainer}>
          <div className={Styles.left}>
            <img className={Styles.logo} src={logo} alt="Zoop.one Logo" />
          </div>
          <div className={Styles.right}>
            <h4 className={Styles.heading}>Change Password</h4>
            <form onSubmit={_handleSubmit}>
              <Input
                type="email"
                placeholder="name@zoop.one"
                labelname="Enter your E-mail"
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
              />
              <br />
              <Button
                style={primaryButtonStyle}
                size={LARGE}
                hoveredStyle={primaryButtonHoverStyle}
                isLoading={loading}
              >
                Send E-mail
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

const RunningSvg = ({ fillBackgroundColor, fillInnerColor, border }: any) => (
  <svg
    width="70"
    height="42"
    viewBox="0 0 64 41"
    fill={fillBackgroundColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="1" width="63" height="39" rx="7.5" stroke="#0F9D58" />
    <g clipPath="url(#clip0_1717_383)">
      <g clipPath="url(#clip1_1717_383)">
        <path
          d="M36.2165 12.7667C35.9323 12.7666 35.6596 12.8786 35.4575 13.0783C35.2553 13.2779 35.14 13.5492 35.1365 13.8333V27.1667C35.1365 27.4531 35.2502 27.7278 35.4528 27.9303C35.6553 28.1329 35.93 28.2467 36.2165 28.2467C36.5029 28.2467 36.7776 28.1329 36.9801 27.9303C37.1827 27.7278 37.2965 27.4531 37.2965 27.1667V13.8333C37.293 13.5492 37.1776 13.2779 36.9755 13.0783C36.7733 12.8786 36.5006 12.7666 36.2165 12.7667ZM27.7831 12.7667C27.499 12.7666 27.2263 12.8786 27.0241 13.0783C26.822 13.2779 26.7066 13.5492 26.7031 13.8333V27.1667C26.7031 27.4531 26.8169 27.7278 27.0194 27.9303C27.222 28.1329 27.4967 28.2467 27.7831 28.2467C28.0696 28.2467 28.3443 28.1329 28.5468 27.9303C28.7493 27.7278 28.8631 27.4531 28.8631 27.1667V13.8333C28.8596 13.5492 28.7443 13.2779 28.5421 13.0783C28.34 12.8786 28.0673 12.7666 27.7831 12.7667Z"
          fill={fillInnerColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1717_383">
        <rect
          width="16"
          height="16"
          fill={fillBackgroundColor}
          transform="translate(24 12.5)"
        />
      </clipPath>
      <clipPath id="clip1_1717_383">
        <rect
          width="16"
          height="16"
          fill={fillBackgroundColor}
          transform="translate(24 12.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RunningSvg;

import React, { useContext, useEffect, useState } from "react";
import OverviewCard from "../overview-card/overviewCard";
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import recentlyAssignedIllustration from "../../../../assets/images/recently-assigned-accounts.png";
import { Link } from "react-router-dom";
import { callGetApi } from "../../../../api/axios";
import { ERROR } from "../../../../utils/toastType";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../shared/colors";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import { durationStyles } from "../../../../constants/durationStyles";
import { ToastContext } from "../../../../context/toastContext";

function RecentlyAssignedAccounts() {
  const [loading, setLoading] = useState(false);
  const [recentlyAssignedAccounts, setRecentlyAssignedAccounts] = useState([]);
  const dispatch = useContext(ToastContext);
  useEffect(() => {
    async function getRecentlyAssignedAccounts() {
      setLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=recently_assigned_kam"
        );
        const recentlyAssignedAccountList = response.data.map((data: any) => ({
          name: data.name,
          category: data.category,
          onboarding_status: data.onboarding_status,
          country_code: data.country_code,
          trial_services_count: data.trial_services_count,
          created_at: data.createdAt,
          id: data._id,
        }));
        setRecentlyAssignedAccounts(recentlyAssignedAccountList);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: error.response.data.err,
          },
        });
      }
    }

    getRecentlyAssignedAccounts();
  }, [dispatch]);

  return (
    <div className="row">
      <OverviewCard
        illustrationUrl={recentlyAssignedIllustration}
        headingText="Recently Assigned"
        bodyText="Reach out to these customers to understand their requirements."
      />
      <div className="col-lg-9 col-md-12 p-3">
        <div className="py-3">
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>
              Recently Assigned
            </p>
          </div>
        </div>
        {loading ? (
          <div
            style={{ height: "300px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : recentlyAssignedAccounts.length > 0 ? (
          <div className="d-flex align-items-center flex-wrap">
            {recentlyAssignedAccounts.map((account: any, index: any) => {
              const date = new Date(Number(account.created_at));
              const duration = getDuration(date);
              const style = getDurationStyle(duration, 7, 3);
              if (index < 6) {
                return (
                  <div className="pt-3 pr-4" key={index}>
                    <div
                      className={`${sharedStyle.card} px-4 pt-4 pb-3`}
                      style={{ width: "320px" }}
                    >
                      <div className="py-1">
                        <Link
                          to={`/application/accounts/${account.id}`}
                          className={`mb-0 ${Styles.accountNameInCard}`}
                        >
                          {account.name}
                        </Link>
                        <div className="d-flex align-items-center pt-1">
                          <p className={`mb-0 ${Styles.createdAtInCard}`}>
                            Joined on
                            <span className={`pl-1 ${Styles.createAtDate}`}>
                              {/* {monthName(date.getMonth())}-{date.getDate()}-
                              {date.getFullYear()} */}
                              {`${monthName(
                                date.getMonth()
                              )} ${date.getDate()}, ${date.getFullYear()}`}
                            </span>
                          </p>
                          <div className="ml-auto">
                            <p
                              className={`mb-0 ${Styles.duration}`}
                              style={
                                style === durationStyles.DANGER
                                  ? { color: "#d64747" }
                                  : style === durationStyles.WARNING
                                  ? { color: "#f4a63b" }
                                  : { color: "#31b975" }
                              }
                            >
                              {duration}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="py-2 row">
                        <div className="col-12 pt-1">
                          <div className="d-flex align-items-start">
                            <p className={`mb-0 ${Styles.key}`}>In Trial:</p>
                            <p className={`mb-0 pl-2 ${Styles.value}`}>
                              {account.trial_services_count ?? "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 pt-1">
                          <div className="d-flex align-items-start">
                            <p className={`mb-0 ${Styles.key}`}>Category:</p>
                            <p className={`mb-0 pl-2 ${Styles.value}`}>
                              {account.category ?? "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 pt-1">
                          <div className="d-flex align-items-start">
                            <p className={`mb-0 ${Styles.key}`}>
                              Country Code:
                            </p>
                            <p className={`mb-0 pl-2 ${Styles.value}`}>
                              {account.country_code ?? "NA"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <p className={`mb-0 ${Styles.accountStatus}`}>
                          {account.onboarding_status}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <div
            style={{ height: "300px", borderRadius: "10px" }}
            className="d-flex align-items-center justify-content-center bg-white"
          >
            <p className="mb-0">No Account Found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentlyAssignedAccounts;

import React, { useContext, useEffect, useState } from "react";

import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../shared/buttonStyles";
import {
  deprecatedProductsForDevDocs,
  hiddenCategoryForDevDocs,
} from "../../../constants/deprecatedProducts";
import Styles from "./editDocs.module.scss";
import { ERROR } from "../../../utils/toastType";
import Button from "../../../shared/button/button";
import EditDocPopupp from "./popups/editDocPopupp";
import { ACCENTCOLOR, SUCCESS } from "../../../shared/colors";
import Loading from "../../../shared/loading/loading";
import { COMPRESSED } from "../../../shared/buttonSize";
import { ToastContext } from "../../../context/toastContext";
import { callDeleteApi, callGetApi } from "../../../api/axios";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import ConfirmationModal from "../../../shared/modal/confirmationModal";
import { PermissionContext } from "../../../context/permissionContext";
import { editDocs } from "../../../constants/navItems";

export const enum PAGE {
  CATEGORY = "CATEGORY",
  TYPE = "TYPE",
  PRODUCT = "PRODUCT",
}

function EditDocs() {
  const dispatch = useContext(ToastContext);
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);

  const grant = assets[editDocs][editDocs].grant;
  const permissions = assets[editDocs][editDocs].permissions;

  const [title, setTitle] = useState<any>({});
  const [arns, setArns] = useState<string[]>();
  const [list, setList] = useState<string[]>([]);
  const [nameToArn, setNameToArn] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<PAGE>();
  const [productData, setProductData] = useState<any>({});
  const [selectedData, setSelectedData] = useState<any>({});
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [confimationModal, setConfimationModal] = useState<boolean>(false);

  useEffect(() => {
    async function getProductsInfo() {
      try {
        const { data: products }: any = await callGetApi(`/products`);

        const arns = new Set();
        const productObj: any = {};
        for (const product of products) {
          if (
            deprecatedProductsForDevDocs.includes(product.arn) ||
            hiddenCategoryForDevDocs.includes(product.category)
          )
            continue;

          if (!(product.category in productObj)) {
            productObj[product.category] = {};
          }

          const productName = product.arn.split("_")[2].toUpperCase();
          if (!(productName in productObj[product.category])) {
            productObj[product.category][productName] = [];
          }

          arns.add(product.arn);
        }

        setProductData(productObj);
        setCurrentPage(PAGE.CATEGORY);
        setArns([...arns] as string[]);
      } catch (error) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Failed to fetch products!",
          },
        });
      }
    }

    getProductsInfo();
  }, []);

  useEffect(() => {
    async function getDevDocsInfo() {
      if (!arns || !productData) return;

      try {
        const { data }: any = await callGetApi("/doc");

        const nameToArn: any = {};
        setProductData((prev: any) => {
          for (const doc of data) {
            if (!prev[doc.category][doc.type]?.includes(doc.name)) {
              prev[doc.category][doc.type]?.push(doc.name);
              nameToArn[`${doc.category}_${doc.type}_${doc.name}`] = doc.arn;
            }
          }
          return prev;
        });

        setNameToArn(nameToArn);
      } catch (error) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Failed to fetch documents!",
          },
        });
      }
    }

    getDevDocsInfo();
  }, [arns]);

  useEffect(() => {
    if (currentPage === PAGE.CATEGORY) {
      setList(Object.keys(productData));
      setTitle((prev: any) => {
        return { ...prev, main: "Existing Categories", sub: "Category Name" };
      });
    } else if (currentPage === PAGE.TYPE) {
      setList(Object.keys(productData[selectedData.category]));
      setTitle((prev: any) => {
        return { ...prev, main: "Existing Product Types", sub: "Product Type" };
      });
    } else if (currentPage === PAGE.PRODUCT) {
      setList(productData[selectedData.category][selectedData.type]);
      setTitle((prev: any) => {
        return {
          ...prev,
          main: "Existing Products",
          sub: "Product Name",
        };
      });
    }
  }, [currentPage]);

  async function deleteProductDoc(arn: string) {
    try {
      if (!selectedData?.arn) return;
      setLoading(true);
      const { data }: any = await callDeleteApi(`/doc/${arn}`);
      if (!data) {
        throw new Error();
      }

      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: "Document deleted successfully!",
        },
      });

      setList((prev: string[]) => {
        const index = prev.indexOf(selectedData.product);
        if (index > -1) {
          prev.splice(index, 1);
        }
        return prev;
      });

      setSelectedData((prev: any) => {
        delete prev.product;
        delete prev.arn;
        return prev;
      });

      setShowEditPopup(false);
    } catch (error) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to delete document!",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {showEditPopup && (
        <EditDocPopupp
          setList={setList}
          setNameToArn={setNameToArn}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setShowEditPopup={setShowEditPopup}
          is_allows_to_write={permissions[`${permissionsType.write}_${grant}`]}
        />
      )}

      {confimationModal && (
        <ConfirmationModal
          title={"Delete Confirmation"}
          subTitle={
            <span>
              Are you sure you want to delete this product?
              <br /> NAME: <b>{selectedData.product}</b>
              <br /> ARN: <b>{selectedData.arn}</b>
            </span>
          }
          btnText={"Confirm"}
          isLoading={loading}
          onCancel={() => {
            setConfimationModal(false);
            setSelectedData((prev: any) => {
              delete prev.product;
              delete prev.arn;
              return prev;
            });
          }}
          forBatch={true}
          onSuccess={async () => {
            await deleteProductDoc(selectedData.arn);
            setConfimationModal(false);
          }}
        />
      )}
      <div className="col-md-12 p-3">
        {(currentPage === PAGE.TYPE || currentPage === PAGE.PRODUCT) && (
          <div
            className={`mb-2 ${Styles.pointer}`}
            onClick={() => {
              if (currentPage === PAGE.TYPE) {
                setCurrentPage(PAGE.CATEGORY);
                setSelectedData(() => {
                  return {};
                });
              } else if (currentPage === PAGE.PRODUCT) {
                setCurrentPage(PAGE.TYPE);
                setSelectedData((prev: any) => {
                  delete prev.type;
                  return prev;
                });
              }
            }}
          >{`< back`}</div>
        )}
        <div className={`${sharedStyle.card}`}>
          <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center pb-3">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>{title.main}</p>
              {currentPage === PAGE.PRODUCT &&
              permissions[`${permissionsType.write}_${grant}`] ? (
                <div className="m-0 p-0 ml-auto">
                  <Button
                    size={COMPRESSED}
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    onClick={() => setShowEditPopup(true)}
                  >
                    Add new
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          {list.length > 0 ? (
            <div style={{ overflowX: "auto", minHeight: "450px" }}>
              <table style={{ width: "100%" }}>
                <thead className={sharedStyle.tableHeaderBack}>
                  <tr>
                    <th className={Styles.accID}>
                      <div className="d-flex align-items-center">
                        <p className={`mb-0 ${Styles.tableHeaderText}`}>
                          {title.sub}
                        </p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item: string, index: number) => (
                    <tr
                      key={index}
                      style={{
                        borderBottom: "1px solid #eceeef",
                      }}
                      className={`${Styles.rowLink}`}
                    >
                      <td
                        className={`${Styles.accID} w-auto ${Styles.rowLink}`}
                        id={`${index}`}
                        onClick={() => {
                          if (currentPage === PAGE.CATEGORY) {
                            setCurrentPage(PAGE.TYPE);
                            setSelectedData((prev: any) => {
                              return { ...prev, category: item };
                            });
                          }
                          if (currentPage === PAGE.TYPE) {
                            setCurrentPage(PAGE.PRODUCT);
                            setSelectedData((prev: any) => {
                              return { ...prev, type: item };
                            });
                          }
                          if (currentPage === PAGE.PRODUCT) {
                            setShowEditPopup(true);
                            setSelectedData((prev: any) => {
                              return {
                                ...prev,
                                product: item,
                                arn: nameToArn[
                                  `${selectedData.category}_${selectedData.type}_${item}`
                                ],
                              };
                            });
                          }
                        }}
                      >
                        <span className={`mb-0 ${Styles.tableBodyText}`}>
                          {item}
                          {currentPage === PAGE.CATEGORY
                            ? " Verification"
                            : null}
                        </span>
                        {currentPage === PAGE.CATEGORY ? (
                          <span
                            className={`${Styles.pointer} float-right mx-3`}
                          >
                            {Object.keys(productData[item] || {}).length}
                          </span>
                        ) : null}
                        {currentPage === PAGE.TYPE ? (
                          <span
                            className={`${Styles.pointer} float-right mx-3`}
                          >
                            {
                              (
                                productData[selectedData.category][
                                  item
                                ] as string[]
                              )?.length
                            }
                          </span>
                        ) : null}
                      </td>
                      {currentPage === PAGE.PRODUCT &&
                      permissions[`${permissionsType.delete}_${grant}`] ? (
                        <td style={{ width: "150px" }}>
                          <div className="mx-3">
                            <Button
                              size={COMPRESSED}
                              style={dangerButtonStyle}
                              hoveredStyle={dangerButtonHoverStyle}
                              onClick={() => {
                                setSelectedData((prev: any) => {
                                  return {
                                    ...prev,
                                    product: item,
                                    arn: nameToArn[
                                      `${selectedData.category}_${selectedData.type}_${item}`
                                    ],
                                  };
                                });
                                setConfimationModal(true);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ height: "350px" }}>
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditDocs;

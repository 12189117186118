import React from "react";
import Styles from "./inputDropdown.module.scss";
import { SECONDARYCOLOR, WHITE } from "../colors";
import SearchImage from "../../assets/images/search.png";
import ArrowDown from "../svg/arrowDown";

function InputDropdown(props: any) {
  const {
    labelname,
    hasError = "",
    optionsArray,
    click,
    isValid = null,
    defaultValue = "",
    no_shadow = "true",
    width = "100%",
    height = "200px",
    disabled = false,
    miniLabel = false,
    whiteBackgroundcolor = false,
    showDropDownName = "",
    PlaceHolderSearch = "",
    searchable = false,
  } = props;
  const [lookup, setLookup] = React.useState("");
  const filteredOptions = optionsArray.filter((option: any) => {
    return option.toLowerCase().includes(lookup.toLowerCase());
  });
  const disabledState = (
    <div
      className={`${
        hasError !== ""
          ? Styles.dropdownError
          : isValid
          ? Styles.validDropdown
          : Styles.dropdown
      } ${no_shadow === "true" ? "shadow-none" : ""} d-flex align-items-center`}
      style={{ cursor: "no-drop", opacity: 0.5 }}
    >
      <div className="flex-grow-1">
        <p className={`mb-0 ${Styles.selectedText}`}>
          {defaultValue ? (
            defaultValue
          ) : (
            <span
              style={{
                color: SECONDARYCOLOR,
              }}
            >
              Select Option
            </span>
          )}
        </p>
      </div>
      <div className="ml-auto">
        <div>
          <ArrowDown color={SECONDARYCOLOR} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <p
        className={`${
          miniLabel ? Styles.formLabelMini : Styles.formLabel
        } m-0 pb-2 `}
      >
        {labelname}
      </p>
      {disabled ? (
        disabledState
      ) : (
        <div className={`dropdown `}>
          <div
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              className={`${
                hasError !== ""
                  ? Styles.dropdownError
                  : isValid
                  ? Styles.validDropdown
                  : Styles.dropdown
              } ${
                no_shadow === "true" ? "shadow-none" : ""
              } d-flex align-items-center
                ${whiteBackgroundcolor && Styles.whiteBG}`}
            >
              <div className="flex-grow-1">
                <p
                  className={`mb-0 ${
                    miniLabel ? Styles.selectedTextMini : Styles.selectedText
                  }`}
                >
                  {defaultValue ? (
                    defaultValue
                  ) : (
                    <span style={{ color: SECONDARYCOLOR }}>
                      {showDropDownName ? showDropDownName : "Select Option"}
                    </span>
                  )}
                </p>
              </div>
              <div className="ml-auto">
                <div>
                  <ArrowDown color={SECONDARYCOLOR} />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: width,
              borderRadius: "8px",
              padding: "10px",
              backgroundColor: WHITE,
              border: 0,
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
              zIndex: 1,
            }}
            className={`dropdown-menu dropdown-menu-right`}
            aria-labelledby="dropdownMenuButton"
          >
            {(optionsArray.length > 10 || searchable === true) && (
              <div
                className={`${Styles.inputWrapper} d-flex align-items-center pl-2`}
              >
                <div className="px-2">
                  <img
                    src={SearchImage}
                    alt="search"
                    width="15px"
                    style={{ marginBottom: "3px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <input
                    type="text"
                    className={Styles.formControl}
                    placeholder={
                      PlaceHolderSearch
                        ? PlaceHolderSearch
                        : "Search Category here"
                    }
                    value={lookup}
                    onChange={(event: any) => {
                      setLookup(event.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                maxHeight: height,
                height: "100%",
                overflow: "auto",
              }}
            >
              {filteredOptions.length !== 0 ? (
                filteredOptions.map((option: any, index: number) => {
                  return (
                    <div key={`${option}-${index}`}>
                      <div
                        className={Styles.dropdownLink}
                        onClick={() => {
                          setLookup("");
                          click(option, index);
                        }}
                      >
                        {option}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{ height: height }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h5 className="mb-0">No option found</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InputDropdown;

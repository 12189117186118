import React, { useEffect } from "react";
import Styles from "./transactionStats.module.scss";
import Loading from "./../../../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { callGetApi } from "../../../../../../api/axios";
import Dropdown from "../../../../../../shared/dropdown/dropdown";
import Switch from "../../../../../../shared/toggle-switch/switch";
import { envTypes } from "../../../../../../constants/envTypes";
import { validOnboardingStatuses } from "../../../../../../constants/validOnboardingStatuses";
import RenderTooltip from "../../../../../../shared/tooltip/tooltip";
import Information from "../../../../../../shared/svg/information";
import moment from "moment";
import { useParams } from "react-router-dom";
import DatePickerDropdown from "../../../../../../shared/date-picker-dropdown/datePickerDropdown";
import InputDropdownStyles from "../../../../../../shared/input-dropdown/inputDropdown.module.scss";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import Button from "../../../../../../shared/button/button";
import { MEDIUM } from "../../../../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";

function TransactionStats({ onboarding_status }: any) {
  const params: any = useParams();
  const org_id = params.id;

  const [loading, setLoading] = React.useState(false);
  const [overAllLoading, setOverallLoading] = React.useState(false);
  const [transactionStatLoading, setTransactionStatLoading] =
    React.useState(false);
  const [totalTransaction, setTotalTransactions] = React.useState(0);
  const [totalBillableTransactions, setTotalBillableTransactions] =
    React.useState(0);
  const [totalSuccessfulTransactions, setTotalSuccessfulTransactions] =
    React.useState(0);
  const [totalFailureTransaction, setTotalFailureTransaction] =
    React.useState(0);

  const durationArray = ["Today", "Last 7 days", "Last 30 days", "Custom"];
  const [duration, setDuration] = React.useState(durationArray[0]);
  const [listOfApps, setListOfApps] = React.useState([]);
  const [env, setEnv] = React.useState("");
  const [selectedApp, setSelectedApp] = React.useState("");
  const [selectedAppId, setSelectedAppId] = React.useState("");
  const [isTestApp, setIsTestApp] = React.useState(true);

  const [selectedDuration, setSelectedDuration] = React.useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [transactionStats, setTransactionStats] = React.useState([]);
  const onboardingStatus = onboarding_status;
  // getting org info api
  useEffect(() => {
    function getAccountInfo() {
      setLoading(true);
      try {
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    getAccountInfo();
  }, [org_id]);

  // get all apps api
  useEffect(() => {
    async function getAllAppNames() {
      if (validOnboardingStatuses.includes(onboardingStatus)) {
        setOverallLoading(true);
        try {
          const { data }: any = await callGetApi(
            `/organisations/${org_id}?data=account_apps`
          );
          setListOfApps(
            data.map((app: any) => ({
              name: app.appName,
              id: app.appId,
              is_test_app: app.is_test_app,
            })) ?? ["No Apps Found"]
          );
          if (
            validOnboardingStatuses.includes(onboardingStatus) &&
            data.length > 1
          ) {
            setSelectedApp(data[1].appName);
            setSelectedAppId(data[1].appId);
            setEnv(envTypes.PRODUCTION);
          } else {
            setSelectedApp(data[0].appName);
            setSelectedAppId(data[0].appId);
            setEnv(envTypes.TEST);
          }
          setOverallLoading(false);
        } catch (err) {
          setOverallLoading(false);
        }
      }
      return;
    }
    getAllAppNames();
  }, [org_id, onboardingStatus]);
  // get transaction stats api
  useEffect(() => {
    async function getTransactionStats() {
      if (
        validOnboardingStatuses.includes(onboardingStatus) &&
        env !== "" &&
        selectedAppId !== "" &&
        (selectedDuration.from !== "" || selectedDuration.to !== "")
      ) {
        setTransactionStatLoading(true);
        setTotalTransactions(0);
        setTotalBillableTransactions(0);
        setTotalSuccessfulTransactions(0);
        setTotalFailureTransaction(0);
        try {
          const response: any = await callGetApi(
            `/organisations/${org_id}?data=transaction_stats&env=${env}&app_id=${selectedAppId}&start_date=${selectedDuration.from}&end_date=${selectedDuration.to}`,
            {
              method: "GET",
            }
          );
          const transactions = response.data;
          setTransactionStatLoading(false);
          setTransactionStats(transactions);
          transactions.forEach((transaction: any) => {
            setTotalTransactions(
              (totalTransaction: any) =>
                totalTransaction +
                transaction.success_txn_count +
                transaction.failure_txn_count
            );
            setTotalBillableTransactions(
              (totalBillableTransactions: any) =>
                totalBillableTransactions + transaction.billable_txn_count
            );
            setTotalSuccessfulTransactions(
              (totalSuccessfulTransactions: any) =>
                totalSuccessfulTransactions + transaction.success_txn_count
            );
            setTotalFailureTransaction(
              (totalFailureTransaction: any) =>
                totalFailureTransaction + transaction.failure_txn_count
            );
          });
          return;
        } catch (err) {
          setTransactionStatLoading(false);
        }
      }
      return;
    }
    getTransactionStats();
  }, [env, selectedAppId, selectedDuration, org_id, onboardingStatus]);

  const [customDate, setCustomDate] = React.useState({
    from: new Date(),
    to: new Date(),
  });
  const [isCustomDateSelected, setIsCustomDateSelected] = React.useState(false);
  const [dateError, setDateError] = React.useState({
    fromError: "",
    toError: "",
  });

  function onChangeFrom(incomingDate: Date) {
    setCustomDate((custom_date) => ({
      ...custom_date,
      from: incomingDate,
    }));
    setDateError((error) => ({
      ...error,
      fromError: "",
      toError: "",
    }));
  }

  function onChangeTo(incomingDate: Date) {
    setCustomDate((custom_date) => ({
      ...custom_date,
      to: incomingDate,
    }));
    setDateError((error) => ({
      ...error,
      fromError: "",
      toError: "",
    }));
  }

  function runCustomDateHandler() {
    if (customDate.from > customDate.to) {
      setDateError((error) => ({
        ...error,
        toError: "From Date should be less than To Date!",
      }));
      return;
    }
    setTimeout(() => scroll(), 120);
    setSelectedDuration((selectedDuration: any) => ({
      ...selectedDuration,
      from: moment(customDate.from).format("YYYY-MM-DD"),
      to: moment(customDate.to).format("YYYY-MM-DD"),
    }));
  }

  const dataRef = React.useRef<any>();

  const scroll = () => {
    dataRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container-fluid p-0">
      {loading || overAllLoading ? (
        <div
          style={{ height: "80vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div /* className="p-3" */>
          {validOnboardingStatuses.includes(onboardingStatus) ? (
            <div style={{ backgroundColor: "#f5f5f5" }}>
              {/* <MarketingText /> */}
              <div
                className={`d-flex align-items-center justify-content-end ${Styles.mockHeader}`}
              >
                <div className="p-3 mr-auto d-flex align-items-center justify-content-start ">
                  <p
                    style={{ fontSize: "18px" }}
                    className={`mb-0 ${Styles.overallheadingText}`}
                  >
                    Transaction Summary
                  </p>
                </div>
                <div className="px-3">
                  <Switch
                    positiveLabel="Prod"
                    negativeLabel="Trial"
                    id="switch-show"
                    isDisabled={true}
                    checked={env === envTypes.PRODUCTION}
                    onChange={() => {
                      if (isTestApp) return setEnv(envTypes.TEST);
                      return setEnv(envTypes.PRODUCTION);
                    }}
                  />
                </div>
                <div className="px-2">
                  <Dropdown
                    default_value={duration}
                    optionsArray={durationArray}
                    click={(value: any) => {
                      const start_date = new Date();
                      const end_date = new Date();
                      if (value === "Today") {
                        setIsCustomDateSelected(false);
                        setSelectedDuration((selectedDuration: any) => ({
                          ...selectedDuration,
                          from: moment(start_date).format("YYYY-MM-DD"),
                          to: moment(end_date).format("YYYY-MM-DD"),
                        }));
                      }
                      if (value === "Last 7 days") {
                        setIsCustomDateSelected(false);
                        const last_seventh_date = new Date(
                          moment().subtract(7, "days").calendar()
                        );
                        setSelectedDuration((selectedDuration: any) => ({
                          ...selectedDuration,
                          from: moment(last_seventh_date).format("YYYY-MM-DD"),
                          to: moment(end_date).format("YYYY-MM-DD"),
                        }));
                      }
                      if (value === "Last 30 days") {
                        setIsCustomDateSelected(false);
                        const last_thirtieth_day = new Date(
                          moment().subtract(30, "days").calendar()
                        );
                        setSelectedDuration((selectedDuration: any) => ({
                          ...selectedDuration,
                          from: moment(last_thirtieth_day).format("YYYY-MM-DD"),
                          to: moment(end_date).format("YYYY-MM-DD"),
                        }));
                      }
                      if (value === "Custom") {
                        setTransactionStats([]);
                        setTotalTransactions(0);
                        setTotalBillableTransactions(0);
                        setTotalSuccessfulTransactions(0);
                        setTotalFailureTransaction(0);
                        setIsCustomDateSelected(true);
                      }
                      setDuration(value);
                    }}
                  />
                </div>
                <div className="px-2">
                  <Dropdown
                    default_value={selectedApp}
                    optionsArray={listOfApps.map((app: any) => app.name)}
                    click={(value: any) => {
                      const data: any = listOfApps.find(
                        (app: any) => app.name === value
                      );
                      data.is_test_app
                        ? setEnv(envTypes.TEST)
                        : setEnv(envTypes.PRODUCTION);
                      setIsTestApp(data.is_test_app);
                      setSelectedApp(data.name);
                      setSelectedAppId(data.id);
                    }}
                  />
                </div>
              </div>
              {isCustomDateSelected ? (
                <div>
                  <div className={`${Styles.datesContainer} d-flex`}>
                    <div className={`${Styles.fromDateContainer} col-md-4`}>
                      <div className="p-3">
                        <p
                          className={`${InputDropdownStyles.formLabel} m-0 pb-2 `}
                        >
                          From
                        </p>

                        <DatePickerDropdown
                          value={new Date()}
                          placeholder="dd-mm-yyyy"
                          onChange={onChangeFrom}
                        />
                      </div>
                    </div>
                    <div className={`${Styles.toDateContainer} col-md-4`}>
                      <div className="p-3">
                        <p
                          className={`${InputDropdownStyles.formLabel} m-0 pb-2 `}
                        >
                          To
                        </p>
                        <DatePickerDropdown
                          value={`${new Date()}`}
                          placeholder="dd-mm-yyyy"
                          onChange={onChangeTo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${Styles.runReportContainer} col-md-4`}>
                    <div className={`p-3`}>
                      <Button
                        size={MEDIUM}
                        onClick={() => {
                          runCustomDateHandler();
                        }}
                        hoveredStyle={primaryButtonHoverStyle}
                        style={primaryButtonStyle}
                        disabled={false /*enableRunReport()*/}
                        type="submit"
                      >
                        Run Custom Date
                      </Button>
                    </div>
                  </div>
                  {dateError.toError && (
                    <div className="p-1 ml-2">
                      {/* <ErrorMessage>{dateError.fromError}</ErrorMessage> */}
                      <ErrorMessage>
                        <p
                          style={{
                            fontStyle: "Helvetica",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {dateError.toError}
                        </p>
                      </ErrorMessage>
                    </div>
                  )}
                </div>
              ) : null}
              <div ref={dataRef}></div>
              <div className="p-3 container-fluid">
                <div
                  className={`row no-gutters ${Styles.card} ${Styles.cardAnimation} p-4`}
                >
                  <div className="col-md-3 col-sm-6">
                    <div className="p-2">
                      <p className={`mb-0 ${Styles.labelText}`}>
                        Total Transactions
                      </p>
                      <p className={`mb-0 ${Styles.value}`}>
                        {totalTransaction}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="p-2">
                      <p className={`mb-0 ${Styles.labelText}`}>
                        Billable Transactions
                      </p>
                      <p className={`mb-0 ${Styles.value}`}>
                        {totalBillableTransactions}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="p-2">
                      <p className={`mb-0 ${Styles.labelText}`}>
                        Successful Transactions
                      </p>
                      <p className={`mb-0 ${Styles.value}`}>
                        {totalSuccessfulTransactions}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="p-2">
                      <p className={`mb-0 ${Styles.labelText}`}>
                        Failed Transactions
                      </p>
                      <p className={`mb-0 ${Styles.value}`}>
                        {totalFailureTransaction}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className={`${Styles.card} ${Styles.cardAnimation}`}>
                  <div className={`p-3 ${Styles.cardHeader}`}>
                    <p className={`mb-0 px-1 ${Styles.headingText}`}>
                      Transaction Stats
                    </p>
                  </div>
                  <div className={Styles.tableOverlay}>
                    {transactionStatLoading ? (
                      <div
                        style={{ height: "200px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <Loading loadingColor={ACCENTCOLOR} />
                      </div>
                    ) : transactionStats.length === 0 ? (
                      <div
                        style={{ height: "200px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <h5 className="mb-0">No Record Found Yet</h5>
                      </div>
                    ) : (
                      <div style={{ overflowX: "auto" }}>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr className={Styles.tableHeaderWrapper}>
                              <th className={Styles.productName}>
                                <p className={`mb-0 ${Styles.tableHeader}`}>
                                  Product Name
                                </p>
                              </th>
                              {env === envTypes.TEST && (
                                <th className={Styles.billable_transaction}>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className={`mb-0 ${Styles.tableHeader} flex-grow-1`}
                                      style={{ textAlign: "center" }}
                                    >
                                      Balance Credits
                                    </p>
                                    <div className="px-2">
                                      <RenderTooltip
                                        placement="bottom"
                                        title="Latest credit balance will be displayed irrespective of duration selected."
                                      >
                                        <div
                                          className={`d-flex align-items-center justify-content-left ${Styles.InfoIconBack}`}
                                        >
                                          <Information
                                            infoClass={Styles.infoIcon}
                                          />
                                        </div>
                                      </RenderTooltip>
                                    </div>
                                  </div>
                                </th>
                              )}
                              <th className={Styles.billable_transaction}>
                                <p
                                  className={`mb-0 ${Styles.tableHeader}`}
                                  style={{ textAlign: "center" }}
                                >
                                  Billable Transactions
                                </p>
                              </th>
                              <th className={Styles.success}>
                                <p
                                  className={`mb-0 ${Styles.tableHeader}`}
                                  style={{ textAlign: "center" }}
                                >
                                  Success
                                </p>
                              </th>
                              <th className={Styles.failure}>
                                <p
                                  className={`mb-0 ${Styles.tableHeader}`}
                                  style={{ textAlign: "center" }}
                                >
                                  Failure
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionStats.map((stat: any) => {
                              return (
                                <tr
                                  key={stat.product_name}
                                  style={{ borderBottom: "1px solid #eceeef" }}
                                >
                                  <td className={Styles.productName}>
                                    <p className={`mb-0 ${Styles.tableBody}`}>
                                      {stat.product_name}
                                    </p>
                                  </td>
                                  {env === envTypes.TEST && (
                                    <td className={Styles.billable_transaction}>
                                      <p
                                        className={`mb-0 ${Styles.tableBody}`}
                                        style={{ textAlign: "center" }}
                                      >
                                        {stat.test_credits ?? 0}
                                      </p>
                                    </td>
                                  )}
                                  <td className={Styles.billable_transaction}>
                                    <p
                                      className={`mb-0 ${Styles.tableBody}`}
                                      style={{ textAlign: "center" }}
                                    >
                                      {stat.billable_txn_count}
                                    </p>
                                  </td>
                                  <td className={Styles.success}>
                                    <p
                                      className={`mb-0 ${Styles.tableBody}`}
                                      style={{ textAlign: "center" }}
                                    >
                                      {stat.success_txn_count}
                                    </p>
                                  </td>
                                  <td className={Styles.failure}>
                                    <p
                                      className={`mb-0 ${Styles.tableBody}`}
                                      style={{ textAlign: "center" }}
                                    >
                                      {stat.failure_txn_count}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles.message}>No Record Found Yet</div>
          )}
        </div>
      )}
    </div>
  );
}

export default TransactionStats;

export const onboardingStatuses: any[] = [
  {
    key: "ORG_CREATED",
    value: "Org Created",
  },
  {
    key: "SERVICES_SUBSCRIBED",
    value: "Services Subscribed",
  },
  {
    key: "TEST_APP_CREATED",
    value: "Test App Created",
  },
  {
    key: "SERVICES_ADDED",
    value: "Services Added",
  },
  {
    key: "ONBOARDING_COMPLETED",
    value: "Onboarding Completed",
  },
  {
    key: "ACTIVATION_REQUESTED",
    value: "Activation Requested",
  },
  {
    key: "ACCOUNT_ACTIVATED",
    value: "Account Activated",
  },
  {
    key: "PLAN_SUBSCRIBED",
    value: "Plan Subscribed",
  },
];

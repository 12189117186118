import React from "react";
import Styles from "./checkbox.module.scss";

function Checkbox({ checked, disabled = null, label, ...props }: any) {
  return (
    <label className={`${Styles.container} ${disabled ? Styles.disabled : ""}`}>
      <input type="checkbox" {...props} checked={checked} disabled={disabled} />
      <span className={Styles.checkmark}></span>
    </label>
  );
}

export default Checkbox;

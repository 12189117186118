import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Styles from "./application.module.scss";
import Navbar from "../../shared/navbar/navbar";
import Accounts from "./accountListing/accountListing";
import Home from "./home/home";
import Plans from "./plans/plans";
import Products from "./products/products";
import Users from "./users/users";
import Account from "./accountListing/account/account";
import PlanDetails from "./plans/plan-details/planDetails";
import CreatePlan from "./plans/create-plan/createPlan";
import AccountActivationPendingPage from "./home/account-activation-pending-card/accountActivationPendingPage";
import BatchService from "./batchService/batchService";
import PendingProducts from "./home/pending-products/pendingProducts";
import EditDocs from "./editDocs/editDocs";
import ProtectedRoutes from "./protectedRoutes";
import Monitor from "./monitor/monitor";
import {
  accounts,
  batchService,
  editDocs,
  monitor,
  plans,
  products,
  users,
} from "../../constants/navItems";

function Application() {
  const location = useLocation();

  return (
    <div className={Styles.background}>
      <Navbar />
      <div className="p-4">
        <Switch location={location}>
          <Route
            path="/application/home/pending-products"
            component={PendingProducts}
          />
          <Route
            path="/application/home/pending-account-activation"
            component={AccountActivationPendingPage}
          />
          <Route path="/application/home" component={Home} />
          <ProtectedRoutes
            name={accounts}
            path="/application/accounts/:id"
            component={Account}
          />
          <ProtectedRoutes
            name={accounts}
            path="/application/accounts"
            component={Accounts}
          />
          <ProtectedRoutes
            name={plans}
            path="/application/plans/create-plan"
            component={CreatePlan}
          />
          <ProtectedRoutes
            name={plans}
            path="/application/plans/:id"
            component={PlanDetails}
          />
          <ProtectedRoutes
            name={plans}
            path="/application/plans"
            component={Plans}
          />
          <ProtectedRoutes
            name={products}
            path="/application/products"
            component={Products}
          />
          <ProtectedRoutes
            name={users}
            path="/application/users"
            component={Users}
          />
          <ProtectedRoutes
            name={batchService}
            path="/application/batch-service"
            component={BatchService}
          />
          <ProtectedRoutes
            name={editDocs}
            path="/application/edit-docs"
            component={EditDocs}
          />
          <ProtectedRoutes
            name={monitor}
            path="/application/monitor"
            component={Monitor}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Application;

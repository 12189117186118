import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { days, months, reportYears } from "../../../src/constants/calendarData";
import calendarDropdownStyles from "./calendarDropdownStyles";

function DatePickerDropdown(props: any) {
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");

  useEffect(() => {
    const dateVal: Date = new Date(props.value);
    const std_date = [
      dateVal.getDate(),
      dateVal.getMonth() + 1,
      dateVal.getFullYear(),
    ].map((date) => String(date));
    setDay(std_date[0]);
    setMonth(std_date[1]);
    setYear(std_date[2]);
  }, []);

  const dateErrorObject = {
    dayError: false,
    monthError: false,
    yearError: false,
  };
  const [dateError, setDateError] = useState({ ...dateErrorObject });

  const dayRef = useRef<any>();
  const monthRef = useRef<any>();
  const yearRef = useRef<any>();

  function isValidDay(day: string): boolean {
    if (isNaN(Number(day))) {
      return false;
    }
    return Number(day) > 31 || Number(day) < 1 ? false : true;
  }
  function isValidMonth(month: string): boolean {
    if (!isNaN(Number(month))) {
      return Number(month) > 12 || Number(month) < 1 ? false : true;
    }

    month = month.toLowerCase();
    switch (true) {
      case "jan".includes(month):
      case "feb".includes(month):
      case "mar".includes(month):
      case "apr".includes(month):
      case "may".includes(month):
      case "jun".includes(month):
      case "jul".includes(month):
      case "aug".includes(month):
      case "sep".includes(month):
      case "oct".includes(month):
      case "nov".includes(month):
      case "dec".includes(month):
        return true;
      default:
        return false;
    }
  }
  function isValidYear(year: string): boolean {
    return isNaN(Number(year)) ? false : true;
  }

  return (
    <div className="d-flex flex-column">
      {/* <label className={`${Styles.label}`} htmlFor="">{props.title}</label> */}
      <div className="d-flex ">
        <div className="d-flex ">
          <div className="d-flex flex-column">
            <Select
              /* value={{ label: "1", value: "1" }}
              defaultValue={{ label: "01" , value: "1" }} */
              ref={dayRef}
              onChange={(evtObj: any) => {
                props.onChange(
                  new Date(
                    Number(year),
                    Number(month) - 1, //TS counts months from 0, jan - 0, feb - 1 etc
                    Number(evtObj.value)
                  )
                );
                setDay(evtObj.value);
              }}
              onInputChange={(evtObj: any) => {
                if (!isValidDay(evtObj) && evtObj) {
                  setDateError((error) => ({
                    ...error,
                    dayError: true,
                  }));
                } else {
                  setDateError((error) => ({
                    ...error,
                    dayError: false,
                  }));
                }
              }}
              options={days}
              styles={
                !dateError.dayError
                  ? calendarDropdownStyles.customStylesForDays
                  : calendarDropdownStyles.customStylesForDaysError
              }
              placeholder={props.placeholder.split("-")[0]}
            />
          </div>
          <div className="d-flex flex-column ml-2">
            <Select
              /*defaultValue={{ label: "Jan" , value: "01" }}*/
              ref={monthRef}
              onChange={(evtObj: any) => {
                props.onChange(
                  new Date(
                    Number(year),
                    Number(evtObj.value) - 1, //TS counts months from 0, jan - 0, feb - 1 etc
                    Number(day)
                  )
                );
                setMonth(evtObj.value);
              }}
              onInputChange={(evtObj: any) => {
                if (!isValidMonth(evtObj) && evtObj) {
                  setDateError((error) => ({
                    ...error,
                    monthError: true,
                  }));
                } else {
                  setDateError((error) => ({
                    ...error,
                    monthError: false,
                  }));
                }
              }}
              options={months}
              styles={
                !dateError.monthError
                  ? calendarDropdownStyles.customStylesForMonths
                  : calendarDropdownStyles.customStylesForMonthsError
              }
              placeholder={props.placeholder.split("-")[1]}
            />
          </div>
          <div className="d-flex flex-column ml-2">
            <Select
              defaultValue={{
                label: `${String(new Date().getFullYear())}`,
                value: "1",
              }}
              //value={{ label: "1", value: "2022" }}
              ref={yearRef}
              onChange={(evtObj: any) => {
                props.onChange(
                  new Date(
                    Number(evtObj.value),
                    Number(month) - 1, //new Date() counts months from 0, jan -> 0, feb -> 1 etc
                    Number(day)
                  )
                );
                setYear(evtObj.value);
              }}
              onInputChange={(evtObj: any) => {
                if (!isValidYear(evtObj) && evtObj) {
                  setDateError((error) => ({
                    ...error,
                    yearError: true,
                  }));
                } else {
                  setDateError((error) => ({
                    ...error,
                    yearError: false,
                  }));
                }
              }}
              options={reportYears}
              styles={
                !dateError.yearError
                  ? calendarDropdownStyles.customStylesForYears
                  : calendarDropdownStyles.customStylesForMonthsError
              }
              placeholder={props.placeholder.split("-")[2]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatePickerDropdown;

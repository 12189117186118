import React, { useEffect, useRef } from "react";
import ArrowDown from "../svg/arrowDown";
import Styles from "./pagination.module.scss";

function Pagination({
  activePageNumber,
  total,
  postPerPage,
  navigateToPage,
  nextButtonShow,
}: any) {
  const pages = [];
  for (let i = 1; i <= Math.ceil(total / postPerPage); i++) {
    pages.push(i);
  }
  const [autoScroll, setAutoScroll] = React.useState(false);
  const currentIndex = pages.findIndex(
    (pageNumber) => pageNumber === activePageNumber
  );
  const pageRef = useRef<any>([]);
  useEffect(() => {
    if (autoScroll) {
      pageRef.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [currentIndex, autoScroll]);
  function gotToPreviousPage() {
    navigateToPage(activePageNumber - 1);
  }
  function goToNextPage() {
    navigateToPage(activePageNumber + 1);
  }
  return (
    <div className="d-inline-block">
      <div className="d-flex align-items-center">
        {activePageNumber !== 1 && (
          <div
            className={`${Styles.rotateLeft} px-3`}
            onClick={() => {
              setAutoScroll(true);
              gotToPreviousPage();
            }}
          >
            <ArrowDown className={Styles.svgFill} />
          </div>
        )}
        <div
          className="d-flex align-items-center"
          style={{
            maxWidth: "50vw",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "10px 0",
          }}
        >
          {pages.map((pageNumber, index: number) => {
            return (
              <div
                className="px-2"
                key={index}
                ref={(pageNumber) => (pageRef.current[index] = pageNumber)}
              >
                <div
                  onClick={() => navigateToPage(pageNumber)}
                  className={`d-flex justify-content-center align-items-center ${
                    activePageNumber === pageNumber
                      ? Styles.pageNumberActive
                      : Styles.pageNumberInActive
                  }`}
                >
                  <p className={`m-0 ${Styles.pageNumberText}`}>{pageNumber}</p>
                </div>
              </div>
            );
          })}
        </div>
        {!nextButtonShow &&
          activePageNumber !== pages.length &&
          pages.length > 0 && (
            <div
              className={`${Styles.rotateRight} px-3`}
              onClick={() => {
                setAutoScroll(true);
                goToNextPage();
              }}
            >
              <ArrowDown className={Styles.svgFill} />
            </div>
          )}
      </div>
    </div>
  );
}

export default Pagination;

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search, ...rest } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (search) {
      history.push({ ...rest, search });
    }
    // eslint-disable-next-line
  }, []);

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

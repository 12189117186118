import React, { createContext } from "react";
import { SECONDARYCOLOR } from "../shared/colors";
import Toast from "../shared/toast/toast";

export const ToastContext = createContext<any>({});

function ToastProvider({ ...props }: any) {
  const [state, dispatch] = React.useReducer((state: any, action: any) => {
    switch (action.type) {
      case "ADD_TOAST":
        return [...state, { ...action.payload }];
      case "REMOVE_TOAST":
        return state.filter((toast: any) => toast.id !== action.id);
      case "REMOVE_ALL_TOAST":
        return (state = []);
      default:
        return state;
    }
  }, []);

  function handleRemoveToast(id: number, time: number) {
    setTimeout(() => {
      dispatch({
        type: "REMOVE_TOAST",
        id: id,
      });
    }, time || 500);
  }

  return (
    <ToastContext.Provider value={dispatch}>
      {state.length > 0 && (
        <div
          style={{
            position: "fixed",
            zIndex: 100,
            top: "70px",
            right: "25px",
            minWidth: "500px",
            maxWidth: "500px",
          }}
        >
          {state.length > 1 && (
            <div className="py-2 text-right">
              <p
                className="mb-0"
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  color: SECONDARYCOLOR,
                }}
                onClick={() => {
                  dispatch({
                    type: "REMOVE_ALL_TOAST",
                  });
                }}
              >
                Clear All
              </p>
            </div>
          )}
          <div
            style={{ height: "90vh", overflowY: "auto", overflowX: "hidden" }}
          >
            {state.map((toast: any) => {
              return (
                <div className="p-2" key={toast.id}>
                  <Toast
                    click={() => handleRemoveToast(toast.id, 500)}
                    type={toast.type}
                    time={toast.time}
                    message={toast.message}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {props.children}
    </ToastContext.Provider>
  );
}

export default ToastProvider;

import React, { useContext, useEffect } from "react";
import { callGetApi } from "../../../api/axios";
import { ToastContext } from "../../../context/toastContext";
import { ACCENTCOLOR } from "../../../shared/colors";
import FilterDropdown from "../../../shared/inTable-filter-dropdown/filterDropdown";
import Loading from "../../../shared/loading/loading";
import Pagination from "../../../shared/pagination/pagination";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import ArrowDown from "../../../shared/svg/arrowDown";
import Close from "../../../shared/svg/close";
import Sort from "../../../shared/svg/sort";
import UpdateSvg from "../../../shared/svg/updateSvg";
import { ERROR } from "../../../utils/toastType";
import Styles from "./users.module.scss";
import moment from "moment";

function RolesList({ setToggleEditModal, is_allowed_to_write, refresh }: any) {
  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const [loading, setLoading] = React.useState(false);
  const [roleList, setRoleList] = React.useState<any[]>([]);
  const [roleNameFilter, setRoleNameFilter] = React.useState<String[]>([]);
  const [defaultRoleList, setDefaultRoleList] = React.useState<any>([]);
  const [listOfRoleNames, setListOfRoleNames] = React.useState<String[]>([]);
  const [sortType, setSortType] = React.useState("");
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [postPerPage] = React.useState(20);
  const dispatch = useContext(ToastContext);
  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;
  useEffect(() => {
    async function getAllRoles() {
      setLoading(true);
      try {
        const response: any = await callGetApi("/roles");
        const list = response.data.map((data: any) => ({
          name: data.role,
          email: data.created_by.email,
          status: data.status,
          permissions: data.permissions,
          created_on: data.createdAt,
          id: data._id,
        }));
        setLoading(false);
        setRoleList(list);
        setDefaultRoleList(list);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    getAllRoles();
  }, [dispatch, refresh]);
  const indicator = (filteredArrayName: String[]) => {
    return (
      <div className="px-1">
        <div
          className={`${Styles.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${Styles.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };
  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }
  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }
  // decide type of sort for specific column
  function sort(type: string) {
    const sortedArray = roleList.slice().sort((a: any, b: any) => {
      const leftParam = a.name.toLowerCase();
      const rightParam = b.name.toLowerCase();
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setRoleList(sortedArray);
  }
  // decide which sorting to apply based upon column name
  function applySorting() {
    if (sortType === sortTypes.descending) {
      setSortType("");
      setRoleList(defaultRoleList);
    } else if (sortType === sortTypes.ascending) {
      setSortType(sortTypes.descending);
      sort(sortTypes.descending);
    } else {
      setSortType(sortTypes.ascending);
      sort(sortTypes.ascending);
    }
  }
  // get all the names of the role in the given list
  function getAllRoleNames() {
    return setListOfRoleNames(roleList.map((role: any) => role.name));
  }
  function removeInTableFilter(name: string) {
    return setRoleNameFilter(
      roleNameFilter.filter((filter) => filter !== name)
    );
  }
  const filteredRoleList = roleList.filter(
    (role: any) =>
      // eslint-disable-next-line
      roleNameFilter.length == 0 || roleNameFilter.includes(role.name)
  );

  return (
    <div className="py-4">
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>Role list</p>
          </div>
        </div>
        {roleNameFilter.length > 0 && (
          <div
            className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
          >
            <p
              className={`px-2 mb-0 ${Styles.clearAll}`}
              onClick={() => setRoleNameFilter([])}
            >
              Clear All
            </p>
            {roleNameFilter.map((filter: any, index: number) => {
              return (
                <div className="p-1" key={index}>
                  <div
                    className={`d-flex align-items-center ${Styles.filterWrapper}`}
                  >
                    <p className={`mb-0 pl-2 ${Styles.filterName}`}>{filter}</p>
                    <div
                      className="px-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeInTableFilter(filter)}
                    >
                      <Close classes={Styles.close} width="8" height="8" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {loading ? (
          <div
            style={{ height: "350px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <>
            <div style={{ overflowX: "auto", minHeight: "400px" }}>
              <table style={{ width: "100%", paddingInline: "20px" }}>
                <thead className={sharedStyle.tableHeaderBack}>
                  <tr>
                    <th
                      className={Styles.name_column}
                      style={{ padding: "15px 15px 15px 25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <p className={`mb-0 ${Styles.tableHeaderText}`}>Name</p>
                        {roleNameFilter.length !== 0 &&
                          indicator(roleNameFilter)}
                        <div className="px-2">
                          <Sort
                            classes={`${Styles.sort} ${
                              sortType === sortTypes.descending
                                ? Styles.rotate
                                : ""
                            } ${sortType !== "" ? Styles.active : ""}`}
                            click={() => applySorting()}
                          />
                        </div>
                        <FilterDropdown
                          width="300px"
                          height="200px"
                          optionArray={listOfRoleNames}
                          filters={roleNameFilter}
                          onSelect={(option: any) => {
                            setActivePageNumber(1);
                            if (roleNameFilter.includes(option)) {
                              setRoleNameFilter(
                                roleNameFilter.filter(
                                  (data: any) => data !== option
                                )
                              );
                              return;
                            }
                            setRoleNameFilter([...roleNameFilter, option]);
                          }}
                          toggleReset={roleNameFilter.length !== 0}
                          onReset={() => setRoleNameFilter([])}
                        >
                          <div
                            className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                            onClick={() => getAllRoleNames()}
                          >
                            <ArrowDown classes={Styles.arrow} />
                          </div>
                        </FilterDropdown>
                      </div>
                    </th>
                    <th className={Styles.role_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Permissions
                      </p>
                    </th>
                    <th className={Styles.email_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Created By
                      </p>
                    </th>
                    <th className={Styles.joined_on_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Created On
                      </p>
                    </th>
                    <th className={Styles.edit_column}></th>
                  </tr>
                </thead>
                <tbody className={Styles.tableWrapper}>
                  {filteredRoleList.length > 0 ? (
                    filteredRoleList
                      .slice(indexOfFirstAccount, indexOfLastAccount)
                      .map((role: any, index: number) => {
                        const date = new Date(role.created_on);
                        return (
                          <tr
                            key={index}
                            className={`${Styles.table_row_wrapper}`}
                            style={
                              index !== filteredRoleList.length - 1
                                ? { borderBottom: "1px solid #eceeef" }
                                : { borderBottom: 0 }
                            }
                          >
                            <td
                              className={Styles.name_column}
                              style={{ padding: "15px 15px 15px 25px" }}
                            >
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {role.name}
                              </p>
                            </td>
                            <td className={Styles.role_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {role.permissions.length}
                              </p>
                            </td>
                            <td className={Styles.email_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {role.email}
                              </p>
                            </td>
                            <td className={Styles.joined_on_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {moment(date).format("DD MMM yyyy hh:mm")}
                              </p>
                            </td>
                            <td className={Styles.edit_column}>
                              {is_allowed_to_write && (
                                <div className="d-flex justify-content-between px-2">
                                  <div className={Styles.show_edit}>
                                    <UpdateSvg
                                      wrapper_width="30px"
                                      wrapper_height="30px"
                                      svg_width="15"
                                      svg_height="15"
                                      click={() =>
                                        setToggleEditModal((prev: any) => ({
                                          ...prev,
                                          item: "Roles",
                                          toggle: true,
                                          object: role,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr
                      style={{ height: "400px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <td className="mb-0">No Roles found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="px-4 py-3 d-flex align-items-center">
              <p className={`mb-0 ${Styles.numberOfRows}`}>
                {
                  filteredRoleList.slice(
                    indexOfFirstAccount,
                    indexOfLastAccount
                  ).length
                }{" "}
                rows per page
              </p>
              <div className="py-2 ml-auto">
                <Pagination
                  activePageNumber={activePageNumber}
                  total={filteredRoleList.length}
                  postPerPage={postPerPage}
                  navigateToPage={(value: number) => {
                    setActivePageNumber(value);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RolesList;

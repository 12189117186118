export const deprecatedProducts = [
  "in_identity_aadhaar_offline_web",
  "in_identity_aadhaar_offline_android",
  "in_financial_bsa_lite_netbanking",
  "in_financial_bsa_lite_pdfupload",
  "in_financial_itr_panextract",
  "in_financial_form26as_panextract",
  "in_merchant_cin_lite",
];

export const deprecatedProductsForDevDocs = [
  "in_identity_aadhaar_offline_web",
  "in_identity_aadhaar_offline_android",
  "in_financial_bsa_lite_netbanking",
  "in_financial_bsa_lite_pdfupload",
  "in_financial_itr_panextract",
  "in_financial_form26as_panextract",
  "in_merchant_cin_lite",
  "in_utility_name_match",
  "in_identity_ckyc-future_lite",
  "in_identity_ckyc-reliance_lite",
  "in_identity_ckyc-kotak_lite",
  "in_identity_aadhaar_verification",
  "in_identity_digilocker_advance",
  "gst_cin_fssai_name_match_report",
  "cin_gst_fssai_name_match_report",
  "fssai_gst_cin_name_match_report",
  "pan_gst_fssai_name_match_report",
  "fssai_gst_report",
  "gst_pan_report",
  "in_ml_ocr_sdk",
  "in_persona_student_verification",
  "in_vehicle_rc_mini",
];

export const hiddenCategoryForDevDocs = ["E-Sign"];

export const deprecatedProductsForProductPage = [
  "in_identity_aadhaar_offline_web",
  "in_identity_aadhaar_offline_android",
  "in_financial_bsa_lite_netbanking",
  "in_financial_bsa_lite_pdfupload",
  "in_financial_itr_panextract",
  "in_financial_form26as_panextract",
  "in_merchant_cin_lite",
  "in_utility_name_match",
  "in_identity_ckyc-future_lite",
  "in_identity_ckyc-reliance_lite",
  "in_identity_ckyc-kotak_lite",
  "in_identity_aadhaar_verification",
  "in_identity_digilocker_advance",
  "gst_cin_fssai_name_match_report",
  "cin_gst_fssai_name_match_report",
  "fssai_gst_cin_name_match_report",
  "pan_gst_fssai_name_match_report",
  "fssai_gst_report",
  "gst_pan_report",
  "in_ml_ocr_sdk",
  "in_persona_student_verification",
];

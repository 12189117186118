import React, { useContext, useEffect, useState } from "react";
import { omit } from "lodash";
import Styles from "./productInfoCard.module.scss";
import Input from "../../../../shared/input/input";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import Button from "../../../../shared/button/button";
import { LARGE } from "../../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import Loading from "../../../../shared/loading/loading";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import { ToastContext } from "../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import { callPostApi } from "../../../../api/axios";
import { ACCENTCOLOR } from "../../../../shared/colors";
import { checkIsEmpty } from "../../../../utils/validation";

export default function ProductInfoCard({
  product,
  plan,
  updatePlansMap,
  is_allows_to_write,
}: any) {
  const ARN_Pricing_Options = ["Default", "Custom"];
  const [currentArnType, setCurrentArnType] = useState<string>(
    ARN_Pricing_Options[0]
  );

  const plan_lower_bound = product.price_lower_bound;
  const plan_upper_bound = plan.upper_bound ?? 15;
  const disableIfNotAdmin = !is_allows_to_write;
  const [ExistingPrice, setExistingPrice] = React.useState<any>();
  const [plans, setPlans] = React.useState<any>();

  const [error, setError] = React.useState({
    currentARN: "",
    Default: "",
    Lite: "",
    Plus: "",
    Advance: "",
    Premium: "",
    Corporate: "",
    Postpaid: "",
    Mini: "",
    "Zoop PostPaid Testing Plan": "",
    "Zoop Testing Plan": "",
  });

  const [addons] = React.useState<any>(product.addons);
  const [addOnsValue, setAddOnsValue] = React.useState<any>({});
  const [addOnsError, setaddOnsError] = React.useState<any>(
    Array(product.addons.length).fill("")
  );

  const [loading, setLoading] = React.useState(true);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const numberRegexp = /[^\0-9]/;

  function isInvalidNumber(num: string) {
    if (num[0] === "0" && num[1] === ".") {
      return false;
    }
    if (num[0] === "0" && num[1] !== ".") {
      return true;
    }
  }

  function checkPlanValue(value: string) {
    if (checkIsEmpty(value)) {
      return "Should not be empty";
    }
    if (Number(value) <= plan_lower_bound) {
      return `Amount should be greater than ${plan_lower_bound}`;
    }
    if (Number(value) > plan_upper_bound) {
      return `Amount should be less than ${plan_upper_bound}`;
    }
    if (numberRegexp.test(value) || isInvalidNumber(value)) {
      return "Enter a valid amount";
    }
    return "";
  }

  function checkAddonValue(value: string) {
    if (checkIsEmpty(value)) {
      return "Should not be empty";
    }
    if (Number(value) < 0) {
      return `Amount can not be negative`;
    }
    if (numberRegexp.test(value)) {
      return "Enter a valid amount";
    }
    if (value[0] === "0" && value.length > 1 && value[1] !== ".") {
      return "Enter a valid amount";
    }
    return "";
  }

  useEffect(() => {
    if (!ExistingPrice) return;
    if (disableIfNotAdmin) return;
    setError((error) => ({
      ...error,
      Default: checkPlanValue(`${plans.Default}`),
      Lite: checkPlanValue(`${plans.Lite}`),
      Plus: checkPlanValue(`${plans.Plus}`),
      Advance: checkPlanValue(`${plans.Advance}`),
      Premium: checkPlanValue(`${plans.Premium}`),
      Corporate: checkPlanValue(`${plans.Corporate}`),
      Mini: checkPlanValue(`${plans.Mini}`),
      "Zoop PostPaid Testing Plan": checkPlanValue(
        `${plans["Zoop PostPaid Testing Plan"]}`
      ),
      "Zoop Testing Plan": checkPlanValue(`${plans["Zoop Testing Plan"]}`),
    }));

    if (addOnsValue.length === 0) return;
    const errArr = addOnsError;
    let index = 0;
    for (const key in addOnsValue) {
      if (Object.hasOwn(addOnsValue, key)) {
        errArr[index++] = checkAddonValue(addOnsValue[key].toString());
      }
    }
    setaddOnsError(errArr);
  }, [ExistingPrice]);

  async function updatePlan() {
    try {
      setBtnLoading(true);

      if (currentArnType === "") {
        setError((error) => ({
          ...error,
          currentARN: "ARN pricing type is not selected",
        }));
        setBtnLoading(false);
        return;
      }

      for (const key in addOnsError) {
        if (Object.hasOwn(addOnsError, key)) {
          if (addOnsError[key] !== "") {
            setBtnLoading(false);
            dispatch({
              type: "ADD_TOAST",
              payload: {
                id: Math.floor(Math.random() * 100),
                type: ERROR,
                message: "Please enter valid data!",
              },
            });
            return;
          }
        }
      }

      if (
        (currentArnType === ARN_Pricing_Options[0] && error.Default !== "") ||
        (currentArnType === ARN_Pricing_Options[1] &&
          (error.Lite !== "" ||
            error.Plus !== "" ||
            error.Advance !== "" ||
            error.Premium !== "" ||
            error.Corporate !== "" ||
            error.Postpaid !== "" ||
            error.Mini !== "" ||
            error["Zoop PostPaid Testing Plan"] !== "" ||
            error["Zoop Testing Plan"] !== ""))
      ) {
        setBtnLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Please enter valid data!",
          },
        });
      } else {
        const { Default, ...Custom } = {
          ...plans,
          Default: plans.Default,
        };

        let data;
        const map = new Map();
        addons.map((addon: any) => {
          map.set(addon.addon_key, addOnsValue[addon.addon_key]);
        });

        if (currentArnType === ARN_Pricing_Options[0]) {
          data = {
            arn: product.arn,
            pricing_ctx:
              addons.length === 0
                ? Default
                : {
                    unit_price: Default,
                    ...Object.fromEntries(map),
                  },
          };
        } else {
          data = {
            arn: product.arn,
            Lite: {
              pricing_ctx:
                typeof plan?.Lite === "number"
                  ? Custom.Lite
                  : {
                      unit_price: Custom.Lite,
                      ...Object.fromEntries(map),
                    },
            },
            Advance: {
              pricing_ctx:
                typeof plan?.Advance === "number"
                  ? Custom.Advance
                  : {
                      unit_price: Custom.Advance,
                      ...Object.fromEntries(map),
                    },
            },
            Premium: {
              pricing_ctx:
                typeof plan?.Premium === "number"
                  ? Custom.Premium
                  : {
                      unit_price: Custom.Premium,
                      ...Object.fromEntries(map),
                    },
            },
            Corporate: {
              pricing_ctx:
                typeof plan?.Corporate === "number"
                  ? Custom.Corporate
                  : {
                      unit_price: Custom.Corporate,
                      ...Object.fromEntries(map),
                    },
            },
            Plus: {
              pricing_ctx:
                typeof plan?.Plus === "number"
                  ? Custom.Plus
                  : {
                      unit_price: Custom.Plus,
                      ...Object.fromEntries(map),
                    },
            },
            Mini: {
              pricing_ctx:
                typeof plan?.Mini === "number"
                  ? Custom.Mini
                  : {
                      unit_price: Custom.Mini,
                      ...Object.fromEntries(map),
                    },
            },
            "Zoop PostPaid Testing Plan": {
              pricing_ctx:
                typeof plan["Zoop PostPaid Testing Plan"] === "number"
                  ? Custom["Zoop PostPaid Testing Plan"]
                  : {
                      unit_price: Custom["Zoop PostPaid Testing Plan"],
                      ...Object.fromEntries(map),
                    },
            },
            "Zoop Testing Plan": {
              pricing_ctx:
                typeof plan["Zoop Testing Plan"] === "number"
                  ? Custom["Zoop Testing Plan"]
                  : {
                      unit_price: Custom["Zoop Testing Plan"],
                      ...Object.fromEntries(map),
                    },
            },
          };
        }

        const response: any = await callPostApi(`/plans/update-pricing`, data);
        if (response.data !== "") {
          if (currentArnType === ARN_Pricing_Options[0]) {
            const tmpData = {
              Lite: plans.Default,
              Plus: plans.Default,
              Advance: plans.Default,
              Premium: plans.Default,
              Corporate: plans.Default,
              Mini: plans.Default,
              "Zoop PostPaid Testing Plan": plans.Default,
              "Zoop Testing Plan": plans.Default,
              addons: Object.fromEntries(map),
            };
            updatePlansMap({ ...tmpData });
            updateValues(tmpData);
          }
          if (currentArnType === ARN_Pricing_Options[1]) {
            updatePlansMap({
              ...Custom,
              addons: Object.fromEntries(map),
            });
            updateValues(Custom);
          }
        }

        setBtnLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message: response.data,
          },
        });
      }
    } catch (err: any) {
      setBtnLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response?.data.err,
        },
      });
    }
  }

  function updateValues(obj: any) {
    if (
      obj?.Lite === obj?.Advance &&
      obj?.Advance === obj?.Plus &&
      obj?.Plus === obj?.Premium &&
      obj?.Premium === obj?.Corporate &&
      obj?.Corporate === obj?.Mini &&
      obj?.Mini === obj["Zoop PostPaid Testing Plan"] &&
      obj["Zoop PostPaid Testing Plan"] === obj["Zoop Testing Plan"]
    ) {
      setPlans({ ...obj, Default: obj?.Lite });
      setExistingPrice({ ...obj, Default: obj?.Lite });
    } else {
      setPlans({
        ...obj,
        Default: 0,
      });
      setExistingPrice({
        ...obj,
        Default: 0,
      });
    }
  }

  useEffect(() => {
    try {
      let addonsVal = {};
      if (typeof plan?.Lite !== "number") {
        const addons = omit(plan?.Lite, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan?.Advance !== "number") {
        const addons = omit(plan?.Advance, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan?.Plus !== "number") {
        const addons = omit(plan?.Plus, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan?.Premium !== "number") {
        const addons = omit(plan?.Premium, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan?.Corporate !== "number") {
        const addons = omit(plan?.Corporate, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan?.Mini !== "number") {
        const addons = omit(plan?.Mini, "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan["Zoop PostPaid Testing Plan"] !== "number") {
        const addons = omit(plan["Zoop PostPaid Testing Plan"], "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }
      if (typeof plan["Zoop Testing Plan"] !== "number") {
        const addons = omit(plan["Zoop Testing Plan"], "unit_price");
        addonsVal = { ...addonsVal, ...addons };
      }

      setAddOnsValue(addonsVal);

      const tmpObj = {
        Lite:
          typeof plan?.Lite === "number" ? plan?.Lite : plan?.Lite.unit_price,
        Advance:
          typeof plan?.Advance === "number"
            ? plan?.Advance
            : plan?.Advance.unit_price,
        Plus:
          typeof plan?.Plus === "number" ? plan?.Plus : plan?.Plus.unit_price,
        Premium:
          typeof plan?.Premium === "number"
            ? plan?.Premium
            : plan?.Premium.unit_price,
        Corporate:
          typeof plan?.Corporate === "number"
            ? plan?.Corporate
            : plan?.Corporate.unit_price,
        Mini:
          typeof plan?.Mini === "number" ? plan?.Mini : plan?.Mini.unit_price,
        "Zoop PostPaid Testing Plan":
          typeof plan["Zoop PostPaid Testing Plan"] === "number"
            ? plan["Zoop PostPaid Testing Plan"]
            : plan["Zoop PostPaid Testing Plan"].unit_price,
        "Zoop Testing Plan":
          typeof plan["Zoop Testing Plan"] === "number"
            ? plan["Zoop Testing Plan"]
            : plan["Zoop Testing Plan"].unit_price,
      };

      if (
        tmpObj?.Lite === tmpObj?.Advance &&
        tmpObj?.Advance === tmpObj?.Plus &&
        tmpObj?.Plus === tmpObj?.Premium &&
        tmpObj?.Premium === tmpObj?.Corporate &&
        tmpObj?.Corporate === tmpObj?.Mini &&
        tmpObj?.Mini === tmpObj["Zoop PostPaid Testing Plan"] &&
        tmpObj["Zoop PostPaid Testing Plan"] === tmpObj["Zoop Testing Plan"]
      ) {
        setExistingPrice({ ...tmpObj, Default: tmpObj?.Lite });
        setPlans({ ...tmpObj, Default: tmpObj?.Lite });
      } else {
        setExistingPrice({
          ...tmpObj,
          Default: 0,
        });
        setPlans({
          ...tmpObj,
          Default: 0,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response?.data.err,
        },
      });
    }
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{ height: "150px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className={`${Styles.cardContainer} d-flex flex-column px-5`}>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <p className={`${Styles.heading}`}>{product.name}</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-10">
                <div className="row">
                  <div
                    className="col-lg-4 col-md-6 col-sm-6"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    <InputDropdown
                      labelname="ARN Pricing"
                      optionsArray={ARN_Pricing_Options}
                      defaultValue={currentArnType}
                      miniLabel={true}
                      whiteBackgroundcolor={true}
                      click={(value: string) => {
                        setCurrentArnType(value);
                        setError((error) => ({
                          ...error,
                          currentARN: "",
                        }));
                      }}
                    />
                    {error.currentARN && (
                      <ErrorMessage>{error.currentARN}</ErrorMessage>
                    )}
                  </div>
                  {currentArnType === ARN_Pricing_Options[0] && (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          min={0}
                          id="Default-pricing"
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Default}
                          existingPriceLabel={
                            ExistingPrice?.Default !== plans?.Default
                              ? ExistingPrice?.Default
                              : ""
                          }
                          placeholder="Enter price"
                          labelname="Pricing"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Default:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Default: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Default && (
                          <ErrorMessage>{error.Default}</ErrorMessage>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {currentArnType === ARN_Pricing_Options[1] && (
                  <>
                    <div className={`row ${Styles.removeExtraSpace} mt-3`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{"Plans"}</p>
                          <p className={`${Styles.plansHeading}`}>Mini</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Mini-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Mini}
                          existingPriceLabel={
                            ExistingPrice?.Mini !== plans?.Mini
                              ? ExistingPrice?.Mini
                              : ""
                          }
                          placeholder="Enter price"
                          labelname="Pricing"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Mini:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Mini: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Mini && (
                          <ErrorMessage>{error.Mini}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>Lite</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Lite-pricing"
                          min={0}
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Lite}
                          existingPriceLabel={
                            ExistingPrice?.Lite !== plans?.Lite
                              ? ExistingPrice?.Lite
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Lite:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Lite: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Lite && (
                          <ErrorMessage>{error.Lite}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>Plus</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          min={0}
                          id="Plus-pricing"
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Plus}
                          existingPriceLabel={
                            ExistingPrice?.Plus !== plans?.Plus
                              ? ExistingPrice?.Plus
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Plus:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Plus: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Plus && (
                          <ErrorMessage>{error.Plus}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>Advance</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          min={0}
                          id="Advance-pricing"
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Advance}
                          existingPriceLabel={
                            ExistingPrice?.Advance !== plans?.Advance
                              ? ExistingPrice?.Advance
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Advance:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Advance: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Advance && (
                          <ErrorMessage>{error.Advance}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>Premium</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          min={0}
                          id="Premium-pricing"
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Premium}
                          existingPriceLabel={
                            ExistingPrice?.Premium !== plans?.Premium
                              ? ExistingPrice?.Premium
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Premium:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Premium: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Premium && (
                          <ErrorMessage>{error.Premium}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>Corporate</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Corporate-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Corporate}
                          existingPriceLabel={
                            ExistingPrice?.Corporate !== plans?.Corporate
                              ? ExistingPrice?.Corporate
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Corporate:
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Corporate: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Corporate && (
                          <ErrorMessage>{error.Corporate}</ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>
                            Zoop Testing Plan
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Zoop-Testing-Plan-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans["Zoop Testing Plan"]}
                          existingPriceLabel={
                            ExistingPrice["Zoop Testing Plan"] !==
                            plans["Zoop Testing Plan"]
                              ? ExistingPrice["Zoop Testing Plan"]
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              "Zoop Testing Plan":
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              "Zoop Testing Plan": checkPlanValue(
                                event.target.value
                              ),
                            }));
                          }}
                        />
                        {error["Zoop Testing Plan"] && (
                          <ErrorMessage>
                            {error["Zoop Testing Plan"]}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{" "}</p>
                          <p className={`${Styles.plansHeading}`}>
                            Zoop PostPaid Testing Plan
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Zoop-PostPaid-Testing-Plan-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans["Zoop PostPaid Testing Plan"]}
                          existingPriceLabel={
                            ExistingPrice["Zoop PostPaid Testing Plan"] !==
                            plans["Zoop PostPaid Testing Plan"]
                              ? ExistingPrice["Zoop PostPaid Testing Plan"]
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              "Zoop PostPaid Testing Plan":
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              "Zoop PostPaid Testing Plan": checkPlanValue(
                                event.target.value
                              ),
                            }));
                          }}
                        />
                        {error["Zoop PostPaid Testing Plan"] && (
                          <ErrorMessage>
                            {error["Zoop PostPaid Testing Plan"]}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>

                    {/* <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{""}</p>
                          <p className={`${Styles.formLabelMini}`}>{""}</p>
                          <p className={`${Styles.plansHeading}`}>
                            Zoop Testing Plan
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Zoop-Testing-Plan-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans["Zoop Testing Plan"]}
                          existingPriceLabel={
                            ExistingPrice["Zoop Testing Plan"] !==
                            plans["Zoop Testing Plan"]
                              ? ExistingPrice["Zoop Testing Plan"]
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              "Zoop Testing Plan":
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              "Zoop Testing Plan": checkPlanValue(
                                event.target.value
                              ),
                            }));
                          }}
                        />
                        {error["Zoop Testing Plan"] && (
                          <ErrorMessage>
                            {error["Zoop Testing Plan"]}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>

                    <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{""}</p>
                          <p className={`${Styles.plansHeading}`}>
                            Zoop PostPaid Testing Plan
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="Zoop-PostPaid-Testing-Plan-pricing"
                          miniLabel={true}
                          min={0}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans["Zoop PostPaid Testing Plan"]}
                          existingPriceLabel={
                            ExistingPrice["Zoop PostPaid Testing Plan"] !==
                            plans["Zoop PostPaid Testing Plan"]
                              ? ExistingPrice["Zoop PostPaid Testing Plan"]
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              "Zoop PostPaid Testing Plan":
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              "Zoop PostPaid Testing Plan": checkPlanValue(
                                event.target.value
                              ),
                            }));
                          }}
                        />
                        {error["Zoop PostPaid Testing Plan"] && (
                          <ErrorMessage>
                            {error["Zoop PostPaid Testing Plan"]}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>

                    {/* <div className={`row ${Styles.removeExtraSpace}`}>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="py-1 mt-2">
                          <p className={`${Styles.formLabelMini}`}>{""}</p>
                          <p className={`${Styles.plansHeading}`}>Postpaid</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <Input
                          no_shadow="true"
                          type="number"
                          id="post-paid-pricing"
                          miniLabel={true}
                          withIcon={"rupee"}
                          disabled={disableIfNotAdmin}
                          value={plans?.Postpaid}
                          existingPriceLabel={
                            ExistingPrice?.Postpaid !== plans?.Postpaid
                              ? ExistingPrice?.Postpaid
                              : ""
                          }
                          placeholder="Enter price"
                          onChange={(event: any) => {
                            setPlans((data: any) => ({
                              ...data,
                              Postpaid: event.target.value === "" ? "" : Number(event.target.value),
                            }));
                            setError((error) => ({
                              ...error,
                              Postpaid: checkPlanValue(event.target.value),
                            }));
                          }}
                        />
                        {error.Postpaid && (
                          <ErrorMessage>{error.Postpaid}</ErrorMessage>
                        )}
                      </div>
                    </div> */}
                  </>
                )}

                {addons.length > 0 && (
                  <>
                    <hr className="ml-0" />
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <p className={`${Styles.heading}`}>Add ons</p>
                      </div>
                    </div>
                  </>
                )}
                {addons.length > 0 &&
                  addons.map((addon: any, index: number) => {
                    return (
                      <>
                        <div className={`row ${Styles.removeExtraSpace}`}>
                          <div className="col-lg-3 col-md-4 col-sm-12">
                            <Input
                              no_shadow="true"
                              type="text"
                              id="addon-arn"
                              miniLabel={true}
                              value={addon.addon_key}
                              whiteBackgroundcolor={true}
                              disabled
                              labelname="ARN"
                            />
                          </div>

                          <div className="col-lg-3 col-md-4 col-sm-12">
                            <Input
                              no_shadow="true"
                              type="text"
                              id="addon-name"
                              miniLabel={true}
                              disabled
                              value={addon.addon_name}
                              whiteBackgroundcolor={true}
                              labelname="Name"
                            />
                          </div>

                          <div className="col-lg-2 col-md-4 col-sm-12">
                            <Input
                              no_shadow="true"
                              type="number"
                              min={0}
                              id="addon-pricing"
                              miniLabel={true}
                              withIcon={"rupee"}
                              disabled={disableIfNotAdmin}
                              value={addOnsValue[addon.addon_key]}
                              hasError={addOnsError[index]}
                              labelname="Pricing"
                              onChange={(event: any) => {
                                const { value } = event.target;

                                const newValArr = { ...addOnsValue };
                                newValArr[addon.addon_key] =
                                  value === "" ? "" : Number(value);
                                setAddOnsValue(newValArr);

                                const newErrArr = [...addOnsError];
                                newErrArr[index] = checkAddonValue(value);
                                setaddOnsError(newErrArr);
                              }}
                            />
                            {addOnsError[index] !== "" && (
                              <ErrorMessage>{addOnsError[index]}</ErrorMessage>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-md-12 col-sm-12 ">
                            <Input
                              no_shadow="true"
                              type="text"
                              id="addon-description"
                              miniLabel={true}
                              disabled
                              value={addon.addon_description}
                              whiteBackgroundcolor={true}
                              labelname="Addon Description"
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="col-md-2 col-sm-2 d-flex flex-column pt-4 mt-3">
                <div className="d-flex flex-row-reverse">
                  <Button
                    size={LARGE}
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    isLoading={btnLoading}
                    disabled={disableIfNotAdmin || !is_allows_to_write}
                    onClick={() => {
                      updatePlan();
                    }}
                  >
                    Update
                  </Button>
                </div>
                <div>
                  <p
                    className={`${Styles.btnNote} d-flex justify-content-center mt-1`}
                  >
                    *It will update all Custom and Standard plans
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

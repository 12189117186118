import React from "react";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import popUpStyles from "../../../../shared/popup.module.scss";
import Styles from "./viewRoles.module.scss";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";

function ViewRoles({ onClose }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>View Roles</p>
        </div>
        <div className="py-3 px-4 mx-2">
          <div className="py-2">
            <p className={`mb-0 ${Styles.label}`}>Admin</p>
            <ul>
              <li className={Styles.body_text}>
                Complete Read / Write access of the Admin Portal.
              </li>
              <li className={Styles.body_text}>
                Manage Overview of the dashboard and client related information.
              </li>
              <li className={Styles.body_text}>
                Assigning KAM for all the new accounts created.
              </li>
              <li className={Styles.body_text}>
                Keeping track of KAM activities and ensuring all the tasks are
                adhered to SLAs.
              </li>
              <li className={Styles.body_text}>
                Giving Approvals for selected action performed by KAM on the
                Customer Dashboard.
              </li>
            </ul>
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Styles.label}`}>KAM</p>
            <ul>
              <li className={Styles.body_text}>
                Ability to modify client accounts with limited access.
              </li>
              <li className={Styles.body_text}>
                First point of contact for managing and tracking all activities.
              </li>
              <li className={Styles.body_text}>
                Supporting clients with all Account and Business related tasks
                from onboarding to account activation.
              </li>
              <li className={Styles.body_text}>
                Involved in maintenance of all client accounts by providing
                appropriate review and approvals for various client request.
              </li>
            </ul>
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Styles.label}`}>Business Manager</p>
            <ul>
              <li className={Styles.body_text}>
                View access for client related information and stay updated with
                current statuses.
              </li>
              <li className={Styles.body_text}>
                Providing additional support to KAM for all the business related
                decisions and customer experience.
              </li>
            </ul>
          </div>
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={MEDIUM}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRoles;

import React, { useCallback, useContext, useEffect } from "react";
import Styles from "./activityLogs.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import Loading from "../../../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { monthName } from "../../../../../../utils/getMonth";
import Pagination from "../../../../../../shared/pagination/pagination";

import { ToastContext } from "../../../../../../context/toastContext";
import { ERROR } from "../../../../../../utils/toastType";
import { callGetApi } from "../../../../../../api/axios";
import { useParams } from "react-router-dom";

function ActivityLogs() {
  const { id }: any = useParams();
  const [activityLogs, setActivityLogs] = React.useState([]);
  const [activityLogsLoading, setActivityLogsLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [postPerPage] = React.useState(30);
  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;

  const getActivityLogs = useCallback(async () => {
    setActivityLogsLoading(true);
    try {
      const response: any = await callGetApi(
        `/organisations/adminActivity/${id}`
      );
      setActivityLogsLoading(false);

      if (response?.data?.length) {
        const mostRecent = response?.data
          ?.filter((log: any) => log !== null)
          .sort((olderActivityLogs: any, mostRecentActivityLogs: any) => {
            return (
              mostRecentActivityLogs?.updatedAt - olderActivityLogs?.updatedAt
            );
          });
        setActivityLogs(mostRecent);
      }
    } catch (err: any) {
      setActivityLogsLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err?.response?.data?.err || "Something went wrong!",
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    getActivityLogs();
  }, [getActivityLogs]);

  const getDates = () => {
    const today = new Date();
    let startMonth,
      startDay: number = 0;

    const endMonth = Number(String(today.getMonth() + 1).padStart(2, "0"));
    let endDay: number = Number(String(today.getDate()).padStart(2, "0"));
    const endYear = today.getFullYear();

    if (endDay <= 30) {
      startMonth = endMonth - 1;
      const temp = endMonth === 1 ? 12 : endMonth - 1;
      if ([1, 3, 5, 7, 8, 10, 12].includes(temp))
        startDay = 31 - (endDay - 30) * -1;
      else if (endMonth === 2) {
        if (endYear % 4 === 0) startDay = 29 - (endDay - 30) * -1;
        else startDay = 28 - (endDay - 30) * -1;
      } else startDay = 28 - (endDay - 30) * -1;
    } else {
      startMonth = endMonth;
      endDay = startDay;
    }
    const startYear = endMonth === 1 && endDay < 30 ? endYear - 1 : endYear;

    const startDate = `${startYear}-${startMonth}-${startDay}`;
    return startDate;
  };

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Data Found</h5>
    </div>
  );

  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Activity Logs </p>
        </div>
      </div>
      {activityLogsLoading ? (
        loading
      ) : activityLogs.length > 0 ? (
        <div style={{ overflowX: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead className={sharedStyle.tableHeaderBack}>
              <tr>
                <th className={Styles.date}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Date and Time
                  </p>
                </th>

                <th className={Styles.particulars}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Particulars
                  </p>
                </th>
                <th className={Styles.person}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Person/Entity
                  </p>
                </th>
                <th className={Styles.old_value}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Old Value
                  </p>
                </th>
                <th className={Styles.new_value}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    New Value
                  </p>
                </th>
                <th className={Styles.event_id}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Event ID
                  </p>
                </th>
                <th className={Styles.ip_address}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    IP Address
                  </p>
                </th>
              </tr>
            </thead>
            <tbody className={Styles.tableWrapper}>
              {activityLogs
                .slice(indexOfFirstAccount, indexOfLastAccount)
                .map((log: any, index: number) => {
                  const date = new Date(Number(log?.updatedAt));
                  return (
                    <tr
                      key={index}
                      style={
                        index !== activityLogs.length - 1
                          ? { borderBottom: "1px solid #eceeef" }
                          : { borderBottom: 0 }
                      }
                    >
                      <td className={Styles.date}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {`${monthName(
                            date.getMonth()
                          )} ${date.getDate()}, ${date.getFullYear()}`}
                        </p>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} PM IST`}
                        </p>
                      </td>
                      <td className={Styles.particulars}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {log?.actionType}
                        </p>
                      </td>
                      <td className={Styles.person}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {log?.changedBy.user_name}
                        </p>
                      </td>
                      <td className={Styles.old_value}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {log?.prevState.value}
                        </p>
                      </td>
                      <td className={Styles.new_value}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {log?.currentState.value}
                        </p>
                      </td>
                      <td className={Styles.event_id}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {log?._id}
                        </p>
                      </td>
                      <td className={Styles.ip_address}>
                        <div className="d-flex align-items-center">
                          <div className="pr-2"></div>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {log?.ipAddress}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        emptyState
      )}
      <div className="px-4 py-3 d-flex align-items-center">
        <p className={`mb-0 ${Styles.numberOfRows}`}>
          {activityLogs.slice(indexOfFirstAccount, indexOfLastAccount).length}{" "}
          rows per page
        </p>
        {activityLogs.length > 0 && (
          <div className="py-2 ml-auto">
            <Pagination
              activePageNumber={activePageNumber}
              total={activityLogs.length}
              postPerPage={postPerPage}
              navigateToPage={(value: number) => {
                setActivePageNumber(value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivityLogs;

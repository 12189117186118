import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { useHistory } from "react-router-dom";

export default function AuthState(props: any) {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    function authListener() {
      firebase.auth().onAuthStateChanged(async function (user) {
        setLoading(false);
        if (user) {
          const { claims } = await user.getIdTokenResult();
          localStorage.setItem(
            "current_user",
            JSON.stringify({
              id: claims.user_id,
              role: claims.role,
              name: claims.name,
              email: claims.email,
            })
          );
          props.location.pathname === "/login" ||
          props.location.pathname === "/"
            ? window.location.replace("/application")
            : history.replace(props.location.pathname);
        } else {
          history.replace("/login");
        }
      });
    }

    authListener();
    // eslint-disable-next-line
  }, []);

  return loading ? <p>Loading...</p> : props.children;
}

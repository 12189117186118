import React, { useContext, useEffect } from "react";
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import OverviewCard from "../overview-card/overviewCard";
import RupeeSVG from "../../../../shared/svg/rupeeSvg";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../shared/colors";
import PaymentPendingIllustrationSrc from "../../../../assets/images/payment-pending.png";
import { Link } from "react-router-dom";
import { callGetApi } from "../../../../api/axios";
import Loading from "../../../../shared/loading/loading";
import { durationStyles } from "../../../../constants/durationStyles";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import { ERROR } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../utils/updateAmount";

function PaymentPending() {
  const [paymentPendingLoading, setPaymentPendingLoading] =
    React.useState(false);
  const [paymentPendingList, setPaymentPendingList] = React.useState([]);

  const dispatch = useContext(ToastContext);
  useEffect(() => {
    async function getPendingPayment() {
      setPaymentPendingLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=payment_pending"
        );
        const paymentPendingAccounts = response.data.map((data: any) => ({
          name: data.name,
          amount: roundOfDecimal(data.amount),
          plan_name: data.plan_name,
          country_code: data.country_code,
          requested_on: data.requested_on,
          id: data._id,
        }));
        setPaymentPendingList(paymentPendingAccounts);
        setPaymentPendingLoading(false);
      } catch (error: any) {
        setPaymentPendingLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: error.response.data.err,
          },
        });
      }
    }

    getPendingPayment();
  }, [dispatch]);
  return (
    <div className="row">
      <OverviewCard
        illustrationUrl={PaymentPendingIllustrationSrc}
        headingText="Pending Payment"
        bodyText="Follow up with accounts in case payments are not made for more than 5 days."
      />
      <div className="col-lg-9 col-md-12 p-3">
        <div className={`${sharedStyle.card}`}>
          <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center pb-3">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                Payment Pending
              </p>
            </div>
          </div>
          {paymentPendingLoading ? (
            <div
              style={{ height: "300px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : paymentPendingList.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <table style={{ width: "100%" }}>
                <thead className={sharedStyle.tableHeaderBack}>
                  <tr>
                    <th className={Styles.account_name_column}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Account Name
                      </p>
                    </th>
                    <th className={Styles.country_code}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Country
                      </p>
                    </th>
                    <th className={Styles.plan_name}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Plan Name
                      </p>
                    </th>
                    <th className={Styles.current_balance}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Amount
                      </p>
                    </th>
                    <th className={Styles.date}>
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        Date
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className={Styles.tableWrapper}>
                  {paymentPendingList.map((account: any, index: number) => {
                    const date = new Date(account.requested_on);
                    const duration = getDuration(date);
                    const style = getDurationStyle(duration, 7, 3);
                    return (
                      <tr
                        key={index}
                        style={
                          index !== paymentPendingList.length - 1
                            ? { borderBottom: "1px solid #eceeef" }
                            : { borderBottom: 0 }
                        }
                      >
                        <td className={Styles.account_name_column}>
                          <Link
                            to={`/application/accounts/${account.id}`}
                            className={`mb-0 ${Styles.accountName}`}
                          >
                            {account.name}
                          </Link>
                        </td>
                        <td className={Styles.country_code}>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.country_code}
                          </p>
                        </td>
                        <td className={Styles.plan_name}>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.plan_name}
                          </p>
                        </td>
                        <td className={Styles.current_balance}>
                          <div className="d-flex align-items-center">
                            <div className="pr-2">
                              <RupeeSVG
                                width="8"
                                height="12"
                                color={PRIMARYCOLOR}
                              />
                            </div>
                            <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                              {numberWithCommas(account.amount)}
                            </p>
                          </div>
                        </td>
                        <td className={Styles.date}>
                          <div
                            className={`mb-0 ${Styles.duration}`}
                            style={
                              style === durationStyles.DANGER
                                ? { color: "#d64747" }
                                : style === durationStyles.WARNING
                                ? { color: "#f4a63b" }
                                : { color: "#31b975" }
                            }
                          >
                            {duration}
                          </div>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {`${monthName(
                              date.getMonth()
                            )} ${date.getDate()}, ${date.getFullYear()}`}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{ height: "300px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <p className="mb-0">No Account Found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentPending;

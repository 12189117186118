import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function RenderTooltip({ children, title, placement }: any) {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 0 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

export default RenderTooltip;

export const roleType = {
  business_manager: "business_manager",
  kam: "kam",
  admin: "admin",
  devdoc: "devdoc",
};

export const listOfRoles = [
  { label: "Business Manager", value: roleType.business_manager },
  { label: "Kam", value: roleType.kam },
  { label: "Admin", value: roleType.admin },
  { label: "DevDoc", value: roleType.devdoc },
];

export const getCurrentUserRole = () => {
  const userData = JSON.parse(localStorage.getItem("current_user") || "");
  return userData?.role || "";
};

import React, { useContext, useEffect } from "react";
import {
  batchServiceTabs,
  CREATE_TASK,
  TRACK_TASK,
} from "../../../constants/batchServiceNavTabs";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import CreateTask from "./create-task/createTask";
import TrackTask from "./track-task/trackTask";
import { callGetApi } from "../../../api/axios";
import { ToastContext } from "../../../context/toastContext";
import { ERROR } from "../../../utils/toastType";
import { batchService } from "../../../constants/navItems";
import { PermissionContext } from "../../../context/permissionContext";

const BatchService = () => {
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const batchPermissions = assets[batchService];

  const [loading, setLoading] = React.useState<boolean>(false);
  const [orgIds, setOrgIds] = React.useState<Array<any>>([]);
  const [taskList, setTaskList] = React.useState<Array<any>>([]);
  const [isfilterTask, setIsfilterTask] = React.useState(false);
  const [loadingForGetBatch, setLoadingForGetBatch] =
    React.useState<boolean>(false);

  const [batchTypesList, setBatchTypesList] = React.useState<Array<any>>([]);
  const dispatch = useContext(ToastContext);
  const [navItems, setNavItems] = React.useState<string[]>([]);
  const [activeTab, setActiveTab] = React.useState("");

  useEffect(() => {
    getOrgIdAndName();
    async function getOrgIdAndName() {
      setLoading(true);
      try {
        const { data }: any = await callGetApi(
          `organisations?status=get_all_org_id_and_name`
        );
        setLoading(false);
        setOrgIds(data.organisations);
        const set: Set<string> = new Set();
        for (const grants of Object.values(batchPermissions) as any) {
          for (const is_allowed of Object.values(grants.permissions)) {
            if (!is_allowed) continue;
            for (const tabName of batchServiceTabs) {
              if (
                grants.grant.endsWith(
                  tabName.replaceAll(" ", "_").toLowerCase()
                )
              ) {
                set.add(tabName);
              }
            }
          }
        }
        setNavItems(Array.from(set));
        setActiveTab(Array.from(set)[0]);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    const getBatchTypes = async () => {
      const response: any = await callGetApi(
        "/productBatchService/getBatchTypes"
      );
      if (response?.data?.length) {
        setBatchTypesList(response?.data);
      }
    };
    getBatchTypes();
  }, []);

  // to get the list of the task.
  const getTaskData = async () => {
    setLoadingForGetBatch(true);
    setIsfilterTask(true);
    try {
      const response: any = await callGetApi("productBatchService/getTasks");

      if (response?.data?.length) {
        setTaskList(response.data.filter((e: any) => e.status !== "DELETED"));
      }
      setLoadingForGetBatch(false);
      setIsfilterTask(false);
    } catch (err: any) {
      setLoadingForGetBatch(false);
    }
  };

  useEffect(() => {
    getTaskData();
  }, []);

  return (
    <div>
      <div
        className="pt-3 d-flex align-items-center"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        {navItems.map((tab, index) => {
          return (
            <div
              key={index}
              className={sharedStyle.navItemWrapper}
              onClick={() => setActiveTab(tab)}
            >
              <p
                className={`mb-0 px-4 ${
                  activeTab === tab
                    ? sharedStyle.activeNavItem
                    : sharedStyle.navItem
                }`}
                style={
                  index !== batchServiceTabs.length - 1
                    ? { borderRight: `1px solid #ddd` }
                    : { borderRight: 0 }
                }
              >
                {tab}
              </p>
              <div
                className={
                  activeTab === tab
                    ? sharedStyle.activeBorder
                    : sharedStyle.nonActiveBorder
                }
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-3">
        {activeTab === CREATE_TASK && (
          <CreateTask
            loadingData={loading}
            orgIdAndName={orgIds}
            navigateToTrackTask={() => setActiveTab(TRACK_TASK)}
            batchTypesList={batchTypesList}
            getTaskData={getTaskData}
            is_allowed_to_write={
              batchPermissions[CREATE_TASK].permissions[
                `${permissionsType.write}_${batchPermissions[CREATE_TASK].grant}`
              ]
            }
          />
        )}
      </div>
      <div className="pb-2">
        {activeTab === TRACK_TASK && (
          <TrackTask
            loadingData={loading}
            orgIdAndName={orgIds}
            batchTypesList={batchTypesList}
            taskList={taskList}
            setTaskList={setTaskList}
            isfilterTask={isfilterTask}
            setIsfilterTask={setIsfilterTask}
            getTaskData={getTaskData}
            loading={loadingForGetBatch}
            is_allowed_to_write={
              batchPermissions[TRACK_TASK].permissions[
                `${permissionsType.write}_${batchPermissions[TRACK_TASK].grant}`
              ]
            }
          />
        )}
      </div>
    </div>
  );
};

export default BatchService;

export const home = "Home";
export const accounts = "Accounts";
export const plans = "Plans";
export const products = "Products";
export const users = "Users";
export const batchService = "Batch Service";
export const editDocs = "Edit Docs";
export const monitor = "Monitor";
export const batchServiceRoute = "batch-service";
export const editDocsRoute = "edit-docs";

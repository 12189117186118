import React, { useContext, useEffect, useState } from "react";
import { callGetApi, callPutApi } from "../../../../../../api/axios";
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";
import { MEDIUM } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";
import {
  ACCENTCOLOR,
  PRIMARYCOLOR,
  WHITE,
} from "../../../../../../shared/colors";
import InputDropdown from "../../../../../../shared/input-dropdown/inputDropdown";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import RupeeSVG from "../../../../../../shared/svg/rupeeSvg";
import { ERROR } from "../../../../../../utils/toastType";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../../../utils/updateAmount";
import Styles from "./assignPlanModal.module.scss";
import tableStyles from "../../../../plans/plan-details/planDetails.module.scss";
import { deprecatedProducts } from "../../../../../../constants/deprecatedProducts";
import ArrowDown from "../../../../../../shared/svg/arrowDown";
import { isObject } from "../../../../../../utils/validation";
function AssignPlanModal({
  onAssignPlan,
  onCancel,
  id,
  disabled,
  current_planId,
  is_allowed_to_write,
}: any) {
  const [plans, setPlans] = React.useState([]);
  const [selectedPlan, setSelectedPlan] = React.useState({
    id: "",
    name: "",
    type: "",
    validity: "",
    amount: "",
    pricing: [],
    code: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [updatePlanLoading, setUpdatePlanLoading] = React.useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const dispatch = useContext(ToastContext);
  const [addOnsToggle, setAddOnsToggles] = useState({
    productName: "",
    addOn: [],
  });
  const tdsTextToValueMap: any = {
    "2% TDS & 18% GST": "2",
    "10% TDS & 18 % GST": "10",
    "NO TDS & 18% GST": "0",
  };
  const [tdsType] = React.useState("NO TDS & 18% GST");
  useEffect(() => {
    async function getAllPlans() {
      setIsLoading(true);
      try {
        const plans: any = await callGetApi("/plans");
        const listOfPlans = plans.data.map((plan: any) => ({
          id: plan._id,
          name: plan.plan_name,
          validity: plan.plan_validity,
          type: plan.plan_type,
          amount: roundOfDecimal(plan.prepaid_recharge_amount),
          pricing: plan.pricing,
          code: plan.plan_code,
        }));
        setPlans(listOfPlans);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    if (disabled) {
      getPlanById(current_planId);
    } else {
      getAllPlans();
    }
    // eslint-disable-next-line
  }, [dispatch]);

  async function onUpdatePlan() {
    setUpdatePlanLoading(true);
    try {
      await callPutApi(`organisations/${id}?action=org_assign_plan`, {
        planId: selectedPlan.id,
        enable_immediately: true,
        tds: tdsTextToValueMap[tdsType],
      });
      setUpdatePlanLoading(false);
      onAssignPlan();
    } catch (err: any) {
      setUpdatePlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  async function getPlanById(id: string) {
    setPlanLoading(true);
    try {
      const { data }: any = await callGetApi(`/plans/${id}`);
      const filteredProducts = data.pricing.filter(
        (product: any) => !deprecatedProducts.includes(product.productArn)
      );
      setSelectedPlan({
        ...data,
        name: data.plan_name,
        amount: data.prepaid_recharge_amount,
        id: data._id,
        pricing: filteredProducts,
        type: data.plan_type,
        validity: data.plan_validity,
        code: data.plan_code,
      });
      setPlanLoading(false);
    } catch (err: any) {
      setPlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  const loading = (
    <div
      style={{ height: "230px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div
        className={`${popUpStyles.popUpContent} ${
          selectedPlan.name ? Styles.cardLg : Styles.card
        }`}
      >
        {isLoading ? (
          loading
        ) : (
          <div>
            <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                {disabled ? "Assigned Plan" : "Assign Plan"}
              </p>
            </div>
            <div className="p-4 mx-2">
              <InputDropdown
                disabled={disabled}
                labelname="Select a Plan"
                optionsArray={plans.map(
                  (plan: any) => `${plan.name}-${plan.code}`
                )}
                click={(value: any) => {
                  const [planName, planCode] = value.split("-");
                  const plan: any = plans.find(
                    (plan: any) =>
                      plan.name === planName && plan.code === planCode
                  );
                  getPlanById(plan.id);
                }}
                defaultValue={`${
                  selectedPlan.name === ""
                    ? ""
                    : selectedPlan.name + "-" + selectedPlan.code
                }`}
                no_shadow="true"
              />
              {selectedPlan?.name ? (
                <div className="pt-4 pb-2 container-fluid">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-12 py-3">
                          <KeyValuePair
                            label="Plan ID"
                            value={selectedPlan.id}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 py-3">
                          <KeyValuePair
                            label="Plan Name"
                            value={selectedPlan.name}
                          />
                        </div>
                        <div className="col-6 py-3">
                          <KeyValuePair
                            label="Plan Type"
                            value={selectedPlan.type}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 py-3">
                          <KeyValuePair
                            label="Plan Validity"
                            value={`${selectedPlan.validity} months`}
                          />
                        </div>
                        <div className="col-6 py-3">
                          <KeyValuePair
                            label="Amount"
                            value={`${numberWithCommas(
                              selectedPlan.amount
                            )} (excl. taxes)`}
                          >
                            <RupeeSVG
                              width="8"
                              height="12"
                              color={PRIMARYCOLOR}
                            />
                          </KeyValuePair>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <table style={{ width: "100%" }}>
                          <thead className={sharedStyle.tableHeaderBack}>
                            <tr>
                              <th className={tableStyles.cover}>
                                <div className="d-flex align-items-center">
                                  <p
                                    className={`mb-0 ${tableStyles.tableHeaderText}`}
                                  >
                                    Product Name
                                  </p>
                                </div>
                              </th>
                              <th className={tableStyles.least}>
                                <div className="d-flex align-items-center">
                                  <p
                                    className={`mb-0 ${tableStyles.tableHeaderText}`}
                                  >
                                    Price
                                  </p>
                                </div>
                              </th>
                              <th className={tableStyles.least}>
                                <div className="d-flex align-items-center">
                                  <p
                                    className={`mb-0 ${tableStyles.tableHeaderText}`}
                                  >
                                    Add ons
                                  </p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              display: "block",
                              height: "250px",
                              overflowY: "auto",
                            }}
                          >
                            {selectedPlan.pricing.map(
                              (product: any, index: number) => {
                                const product_price =
                                  Object.keys(product.productPricing).length > 0
                                    ? product.productPricing.unit_price
                                    : typeof product.productPricing === "number"
                                    ? product.productPricing
                                    : 0;
                                return (
                                  <>
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #eceeef",
                                      }}
                                      key={index}
                                    >
                                      <td className={tableStyles.cover}>
                                        <p
                                          className={`mb-0 ${tableStyles.tableBodyText}`}
                                        >
                                          {product.productName}
                                        </p>
                                      </td>
                                      <td className={tableStyles.least}>
                                        <p
                                          className={`mb-0 ${tableStyles.tableBodyText}`}
                                        >
                                          {product_price}
                                        </p>
                                      </td>
                                      <td className={tableStyles.least}>
                                        <div
                                          className="d-flex align-items-center"
                                          onClick={() => {
                                            if (
                                              addOnsToggle.productName ===
                                              product.productName
                                            ) {
                                              setAddOnsToggles({
                                                ...addOnsToggle,
                                                productName: "",
                                                addOn: [],
                                              });
                                            } else {
                                              setAddOnsToggles({
                                                ...addOnsToggle,
                                                productName:
                                                  product.productName,
                                                addOn: product.addons,
                                              });
                                            }
                                          }}
                                          style={
                                            product.addons.length > 0
                                              ? { cursor: "pointer" }
                                              : {}
                                          }
                                        >
                                          <p
                                            className={`mb-0 ${tableStyles.tableBodyText}`}
                                          >
                                            View
                                          </p>
                                          {product.addons.length > 0 && (
                                            <div className="pl-2">
                                              {addOnsToggle.productName ===
                                              product.productName ? (
                                                <ArrowDown color={WHITE} />
                                              ) : (
                                                <div
                                                  className={
                                                    tableStyles.rotateArrowRightLongDescription
                                                  }
                                                >
                                                  <ArrowDown />
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    {addOnsToggle.productName ===
                                      product.productName &&
                                      product.addons.length > 0 && (
                                        <tr
                                          style={{
                                            borderBottom: "1px solid #eceeef",
                                          }}
                                        >
                                          <td
                                            colSpan={3}
                                            className={
                                              tableStyles.longDescription
                                            }
                                          >
                                            <table
                                              style={{
                                                width: "100%",
                                                border: "1px solid #eceeef",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <thead
                                                className={
                                                  sharedStyle.tableHeaderBack
                                                }
                                              >
                                                <tr>
                                                  <th
                                                    className={
                                                      tableStyles.product_name
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <p
                                                        className={`mb-0 ${tableStyles.tableHeaderText}`}
                                                      >
                                                        Addon Name
                                                      </p>
                                                    </div>
                                                  </th>
                                                  <th
                                                    className={
                                                      tableStyles.least
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <p
                                                        className={`mb-0 ${tableStyles.tableHeaderText}`}
                                                      >
                                                        Addon Price
                                                      </p>
                                                    </div>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {addOnsToggle.addOn.map(
                                                  (
                                                    addOn: any,
                                                    index: number
                                                  ) => (
                                                    <>
                                                      <tr
                                                        key={
                                                          addOn.addon_key +
                                                          index
                                                        }
                                                        style={{
                                                          borderBottom:
                                                            "1px solid #eceeef",
                                                        }}
                                                      >
                                                        <td
                                                          className={
                                                            tableStyles.product_name
                                                          }
                                                        >
                                                          <p
                                                            className={`mb-0 ${tableStyles.tableBodyText}`}
                                                          >
                                                            {addOn.addon_name}
                                                          </p>
                                                        </td>
                                                        <td
                                                          className={
                                                            tableStyles.least
                                                          }
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            <div className="pr-2">
                                                              <RupeeSVG
                                                                width="8"
                                                                height="12"
                                                                color={
                                                                  PRIMARYCOLOR
                                                                }
                                                              />
                                                            </div>
                                                            <div className="pr-2">
                                                              <p
                                                                className={`mb-0 ${tableStyles.tableBodyText}`}
                                                              >
                                                                {isObject(
                                                                  product
                                                                    .productPricing[
                                                                    addOn
                                                                      .addon_key
                                                                  ]
                                                                )
                                                                  ? product
                                                                      ?.productPricing[
                                                                      String(
                                                                        addOn.addon_key
                                                                      )
                                                                    ]
                                                                      ?.unit_price
                                                                  : product
                                                                      .productPricing[
                                                                      addOn
                                                                        .addon_key
                                                                    ]}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>

                                                      {addOn.sub_addons.length >
                                                      0 ? (
                                                        <tr
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #eceeef",
                                                          }}
                                                        >
                                                          <td
                                                            colSpan={3}
                                                            className={
                                                              Styles.longDescription
                                                            }
                                                          >
                                                            {/* Adons table  */}
                                                            <table
                                                              style={{
                                                                width: "100%",
                                                                border:
                                                                  "1px solid #eceeef",
                                                                borderRadius:
                                                                  "5px",
                                                              }}
                                                            >
                                                              <thead
                                                                className={
                                                                  sharedStyle.tableHeaderBack
                                                                }
                                                              >
                                                                <tr>
                                                                  <th
                                                                    className={
                                                                      tableStyles.product_name
                                                                    }
                                                                  >
                                                                    <div className="d-flex align-items-center">
                                                                      <p
                                                                        className={`mb-0 ${tableStyles.tableHeaderText}`}
                                                                      >
                                                                        Sub
                                                                        Addon
                                                                        Name
                                                                      </p>
                                                                    </div>
                                                                  </th>
                                                                  <th
                                                                    className={
                                                                      tableStyles.least
                                                                    }
                                                                  >
                                                                    <div className="d-flex align-items-center">
                                                                      <p
                                                                        className={`mb-0 ${tableStyles.tableHeaderText}`}
                                                                      >
                                                                        {" "}
                                                                        Addon
                                                                        Price
                                                                      </p>
                                                                    </div>
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {addOn.sub_addons.map(
                                                                  (
                                                                    sub_addon: any,
                                                                    index: number
                                                                  ) => {
                                                                    return (
                                                                      <tr
                                                                        key={
                                                                          sub_addon.sub_addon_key +
                                                                          index
                                                                        }
                                                                        style={{
                                                                          borderBottom:
                                                                            "1px solid #eceeef",
                                                                        }}
                                                                      >
                                                                        <td
                                                                          className={
                                                                            tableStyles.product_name
                                                                          }
                                                                        >
                                                                          <p
                                                                            className={`mb-0 ${tableStyles.tableBodyText}`}
                                                                          >
                                                                            {
                                                                              sub_addon.sub_addon_name
                                                                            }
                                                                          </p>
                                                                        </td>
                                                                        <td
                                                                          className={
                                                                            tableStyles.least
                                                                          }
                                                                        >
                                                                          <>
                                                                            <div className="d-flex align-items-center">
                                                                              <div className="pr-2">
                                                                                <RupeeSVG
                                                                                  width="8"
                                                                                  height="12"
                                                                                  color={
                                                                                    PRIMARYCOLOR
                                                                                  }
                                                                                />
                                                                              </div>
                                                                              <div className="pr-2">
                                                                                <p
                                                                                  className={`mb-0 ${Styles.tableBodyText}`}
                                                                                >
                                                                                  {
                                                                                    product
                                                                                      ?.productPricing[
                                                                                      String(
                                                                                        addOn?.addon_key
                                                                                      )
                                                                                    ][
                                                                                      sub_addon
                                                                                        .sub_addon_key
                                                                                    ]
                                                                                  }
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          </>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      )}
                                  </>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : planLoading ? (
                loading
              ) : (
                <div className="py-5 d-flex align-items-center justify-content-center">
                  <h5 className="mb-0">Please select a plan</h5>
                </div>
              )}
            </div>
            <div
              className="py-4 my-2 d-flex align-items-center justify-content-center"
              style={{ borderTop: "1px solid #ECEEEF" }}
            >
              <div className="px-2">
                <Button
                  size={MEDIUM}
                  style={cancelButtonStyle}
                  hoveredStyle={cancelButtonHoverStyle}
                  onClick={() => onCancel()}
                >
                  {disabled ? "Close" : "Cancel"}
                </Button>
              </div>
              {!disabled && is_allowed_to_write ? (
                <div className="px-2">
                  <Button
                    size={MEDIUM}
                    disabled={selectedPlan.name === ""}
                    style={primaryButtonStyle}
                    isLoading={updatePlanLoading}
                    hoveredStyle={primaryButtonHoverStyle}
                    onClick={() => onUpdatePlan()}
                  >
                    Assign Plan
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AssignPlanModal;

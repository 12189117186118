export const ACCOUNT_DETAILS = "Account Details";
export const KYC_DETAILS = "KYC Details";
export const BILLING = "Billing";
export const PRODUCTS = "Products";
export const ACTIVITIES = "Activities";

export const accountNavTabs = [
  ACCOUNT_DETAILS,
  KYC_DETAILS,
  BILLING,
  PRODUCTS,
  ACTIVITIES,
];

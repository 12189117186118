import React from "react";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../../../shared/colors";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import RupeeSVG from "../../../../../../shared/svg/rupeeSvg";
import { monthName } from "../../../../../../utils/getMonth";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../../../utils/updateAmount";
import Styles from "../billing.module.scss";

function RequestedPlan({
  requested_plan = {},
  isLoading,
  wallet_balance,
}: any) {
  const start_date = new Date(Number(requested_plan.createdAt));
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Requested Plan</p>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{ height: "250px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className="p-4">
          {requested_plan ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Order ID"
                    value={requested_plan.order_id ?? "NA"}
                  />
                </div>
                <div className="col-md-6">
                  <div className="py-4">
                    <p className={`mb-0 ${Styles.key}`}>Plan Name</p>
                    <div className="d-flex align-items-center">
                      <p className={`mb-0 ${Styles.value}`}>
                        {requested_plan.plan_name}
                      </p>
                      {/* <p
                        className={`mb-0 px-2 ${Styles.rateCard}`}
                        style={{ fontSize: "12px" }}
                      >
                        View Rate Card
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Plan Validity"
                    value={
                      requested_plan.duration
                        ? `${requested_plan.duration} months`
                        : "NA"
                    }
                  />
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Plan Value"
                    value={
                      numberWithCommas(
                        roundOfDecimal(requested_plan.plan_amount)
                      ) ?? "NA"
                    }
                  >
                    <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
                  </KeyValuePair>
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Requested On"
                    value={
                      start_date
                        ? `${monthName(
                            start_date.getMonth()
                          )} ${start_date.getDate()}, ${start_date.getFullYear()}`
                        : "NA"
                    }
                  />
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Wallet Balance"
                    value={numberWithCommas(roundOfDecimal(wallet_balance))}
                  >
                    <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
                  </KeyValuePair>
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Activation Request"
                    value={
                      requested_plan.enable_immediately
                        ? "On Payment"
                        : "On Plan Expiry"
                    }
                  />
                </div>
                <div className="col-md-6">
                  <KeyValuePair
                    className="py-4"
                    label="Payment Status"
                    value={requested_plan.status}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ height: "250px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <h5 className="mb-0">No Plan subscribed yet</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RequestedPlan;

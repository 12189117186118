const customStylesForDays = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "80px",
    border: "1px solid black",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForDaysError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "80px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForMonths = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "88px",
    border: "1px solid black",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForMonthsError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "88px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForYears = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "116px",
    border: "1px solid black",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForYearsError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "48px",
    width: "116px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export default {
  customStylesForMonths,
  customStylesForYears,
  customStylesForDays,
  customStylesForMonthsError,
  customStylesForYearsError,
  customStylesForDaysError,
};

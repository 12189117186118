import validator from "validator";

export const numberRegexp = /\d/;
export const specialCharRegexp = /[!@#$%^&*(),.?":{}|<>]/;
export const alphaNumericRegexp = /^[a-zA-Z0-9 ]+$/;
export const alphabetRegExp = /^[a-zA-Z ]+$/;

// Gstin regexp link https://stackoverflow.com/a/45095795
export const GSTINRegexp =
  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

export function isValidEmail(email: string) {
  return validator.isEmail(email);
}
export function isValidEmailFirstChar(email: string) {
  return numberRegexp.test(email[0]);
}
export function isValidGSTIN(GSTINNumber: string): boolean {
  return GSTINRegexp.test(GSTINNumber);
}
export function isValidPhoneNumber(phoneNumber: string): boolean {
  return validator.isMobilePhone(phoneNumber, "en-IN");
}
export function checkIsEmpty(validationString: string): boolean {
  return validator.isEmpty(validationString, { ignore_whitespace: true });
}
export function isValidName(
  name: string,
  includeNumericChar: boolean
): boolean {
  if (includeNumericChar) {
    return !alphaNumericRegexp.test(name) || specialCharRegexp.test(name);
  }
  return !alphabetRegExp.test(name) || specialCharRegexp.test(name);
}
export function isNumeric(value: string): boolean {
  return validator.isNumeric(value, { no_symbols: true });
}

export function hasWhiteSpace(s: string) {
  return /\s/g.test(s);
}

export function isObject(object: any) {
  return typeof object === "object" && object !== null;
}

import React from "react";
import sharedStyle from "../sharedStyle.module.scss";

function UpdateSvg({
  wrapper_width = "35px",
  wrapper_height = "35px",
  svg_width = "19.579",
  svg_height = "19.49",
  click,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${sharedStyle.svgWrapper}`}
      style={{ width: wrapper_width, height: wrapper_height }}
      onClick={click}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={svg_width}
        height={svg_height}
        viewBox="0 0 19.579 19.49"
        className={sharedStyle.updateSvgColor}
      >
        <path
          d="M102.9 86.651a4.26 4.26 0 0 0-6.03 0L85.6 97.918a.831.831 0 0 0-.228.457l-.8 5.6a.8.8 0 0 0 .794.914.554.554 0 0 0 .1-.006l5.693-.708a.809.809 0 0 0 .468-.228L102.9 92.675a4.264 4.264 0 0 0 0-6.024zm-12.212 15.966l-4.386.543.617-4.3 9.64-9.639 3.763 3.763zm11.084-11.067l-.314.314-3.763-3.764.314-.314A2.585 2.585 0 0 1 99.888 87a2.665 2.665 0 0 1 1.884 4.551z"
          transform="translate(-84.564 -85.4)"
        />
      </svg>
    </div>
  );
}

export default UpdateSvg;

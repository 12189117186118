import React from "react";
import { ACCENTCOLOR, WHITE } from "../colors";
import Styles from "./filterDropdown.module.scss";
import SearchImage from "../../assets/images/search.png";
import Checkbox from "../checkbox/checkbox";
import Loading from "../loading/loading";

function FilterDropdown({
  width = "300px",
  height = "300px",
  toggleReset,
  optionArray,
  filters,
  onSelect,
  onReset,
  loading,
  ...props
}: any) {
  const [lookup, setLookup] = React.useState("");
  const filteredOption = optionArray.filter((option: any) => {
    return option.toLowerCase().includes(lookup.toLowerCase());
  });

  return (
    <div className="dropdown">
      <div
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.children}
      </div>
      <div
        className={"dropdown-menu dropdown-menu-right"}
        style={{
          width: width,
          borderRadius: "8px",
          padding: "10px",
          backgroundColor: WHITE,
          border: 0,
          boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
          zIndex: 1,
        }}
        aria-labelledby="dropdownMenuButton"
      >
        {
          <div
            className={`${Styles.inputWrapper} d-flex align-items-center pl-2`}
          >
            <div className="px-2">
              <img
                src={SearchImage}
                alt="search"
                width="15px"
                style={{ marginBottom: "3px" }}
              />
            </div>
            <div className="flex-grow-1">
              <input
                type="text"
                className={Styles.formControl}
                placeholder="Search"
                value={lookup}
                onChange={(event: any) => {
                  setLookup(event.target.value);
                }}
              />
            </div>
          </div>
        }
        <div style={{ maxHeight: height, height: "100%", overflow: "auto" }}>
          {loading ? (
            <div
              style={{ height: "200px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : filteredOption.length > 0 ? (
            filteredOption.map((option: any, index: number) => {
              return (
                <div
                  key={`${option}-${index}`}
                  className={`${
                    filters.includes(option)
                      ? Styles.active
                      : Styles.dropdownLink
                  } d-flex align-items-center`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setLookup("");
                    onSelect(option);
                  }}
                >
                  <Checkbox
                    id={`id${index}`}
                    checked={filters.includes(option)}
                    onChange={() => {
                      setLookup("");
                      onSelect(option);
                    }}
                  />
                  <p className="mb-0 pl-4 ml-2">{option}</p>
                </div>
              );
            })
          ) : (
            <div
              style={{ height: "200px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <h5 className="mb-0">No option found</h5>
            </div>
          )}
        </div>
        {toggleReset && (
          <div className="py-2">
            <div className="d-flex align-items-center justify-content-center">
              <p className={`mb-0 ${Styles.reset}`} onClick={() => onReset()}>
                Reset
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterDropdown;

import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "../../components/application/accountListing/redux-slice/accountFilterData";

export const store = configureStore({
  reducer: {
    data: dataReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

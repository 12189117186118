import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { callPutApi } from "../../../../../api/axios";
import { orgStatus } from "../../../../../constants/orgStatus";
import { ToastContext } from "../../../../../context/toastContext";
import Button from "../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../shared/buttonSize";
import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../shared/buttonStyles";

import sharedStyle from "../../../../../shared/sharedStyle.module.scss";
import { ERROR } from "../../../../../utils/toastType";
import RejectReasonModal from "../products/reject-reason-modal/rejectReasonModal";
import Styles from "./activities.module.scss";

import {
  activitiesNavTabs,
  TRANSACTION_STATS,
  ACTIVITY_LOGS,
  IP_WHITELISTING,
} from "../../../../../constants/activitiesNavTabs";

import TransactionStats from "./transaction-stats/transactionStats";
import ActivityLogs from "./activity-logs/activityLogs";
import IpWhitelisting from "./ip-whitelisting/ipWhitelisting";

function Activities({
  org_status,
  onUpdateStatus,
  onboarding_status,
  is_allowed_to_delete,
}: any) {
  const { id }: any = useParams();
  const [activeTab, setActiveTab] = React.useState(TRANSACTION_STATS);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [
    toggleDisableAccountConfirmation,
    setToggleDisableAccountConfirmation,
  ] = React.useState(false);
  const dispatch = useContext(ToastContext);

  async function onDisableOrg({ trimmedReason, disableButtonLoading }: any) {
    try {
      const { data }: any = await callPutApi(
        `/organisations/${id}?action=org_disable_account`,
        { reason_message: trimmedReason }
      );
      setToggleDisableAccountConfirmation(false);
      onUpdateStatus(data.status);
    } catch (err: any) {
      disableButtonLoading();
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  async function onEnableOrg() {
    setButtonLoading(true);
    try {
      const { data }: any = await callPutApi(
        `/organisations/${id}?action=org_enable_account`,
        {}
      );
      setButtonLoading(false);
      onUpdateStatus(data.status);
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  return (
    <div>
      {toggleDisableAccountConfirmation && (
        <RejectReasonModal
          title="Disable A Account"
          btnName="Disable"
          onCancel={() => setToggleDisableAccountConfirmation(false)}
          onReject={(data: Object) => {
            onDisableOrg(data);
          }}
        />
      )}
      <div className="d-flex justify-content-end py-2 px-3">
        {org_status === orgStatus.ENABLED ||
        org_status === orgStatus.PENDING ? (
          <div className="d-inline-flw">
            <Button
              size={COMPRESSED}
              style={dangerButtonStyle}
              hoveredStyle={dangerButtonHoverStyle}
              disabled={!is_allowed_to_delete}
              onClick={() => setToggleDisableAccountConfirmation(true)}
            >
              Disable Account
            </Button>
          </div>
        ) : null}
        {org_status === orgStatus.DISABLED ? (
          <div className="d-inline-flw">
            <Button
              size={COMPRESSED}
              style={successButtonStyle}
              hoveredStyle={successButtonHoverStyle}
              isLoading={buttonLoading}
              disabled={buttonLoading || !is_allowed_to_delete}
              onClick={() => onEnableOrg()}
            >
              Enable Account
            </Button>
          </div>
        ) : null}
      </div>

      <div
        className="d-flex align-items-center"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        {activitiesNavTabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={Styles.navItemWrapper}
              onClick={() => setActiveTab(tab)}
            >
              <p
                className={`mb-0 px-4 ${
                  activeTab === tab ? Styles.activeNavItem : Styles.navItem
                }`}
                style={
                  index !== activitiesNavTabs.length - 1
                    ? { borderRight: `1px solid #ddd`, fontSize: "12px" }
                    : { borderRight: 0, fontSize: "12px" }
                }
              >
                {tab}
              </p>
              <div
                className={
                  activeTab === tab
                    ? Styles.activeBorder
                    : Styles.nonActiveBorder
                }
              ></div>
            </div>
          );
        })}
      </div>

      {/* conditional rendering navtabs*/}
      <div className="py-3 ">
        {activeTab === TRANSACTION_STATS && (
          <div className={`${sharedStyle.card}`}>
            <TransactionStats onboarding_status={onboarding_status} />
          </div>
        )}
        {activeTab === ACTIVITY_LOGS && (
          <div className={`${sharedStyle.card}`}>
            <ActivityLogs />
          </div>
        )}
        {activeTab === IP_WHITELISTING && (
          <div className={`${sharedStyle.card}`}>
            <IpWhitelisting />
          </div>
        )}
      </div>
      {/*over*/}
    </div>
  );
}

export default Activities;

import React, { useContext } from "react";
import { callPutApi } from "../../../../../../api/axios";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import Input from "../../../../../../shared/input/input";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";
import { publicEmail } from "../../../../../../utils/publicEmail";
import { ERROR } from "../../../../../../utils/toastType";
import {
  checkIsEmpty,
  isValidName,
  isValidEmail,
  isValidEmailFirstChar,
  isValidPhoneNumber,
  isNumeric,
} from "../../../../../../utils/validation";

function PocDetails({
  pocType,
  pocInfo = {},
  isLoading,
  accountInfo = {},
  apiUrl,
  onUpdatePocInfo,
  is_allowed_to_write,
}: any) {
  const [tempPocInfo, setTempPocInfo] = React.useState(pocInfo);
  const [toggleUpdatePOC, setToggleUpdatePOC] = React.useState(false);
  const [pocLoading, setPocLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const [error, setError] = React.useState({
    name: "",
    email: "",
    designation: "",
    phone: "",
  });
  const [validData, setValidData] = React.useState({
    name: false,
    email: false,
    designation: false,
    phone: false,
  });
  const loading = (
    <div
      style={{ height: "200px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  function checkPOCName() {
    if (checkIsEmpty(tempPocInfo?.name)) {
      setError((error) => ({
        ...error,
        name: "Name cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        name: false,
      }));
      return false;
    }
    if (isValidName(tempPocInfo?.name, false)) {
      setError((error) => ({
        ...error,
        name: "Name should contain alphabet only!",
      }));
      setValidData((validData) => ({
        ...validData,
        name: false,
      }));
      return false;
    }
    return true;
  }
  function checkPOCEmail() {
    if (checkIsEmpty(tempPocInfo?.email)) {
      setError((error) => ({
        ...error,
        email: "Email cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    if (!isValidEmail(tempPocInfo?.email)) {
      setError((error) => ({
        ...error,
        email: "Enter a valid email",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    if (isValidEmailFirstChar(tempPocInfo?.email)) {
      setError((error) => ({
        ...error,
        email: "Email cannot state with number",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [_, domain] = tempPocInfo?.email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setError((error) => ({
        ...error,
        email: "Only work emails are allowed",
      }));
      return false;
    }
    return true;
  }
  function checkPOCDesignation() {
    if (checkIsEmpty(tempPocInfo?.designation)) {
      setError((error) => ({
        ...error,
        designation: "Designation cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        designation: false,
      }));
      return false;
    }
    if (isValidName(tempPocInfo?.designation, false)) {
      setError((error) => ({
        ...error,
        designation: "Designation should contain alphabet only!",
      }));
      setValidData((validData) => ({
        ...validData,
        designation: false,
      }));
      return false;
    }
    return true;
  }
  function checkPOCPhoneNumber() {
    if (accountInfo.country_code !== "IN") {
      if (!isNumeric(tempPocInfo?.phone) && tempPocInfo?.phone !== "") {
        setError((error) => ({
          ...error,
          phone: "Enter a valid Phone number",
        }));
        setValidData((validData) => ({
          ...validData,
          phone: false,
        }));
        return false;
      }
      return true;
    }
    if (checkIsEmpty(tempPocInfo?.phone)) {
      setError((error) => ({
        ...error,
        phone: "Phone number cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    if (!isValidPhoneNumber(tempPocInfo?.phone)) {
      setError((error) => ({
        ...error,
        phone: "Enter a valid Phone number",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    if (tempPocInfo?.phone[0] === "0") {
      setError((error) => ({
        ...error,
        phone: "Exclude 0 from beginning",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    if (tempPocInfo?.phone.length > 10) {
      setError((error) => ({
        ...error,
        phone: "Phone number should be of 10 digit only!",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    return true;
  }
  async function updatePocInfo() {
    const allCheckPasses = [
      checkPOCName(),
      checkPOCEmail(),
      checkPOCDesignation(),
      checkPOCPhoneNumber(),
    ].every(Boolean);
    if (!allCheckPasses) {
      return;
    }
    setPocLoading(true);
    try {
      const response: any = await callPutApi(`${apiUrl}`, tempPocInfo);
      setPocLoading(false);
      setToggleUpdatePOC(false);
      if (pocType === "Business") {
        onUpdatePocInfo(response.data.business_poc);
        return;
      }
      onUpdatePocInfo(response.data.technical_poc);
      return;
    } catch (err: any) {
      setPocLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>{pocType} POC</p>
          <div className="ml-auto">
            {isLoading ? (
              <div>--</div>
            ) : is_allowed_to_write ? (
              toggleUpdatePOC ? (
                <div className="d-flex align-items-center">
                  <div className="px-2">
                    <Button
                      size={COMPRESSED}
                      style={cancelButtonStyle}
                      disabled={pocLoading}
                      hoveredStyle={cancelButtonHoverStyle}
                      onClick={() => {
                        setTempPocInfo(pocInfo);
                        setToggleUpdatePOC(false);
                        setError({
                          name: "",
                          email: "",
                          designation: "",
                          phone: "",
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-2">
                    <Button
                      size={COMPRESSED}
                      style={successButtonStyle}
                      disabled={pocLoading}
                      hoveredStyle={successButtonHoverStyle}
                      onClick={() => updatePocInfo()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : accountInfo?.status === orgStatus.DISABLED ? null : (
                <UpdateSvg
                  click={() => {
                    setTempPocInfo(pocInfo);
                    setToggleUpdatePOC(true);
                  }}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      {isLoading ? (
        loading
      ) : (
        <div className="p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                {toggleUpdatePOC ? (
                  <div>
                    <Input
                      no_shadow="true"
                      type="text"
                      name="business_poc_name"
                      id="business_poc_name"
                      autoComplete="off"
                      maxLength={40}
                      value={tempPocInfo.name}
                      hasError={error.name}
                      isValid={validData.name}
                      placeholder="Enter Business POC Name"
                      labelname="Name"
                      onChange={(event: any) => {
                        setTempPocInfo((tempPocInfo: any) => ({
                          ...tempPocInfo,
                          name: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          name: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          name: true,
                        }));
                      }}
                      onBlur={checkPOCName}
                    />
                    {error.name && <ErrorMessage>{error.name}</ErrorMessage>}
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-3"
                    label="Name"
                    value={pocInfo.name ?? "NA"}
                  />
                )}
              </div>
              <div className="col-md-6">
                {toggleUpdatePOC ? (
                  <div>
                    <Input
                      no_shadow="true"
                      type="text"
                      name="business_poc_email"
                      id="business_poc_email"
                      autoComplete="off"
                      value={tempPocInfo.email}
                      hasError={error.email}
                      isValid={validData.email}
                      placeholder="Enter Business POC Email"
                      labelname="Email"
                      onChange={(event: any) => {
                        setTempPocInfo((tempPocInfo: any) => ({
                          ...tempPocInfo,
                          email: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          email: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          email: true,
                        }));
                      }}
                      onBlur={checkPOCEmail}
                    />
                    {error.email && <ErrorMessage>{error.email}</ErrorMessage>}
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-3"
                    label="Email"
                    value={pocInfo.email ?? "NA"}
                  />
                )}
              </div>
              <div className="col-md-6">
                {toggleUpdatePOC ? (
                  <div>
                    <Input
                      no_shadow="true"
                      type="text"
                      name="business_poc_designation"
                      id="business_poc_designation"
                      autoComplete="off"
                      maxLength={25}
                      value={tempPocInfo.designation}
                      hasError={error.designation}
                      isValid={validData.designation}
                      placeholder="Enter Business POC Designation"
                      labelname="Designation"
                      onChange={(event: any) => {
                        setTempPocInfo((tempPocInfo: any) => ({
                          ...tempPocInfo,
                          designation: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          designation: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          designation: true,
                        }));
                      }}
                      onBlur={checkPOCDesignation}
                    />
                    {error.designation && (
                      <ErrorMessage>{error.designation}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-3"
                    label="Designation"
                    value={pocInfo.designation ?? "NA"}
                  />
                )}
              </div>
              <div className="col-md-6">
                {toggleUpdatePOC ? (
                  <div>
                    <Input
                      no_shadow="true"
                      type="text"
                      name="business_poc_phone"
                      id="business_poc_phone"
                      autoComplete="off"
                      value={tempPocInfo.phone}
                      hasError={error.phone}
                      isValid={validData.phone}
                      placeholder="Enter Business POC Phone"
                      labelname="Phone"
                      maxLength={10}
                      onChange={(event: any) => {
                        setTempPocInfo((tempPocInfo: any) => ({
                          ...tempPocInfo,
                          phone: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          phone: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          phone: true,
                        }));
                      }}
                      onBlur={checkPOCPhoneNumber}
                    />
                    {error.phone && <ErrorMessage>{error.phone}</ErrorMessage>}
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-3"
                    label="Phone"
                    value={pocInfo.phone ?? "NA"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PocDetails;

import {
  SUCCESS,
  DANGER,
  WHITE,
  ACCENTCOLOR,
  SECONDARYCOLOR,
  ACCENTDARKCOLOR,
  LINKHOVERBACKGROUND,
  SUCCESSDARKCOLOR,
  DANGERDARKCOLOR,
  BLUEBACKGROUNDCOLOR,
  GREYCOLOR,
} from "./colors";

export const primaryButtonStyle = {
  borderRadius: "8px",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const primaryButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};

export const primaryButtonModernStyle = {
  borderRadius: "8px",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const primaryButtonHoverModernStyle = {
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const borderButtonStyle = {
  borderRadius: "8px",
  buttonTextColor: ACCENTCOLOR,
  background: "transparent",
  border: `2px solid ${ACCENTCOLOR}`,
  boxShadow: "none",
};

export const noBorderButtonStyle = {
  borderRadius: "8px",
  buttonTextColor: ACCENTCOLOR,
  background: "transparent",
  border: `2px solid ${WHITE}`,
  boxShadow: "none",
};
export const borderButtonHoverStyle = {
  borderRadius: "8px",
  buttonTextColor: ACCENTCOLOR,
  background: LINKHOVERBACKGROUND,
  border: `2px solid ${ACCENTCOLOR}`,
  boxShadow: "none",
};
export const cancelButtonStyle = {
  borderRadius: "8px",
  background: "transparent",
  border: `solid 1px ${SECONDARYCOLOR}`,
  buttonTextColor: SECONDARYCOLOR,
  boxShadow: "none",
};
export const cancelButtonHoverStyle = {
  borderRadius: "8px",
  background: "#fcfcfc",
  border: `solid 1px ${SECONDARYCOLOR}`,
  buttonTextColor: WHITE,
  boxShadow: "none",
};
export const cancelButtonModernStyle = {
  border: "none",
  buttonTextColor: ACCENTCOLOR,
  boxShadow: "none",
  background: "none",
};
export const cancelButtonModernHoverStyle = {
  border: "none",
  background: "none",
  buttonTextColor: ACCENTCOLOR,
  boxShadow: "none",
};
export const successButtonStyle = {
  borderRadius: "8px",
  backgroundColor: SUCCESS,
  buttonTextColor: WHITE,
  border: 0,
};
export const successButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: SUCCESSDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const blueHoverButtonStyle = {
  borderRadius: "8px",
  backgroundColor: BLUEBACKGROUNDCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const greyButtonStyle = {
  borderRadius: "8px",
  backgroundColor: GREYCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};

export const dangerButtonStyle = {
  borderRadius: "8px",
  backgroundColor: DANGER,
  buttonTextColor: WHITE,
  border: 0,
};
export const dangerButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: DANGERDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};

import React, { useContext } from "react";
import { callPutApi } from "../../../../../../api/axios";
import { ERROR } from "../../../../../../utils/toastType";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import { PRIMARYCOLOR } from "../../../../../../shared/colors";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  dangerButtonHoverStyle,
  dangerButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { ToastContext } from "../../../../../../context/toastContext";

function DisableProductAuthentication({
  id,
  selectedProductIds,
  onDisableProduct,
  onCancel,
}: any) {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  async function disableProduct() {
    setButtonLoading(true);
    try {
      await callPutApi(
        `/organisations/${id}?action=disable_product_subscription`,
        {
          productIds: selectedProductIds,
          orgId: id,
        }
      );
      setButtonLoading(false);
      onDisableProduct();
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "450px" }}>
        <div className="p-4">
          <h5
            className="mb-0 py-2 text-center"
            style={{ fontSize: "14px", color: PRIMARYCOLOR }}
          >
            Are you sure you want to Disable this{" "}
            {selectedProductIds.length > 1 ? "products" : "product"}
          </h5>
        </div>
        <div
          className="py-3 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={COMPRESSED}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={COMPRESSED}
              style={dangerButtonStyle}
              hoveredStyle={dangerButtonHoverStyle}
              isLoading={buttonLoading}
              onClick={disableProduct}
            >
              Disable
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisableProductAuthentication;

import React, { useContext } from "react";
import Styles from "../addPaymentModal.module.scss";
import {
  PRIMARYCOLOR,
  SECONDARYCOLOR,
} from "../../../../../../../shared/colors";
import KeyValuePair from "../../../../../../../shared/key-value-pair/keyValuePair";
import ArrowDown from "../../../../../../../shared/svg/arrowDown";
import RupeeSVG from "../../../../../../../shared/svg/rupeeSvg";
import { MEDIUM } from "../../../../../../../shared/buttonSize";
import Button from "../../../../../../../shared/button/button";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../../shared/buttonStyles";
import { callPutApi } from "../../../../../../../api/axios";
import { ToastContext } from "../../../../../../../context/toastContext";
import { ERROR } from "../../../../../../../utils/toastType";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../../../../utils/updateAmount";

function AccountWallet({
  back,
  onCancel,
  onUpdateAccountWallet,
  planPaymentPending,
  id,
}: any) {
  const dispatch = useContext(ToastContext);
  const [loading, setLoading] = React.useState(false);
  async function handleAccountWallet() {
    setLoading(true);
    try {
      await callPutApi(`organisations/${id}?action=add_custom_payment`, {
        payment_type: "WALLET_ADJUSTMENT",
      });
      setLoading(false);
      onUpdateAccountWallet();
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  return (
    <div>
      <div className="d-inline-flex py-2">
        <div
          className="d-flex align-items-center"
          onClick={() => back()}
          style={{ cursor: "pointer" }}
        >
          <div className={`pr-1 ${Styles.rotate}`}>
            <ArrowDown color={SECONDARYCOLOR} />
          </div>
          <p className={`mb-0 ${Styles.back}`}>back</p>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-12 py-3">
            <KeyValuePair
              label="Order Id"
              value={planPaymentPending.order_id}
            />
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair label="Plan Id" value={planPaymentPending.plan_id} />
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair
              label="Plan Name"
              value={planPaymentPending.plan_name}
            />
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair label="Type" value="Prepaid" />
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair
              label="Validity"
              value={`${planPaymentPending.validity} Months`}
            />
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair
              label="Amount (incl. taxes)"
              value={numberWithCommas(
                roundOfDecimal(planPaymentPending.amount)
              )}
            >
              <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
            </KeyValuePair>
          </div>
          <div className="col-sm-6 py-3">
            <KeyValuePair
              label="Total Wallet Balance"
              value={
                planPaymentPending.wallet_balance -
                  planPaymentPending.wallet_amount >
                0
                  ? numberWithCommas(
                      roundOfDecimal(
                        planPaymentPending.wallet_balance -
                          planPaymentPending.wallet_amount
                      )
                    )
                  : "Not enough balance"
              }
              helperText={
                planPaymentPending.wallet_balance -
                  planPaymentPending.wallet_amount >
                0
                  ? "Amount after tax deduction"
                  : ""
              }
            >
              {planPaymentPending.wallet_balance -
                planPaymentPending.wallet_amount >
              0 ? (
                <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
              ) : (
                ""
              )}
            </KeyValuePair>
          </div>
        </div>
        {/* Footer Section  */}
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={MEDIUM}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={MEDIUM}
              isLoading={loading}
              disabled={
                planPaymentPending.wallet_balance -
                  (planPaymentPending.amount - planPaymentPending.tax) <
                0
              }
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => handleAccountWallet()}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountWallet;

import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { callGetApi } from "../../../../../../api/axios";
import { ToastContext } from "../../../../../../context/toastContext";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import Loading from "../../../../../../shared/loading/loading";
import { ERROR } from "../../../../../../utils/toastType";
import sharedStyle from "./../../../../../../shared/sharedStyle.module.scss";
import Styles from "./ipWhitelisting.module.scss";

function IpWhitelisting() {
  const [listOfApps, setListOfApps] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { id }: any = useParams();
  const dispatch = useContext(ToastContext);
  useEffect(() => {
    setIsLoading(true);
    async function getAllApps() {
      try {
        const response: any = await callGetApi(
          `/organisations/${id}?data=whitelisted_ips`
        );
        const list = response.data.map((app: any) => ({
          appId: app.appId,
          appName: app.appName,
          whitelisted_ips: app.whitelisted_ips,
        }));
        setIsLoading(false);
        setListOfApps(list);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    getAllApps();
  }, [id, dispatch]);

  const emptyState = (
    <div
      style={{ height: "300px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Apps Found</h5>
    </div>
  );

  const loading = (
    <div
      style={{ height: "300px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  return (
    <div className={`${sharedStyle.card}`}>
      <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center pb-3">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>IP whitelisting</p>
        </div>
      </div>
      {isLoading ? (
        loading
      ) : listOfApps.length > 0 ? (
        <div className={Styles.table_wrapper}>
          <table style={{ width: "100%" }}>
            <thead className={sharedStyle.tableHeaderBack}>
              <tr>
                <th className={Styles.app_id}>
                  <p className={`mb-0 ${Styles.tableHeaderText}`}>App Id</p>
                </th>
                <th className={Styles.app_column}>
                  <p className={`mb-0 ${Styles.tableHeaderText}`}>App Name</p>
                </th>
                <th className={Styles.ip_whitelisting_column}>
                  <p className={`mb-0 ${Styles.tableHeaderText}`}>IP Address</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {listOfApps.map((app: any) => {
                return (
                  <tr
                    key={app.appId}
                    style={{ borderBottom: "1px solid #eceeef" }}
                  >
                    <td className={Styles.app_id}>
                      <p className={`mb-0 ${Styles.tableBodyText}`}>
                        {app?.appId}
                      </p>
                    </td>
                    <td className={Styles.app_column}>
                      <p className={`mb-0 ${Styles.tableBodyText}`}>
                        {app?.appName}
                      </p>
                    </td>
                    <td className={Styles.ip_whitelisting_column}>
                      <div className="d-flex align-items-center flex-wrap">
                        {app?.whitelisted_ips.length > 0 ? (
                          app?.whitelisted_ips.map(
                            (ip: string, index: number) => {
                              return (
                                <div className="p-1" key={index}>
                                  <div
                                    className={`px-2 ${Styles.filterWrapper}`}
                                  >
                                    <p className={`mb-0 ${Styles.filterName}`}>
                                      {ip}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <p className={`mb-0 p-1 ${Styles.tableBodyText}`}>
                            NA
                          </p>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        emptyState
      )}
    </div>
  );
}

export default IpWhitelisting;

import React, { useContext, useEffect } from "react";
import validator from "validator";
import Button from "../../../../shared/button/button";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import Input from "../../../../shared/input/input";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../shared/buttonStyles";
import { COMPRESSED } from "../../../../shared/buttonSize";
import { isValidName } from "../../../../utils/validation";
import { ToastContext } from "../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import { callPostApi } from "../../../../api/axios";
import { ReactComponent as CrossIcon } from "../../../../assets/images/cross.svg";
import { ReactComponent as InfoSvg } from "../../../../assets/images/info.svg";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../shared/colors";

interface State {
  batchName?: string;
  TPM?: number | any;
  batchType?: string | undefined;
  batchFile?: any;
  response_type?: any;
}

interface Error {
  TPM?: any;
  batchName?: string;
  batchType?: string;
  batchFile?: string;
  orgName?: string;
  orgId?: string;
  responseError?: string;
}

interface Props {
  navigateToTrackTask: Function;
  loadingData: boolean;
  batchTypesList: any;
  orgIdAndName: any;
  getTaskData: Function;
  is_allowed_to_write: boolean;
}

const CreateTask = ({
  navigateToTrackTask,
  loadingData,
  orgIdAndName,
  batchTypesList,
  getTaskData,
  is_allowed_to_write,
}: Props) => {
  const dispatch = useContext(ToastContext);

  const [state, setState] = React.useState<State>({
    TPM: "20",
  });
  const [error, setError] = React.useState<Error>({ batchName: "" });
  const ResponseCategory = ["Insurance", "Finance"];
  const [loading, setLoading] = React.useState<Boolean>(false);

  const restrictedProduct: any = [
    "IN_VEHICLE_RC_ADVANCE",
    "IN_VEHICLE_RC_REVERSE",
  ];

  const uniqueProduct: any = ["IN_IDENTITY_PAN_MICRO"];
  const PanMicroResponseCategory = ["NONE", "PAN_DETAILS"];

  const [value, setValue] = React.useState<any>(null);

  const downloadSampleLink = batchTypesList.length
    ? batchTypesList.filter((item: any) => item?.value === state?.batchType)[0]
        ?.sample_link
    : "";

  // To validate the batch name
  const checkBatchName = () => {
    if (!state?.batchName?.length) {
      setError((error) => ({
        ...error,
        batchName: "Batch name cannot be empty",
      }));
      return false;
    }
    if (isValidName(state?.batchName, true)) {
      setError((error) => ({
        ...error,
        batchName: "Enter a valid name",
      }));
      return false;
    }
    if (validator.isNumeric(state?.batchName[0], { no_symbols: true })) {
      setError((error) => ({
        ...error,
        batchName: "Batch name should not start with a number",
      }));
      return false;
    }
    return true;
  };

  // To validate the Concurrent Req
  function checkConcurrentReq() {
    if (!state?.TPM || !state?.TPM?.length) {
      setError((error) => ({
        ...error,
        TPM: "TPM Request cannot be empty",
      }));
      return false;
    }

    if (state?.TPM < 1) {
      setError((error) => ({
        ...error,
        TPM: "TPM Request cannot be 0 or negative",
      }));
      return false;
    }
    if (
      state?.batchType === restrictedProduct[1] &&
      (state?.TPM > 20 || state?.TPM < 1)
    ) {
      setError((error) => ({
        ...error,
        TPM: "TPM Request cannot be more than 20 and less than 1 for Rc Reverse",
      }));
      return false;
    }
    if (
      state?.batchType !== restrictedProduct[1] &&
      (state?.TPM > 1000 || state?.TPM < 1)
    ) {
      setError((error) => ({
        ...error,
        TPM: "TPM Request cannot be more than 1000 and less than 1",
      }));
      return false;
    }
    if (!Number.isInteger(+state?.TPM)) {
      setError((error) => ({
        ...error,
        TPM: "No decimal values allowed",
      }));
      return false;
    }
    return true;
  }

  // To validate the Batch Type
  function checkBatchType() {
    if (!state?.batchType || !state?.batchType?.length) {
      setError((error) => ({
        ...error,
        batchType: "Select Batch Type",
      }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    setState((data) => ({
      ...data,
      TPM: "20",
    }));
  }, [state.batchType]);

  useEffect(() => {
    if (uniqueProduct[0] === state.batchType) {
      setState((data) => ({
        ...data,
        response_type: PanMicroResponseCategory[0] || "",
      }));
    } else if (!restrictedProduct.includes(state.batchType)) {
      setState((data) => ({
        ...data,
        response_type: "None",
      }));
    } else if (restrictedProduct[1] === state.batchType) {
      setState((data) => ({
        ...data,
        response_type: ResponseCategory[1] || "",
      }));
    } else {
      setState((data) => ({
        ...data,
        response_type: "",
      }));
    }
  }, [state.batchType]);

  function checkResponseType() {
    if (!state?.response_type) {
      setError((error) => ({
        ...error,
        responseError: "Select Response Type",
      }));
      return false;
    }
    return true;
  }

  function checkOrgID() {
    if (!value?.id) {
      setError((error) => ({
        ...error,
        orgId: "Select Org ID",
      }));
      return false;
    }
    return true;
  }

  function checkOrgName() {
    if (!value?.name) {
      setError((error) => ({
        ...error,
        orgName: "Select Org Name",
      }));
      return false;
    }
    return true;
  }
  // To validate the Batch Type
  function checkFileUpload() {
    if (!state?.batchFile?.name) {
      setError((error) => ({
        ...error,
        batchFile: "Select Batch File",
      }));
      return false;
    }
    if (!state?.batchFile?.type.includes("csv")) {
      setError((error) => ({
        ...error,
        batchFile: "Select CSV File Only",
      }));
      return false;
    }

    const fileSize = state?.batchFile?.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      setError((error) => ({
        ...error,
        batchFile: "File size should not exceed 50mb",
      }));
      return false;
    }

    return true;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const validate = [
      checkConcurrentReq(),
      checkBatchType(),
      checkOrgName(),
      checkOrgID(),
      checkBatchName(),
      checkResponseType(),
      checkFileUpload(),
    ].every(Boolean);
    if (!validate) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("batch_type", state?.batchType || "");
    formData.append("batch_name", state?.batchName || "");
    formData.append("tpm", state?.TPM || "");
    formData.append("upload_file", state?.batchFile || "");
    formData.append("org_id", value.id || "");
    formData.append("org_name", value.name || "");
    formData.append("response_type", state.response_type.toUpperCase() || "");

    const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
    formData.append("user_email", currentUser?.email || "");

    try {
      const response: any = await callPostApi(
        "productBatchService/createTask",
        formData
      );

      if (response) {
        if (response?.success === true) {
          navigateToTrackTask();
          getTaskData();
          dispatch({
            type: "ADD_TOAST",
            payload: {
              id: Math.floor(Math.random() * 100),
              type: SUCCESS,
              message: response?.message || "",
            },
          });
        } else {
          dispatch({
            type: "ADD_TOAST",
            payload: {
              id: Math.floor(Math.random() * 100),
              type: ERROR,
              message: response?.message || "Something went wrong!",
            },
          });
        }
      }
      setState({ TPM: "20" });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err?.response?.data?.err || "Something went wrong!",
        },
      });
    }
  };

  const resetFile = () => {
    setState((state) => ({ ...state, batchFile: "" }));
  };

  return loading || loadingData ? (
    <>
      <div
        style={{ height: "50vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Loading loadingColor={ACCENTCOLOR} />
      </div>
    </>
  ) : (
    <div className="d-flex justify-content-center">
      <div className="col-lg-4 p-2">
        <div className={sharedStyle.card}>
          <div className={`px-4 pt-3 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center pb-3">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>Create Task</p>
            </div>
          </div>
          <div className="p-1 px-4 pb-5" style={{ overflow: "auto" }}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between">
                <div className={sharedStyle.dropdown1}>
                  <InputDropdown
                    labelname="Org ID"
                    showDropDownName="Enter ID"
                    optionsArray={orgIdAndName?.map((obj: any) => obj._id)}
                    click={(key: string) => {
                      const option = orgIdAndName.filter(
                        (item: any) => item?._id === key
                      );
                      setValue((data: any) => ({
                        ...data,
                        id: option[0]?._id || "",
                      }));
                      setValue((data: any) => ({
                        ...data,
                        name: option[0]?.name || "",
                      }));
                      setError((error) => ({
                        ...error,
                        orgId: "",
                      }));
                      setError((error) => ({
                        ...error,
                        orgName: "",
                      }));
                    }}
                    defaultValue={value?.id}
                    onBlur={checkOrgID}
                    PlaceHolderSearch={"Search Organisation Id"}
                    whiteBackgroundcolor={true}
                  />
                  {error.batchType && (
                    <ErrorMessage>{error.orgId}</ErrorMessage>
                  )}
                </div>

                <div className={sharedStyle.dropdown2}>
                  <InputDropdown
                    labelname="Org Name"
                    optionsArray={orgIdAndName?.map((obj: any) => obj.name)}
                    showDropDownName="Org Name"
                    click={(key: string) => {
                      const option = orgIdAndName.filter(
                        (item: any) => item?.name === key
                      );
                      setValue((data: any) => ({
                        ...data,
                        id: option[0]?._id || "",
                      }));
                      setValue((data: any) => ({
                        ...data,
                        name: option[0]?.name || "",
                      }));
                      setError((error) => ({
                        ...error,
                        orgId: "",
                      }));
                      setError((error) => ({
                        ...error,
                        orgName: "",
                      }));
                    }}
                    defaultValue={value?.name}
                    onBlur={checkOrgName}
                    whiteBackgroundcolor={true}
                  />
                  {error.batchType && (
                    <ErrorMessage>{error.orgName}</ErrorMessage>
                  )}
                </div>
              </div>

              <div className="form-group mb-2 mt-2 w-75">
                <Input
                  type="text"
                  placeholder="Enter Name"
                  labelname="Batch Name"
                  maxLength={50}
                  whiteBackgroundcolor={true}
                  no_shadow={"true"}
                  autoComplete="off"
                  onChange={(e: any) => {
                    setState((data) => ({
                      ...data,
                      batchName: e.target.value,
                    }));
                    setError((error) => ({
                      ...error,
                      batchName: "",
                    }));
                  }}
                  value={state?.batchName || ""}
                  onBlur={checkBatchName}
                />
                {error.batchName && (
                  <ErrorMessage>{error.batchName}</ErrorMessage>
                )}
              </div>
              <div className="form-group mb-2 py-2 w-75">
                <InputDropdown
                  labelname="Product Name"
                  showDropDownName="Select Product"
                  whiteBackgroundcolor={true}
                  optionsArray={batchTypesList.map((item: any) => item?.name)}
                  click={(key: string) => {
                    const option = batchTypesList.filter(
                      (item: any) => item?.name === key
                    );
                    setState((data) => ({
                      ...data,
                      batchType: option[0]?.value || "",
                    }));
                    setError((error) => ({
                      ...error,
                      batchType: "",
                    }));
                    setError((error) => ({
                      ...error,
                      TPM: "",
                    }));
                  }}
                  defaultValue={`${
                    batchTypesList.filter(
                      (item: any) => item?.value === state.batchType
                    )[0]?.name || ""
                  }`}
                  onBlur={checkBatchType}
                  no_shadow="true"
                />
                {error.batchType && (
                  <ErrorMessage>{error.batchType}</ErrorMessage>
                )}
              </div>

              <div className="form-group mb-2 py-2 w-75">
                <InputDropdown
                  labelname="Response Type"
                  showDropDownName="Select Response Type"
                  whiteBackgroundcolor={true}
                  optionsArray={
                    state.batchType === uniqueProduct[0]
                      ? PanMicroResponseCategory
                      : state.batchType === restrictedProduct[1]
                      ? [ResponseCategory[1]]
                      : ResponseCategory
                  }
                  click={(key: string) => {
                    const option = ResponseCategory.filter(
                      (item) => item === key
                    );
                    setState((data) => ({
                      ...data,
                      response_type: restrictedProduct.includes(state.batchType)
                        ? option[0]
                        : uniqueProduct[0] === state.batchType
                        ? key
                        : "None",
                    }));
                    setError((error) => ({
                      ...error,
                      responseError: "",
                    }));
                  }}
                  defaultValue={
                    restrictedProduct.includes(state.batchType) ||
                    uniqueProduct[0] === state.batchType
                      ? state.response_type
                      : "None"
                  }
                  disabled={
                    restrictedProduct.includes(state.batchType) ||
                    uniqueProduct.includes(state.batchType)
                      ? false
                      : true
                  }
                  no_shadow="true"
                />
                {error.responseError && (
                  <ErrorMessage>{error.responseError}</ErrorMessage>
                )}
              </div>
              <div className="form-group mb-2 w-75">
                <Input
                  type="number"
                  placeholder="Enter TPM"
                  whiteBackgroundcolor={true}
                  no_shadow={"true"}
                  labelname="TPM (Transactions per minute)"
                  onChange={(e: any) => {
                    setState((data) => ({
                      ...data,
                      TPM: e.target.value,
                    }));
                    setError((error) => ({
                      ...error,
                      TPM: "",
                    }));
                  }}
                  value={state?.TPM || ""}
                  onBlur={checkConcurrentReq}
                />
                {error.TPM && <ErrorMessage>{error.TPM}</ErrorMessage>}
              </div>
              <div className="d-flex">
                <div>
                  <InfoSvg />
                </div>
                <div className={sharedStyle.TmpHeading}>
                  TPM is the Number of transactions per minute
                </div>
              </div>
              <div className="form-group mb-2 pt-2 pb-4 w-75">
                <div className="position-relative">
                  <p className={`m-0 ${sharedStyle.formLabel}`}>Batch file</p>
                  <div>
                    {state?.batchFile?.name ? (
                      <div className="d-flex align-items-center">
                        <p className={"mr-3 m-0 " + sharedStyle.textEllipsis}>
                          {state?.batchFile?.name}
                        </p>
                        <span className={"cursor-pointer"} onClick={resetFile}>
                          <CrossIcon />
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <span
                            className={`${sharedStyle.fileInputMessage} mb-1`}
                          >
                            Only CSV format accepted
                          </span>
                          <label
                            htmlFor="createTaskFileeInput"
                            className={sharedStyle.fileInputLabel + " m-0"}
                          >
                            Upload File
                          </label>
                          <input
                            type="file"
                            className="custom-file-input d-none"
                            id="createTaskFileeInput"
                            accept=".xls, .xlsx, .csv"
                            onChange={(e: any) => {
                              setState((data) => ({
                                ...data,
                                batchFile: e.target?.files[0],
                              }));
                              setError((error) => ({
                                ...error,
                                batchFile: "",
                              }));
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          {downloadSampleLink && (
                            <a
                              href={downloadSampleLink}
                              className={sharedStyle.downloadFile}
                              download
                            >
                              Download Sample CSV
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                    {error.batchFile && (
                      <ErrorMessage>{error.batchFile}</ErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-25 mx-auto">
                <Button
                  type="submit"
                  size={COMPRESSED}
                  style={primaryButtonStyle}
                  hoveredStyle={primaryButtonHoverStyle}
                  disabled={!is_allowed_to_write || loading}
                  isLoading={loading}
                >
                  Create Task
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTask;
